import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Langs
import En from "./en";
import Ar from "./ar";

const resources = {
  en: En,
  ar: Ar
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem("lang"),

    interpolation: {
      escapeValue: false
    }
  });

  export default i18n;