import React from 'react'
import "./MainVal.scss"
import Button from '@mui/material/Button';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';


const MainVal = () => {

    const {t} = useTranslation()

    return (
        <div className='mv-container'>
            <div className='mv-wrapper'>
            {/* {t('Overview')} */}
                {/* <h1 className='mv-h1'>{en.translation.Value} </h1> */}
                <h1 className='mv-h1'>{t('Value')} </h1>
                <h2 className='mv-h2'>{t('FAst')}<br/> {t('FAst2')} <span className='mv-iss'>{t('FAst3')} </span>  </h2>
                <Link to={"/demo"}>
                    <Button className='mv-btn' color='secondary' variant='contained' sx={{width: "208px",
                        height: "58px" , color : "white" , borderRadius : "16px" ,
                        "@media (max-width: 767px)": {
                            fontSize: "25px",
                            }
                    }}
                        >{t('BookAdemo')}
                        
                    </Button>
                </Link>
            </div>
        </div>
    )
}

export default MainVal
