// images
import logo from "../../icons/logo2.svg";
import sa from "../../icons/sa.svg";
import phone from "../../icons/phone.png";
import PublicIcon from '@mui/icons-material/Public';
// Imports
import { Button, Stack, Typography } from "@mui/material";
import "./Navbar.scss";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { HashLink as Hash } from "react-router-hash-link";      
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Navbar = () => {
    const navigate = useNavigate();

    const [active, setactive] = useState(false);

    const {t} = useTranslation()

    useEffect(() => {
        if (localStorage.getItem("lang")) {
            if (localStorage.getItem("lang") === "ar") {
                document.body.classList.add("ar");
            } else {
                document.body.classList.remove("ar");
            }
        } else {
            localStorage.setItem("lang", "en");
        }
    }, []);

    const handleChange = (e) => {
        if (localStorage.getItem("lang") === "en") {
            localStorage.setItem("lang", "ar");
            window.location.reload();
        } else {
            localStorage.setItem("lang", "en");
            window.location.reload();
        }
    };

    return (
        <div className="Navbar">
                
            <Phone handleChange={handleChange} />

            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    pt: 10,
                    pb: 10,
                }}
            >
                <div className="logo">
                    <Link to="/">
                        <img src={logo} alt="" />
                    </Link>
                </div>

                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={25}
                    sx={{
                        fontSize: "18px",
                        display: { xs: "none", md: "flex" },
                    }}
                    className="list"
                >
                    <NavLink to="/">{t('Overview')}</NavLink>
                    <Hash to="/#features">{t('Features')}</Hash>
                    <Hash to="/#pricing">{t('AppNav')}</Hash>
                    <NavLink to="/value">{t('CaseStudiesNav')}</NavLink>
                    <NavLink to="/media">{t('MediaCenter')}</NavLink>
                </Stack>

                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={12}
                    className="login-demo"
                    sx={{
                        display: { xs: "none", md: "flex" },
                        pt: 10
                    }}
                >
                    <NavLink to="/login">{t('loginNav')}</NavLink>
                    <Button
                        onClick={() => navigate("/demo")}
                        variant="contained"
                        sx={{
                            borderRadius: "12px",
                            py: 5,
                            px: 10,
                            fontSize: "18px",
                            lineHeight: "22px",
                            "&:hover": { color: "secondary.main" },
                        }}
                    >
                        <NavLink to="/demo">{t('BookAdemo')}</NavLink>
                    </Button>
                </Stack>

                {active ? (
                    <CloseIcon
                        fontSize="large"
                        color="primary"
                        sx={{
                            cursor: "pointer",
                            display: { xs: "block", md: "none" },
                            "&:hover": {
                                color: "secondary.main",
                            },
                        }}
                        onClick={() => setactive(false)}
                    />
                ) : (
                    <MenuIcon
                        fontSize="large"
                        color="primary"
                        sx={{
                            cursor: "pointer",
                            display: { xs: "block", md: "none" },
                            "&:hover": {
                                color: "secondary.main",
                            },
                        }}
                        onClick={() => setactive(true)}
                    />
                )}
            </Stack>

            {active && <Menu setactive={setactive} />}

        </div>
    );
};

const Phone = ({handleChange}) => {

    const {t} = useTranslation()
    
    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            spacing={2}
            sx={{
                width: "fit-content",
                position: "absolute",
                top: "-6px",
                right: localStorage.getItem("lang") === "en" ? "64px" : "35px",
                px: 10,
                pt: 4,
                pb: 2,
                border: "1px solid",
                borderColor: "text.primary",
                borderBottomLeftRadius: "15px",
                borderBottomRightRadius: "15px",
                display: { xs: "none", md: "flex" },
            }}
        >
            <img src={phone} alt="" />

            <Typography variant="span" className="s1" color="text.secondary" sx={{
                fontSize : "13px",
                }}>
                +966 534 470 076
            </Typography>

            <Stack direction={"row"} alignItems={"center"} spacing={3}>
                <Typography
                    onClick={(e) => handleChange(e)}
                    sx={{
                        fontSize: "12px",
                        fontWeight: "700",
                        color: "primary.main",
                        lineHeight: "10px",
                        pl: 4,
                        py: 2,
                        borderLeft: "1px solid",
                        cursor: "pointer",
                        "&:hover": {
                            color: "secondary.main",
                        },
                    }}
                >
                    {t('lang')}
                </Typography>
                {localStorage.getItem("lang") !== "en" ? <PublicIcon fontSize="small" /> : <img src={sa} alt="" />}
            </Stack>
        </Stack>
    )
}

const Menu = ({ setactive }) => {

    const {t} = useTranslation()
    
    const handleChange = (e) => {
        e.preventDefault()
        
        if (localStorage.getItem("lang") === "en") {
            localStorage.setItem("lang", "ar");
            window.location.reload();
        } else {
            localStorage.setItem("lang", "en");
            window.location.reload();
        }
    };
    
    
    return (
        <div className="Menu">
            <Stack
                sx={{
                    position: "fixed",
                    zIndex: "10000000",
                    top: "0",
                    left: "0",
                    bgcolor: "white",
                    height: "100vh",
                    width: "70%",
                    py: 20,
                    px: 10,
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                }}
            >
                <Stack onClick={() => setactive(false)}>
                    <div className="logo">
                        <Link to="/">
                            <img src={logo} alt="" />
                        </Link>
                    </div>
                    <NavLink to="/">{t('Overview')}</NavLink>
                    <Hash to="/#features">{t('Features')}</Hash>
                    <NavLink to="/prices">{t('AppNav')}</NavLink>
                    <NavLink to="/value">{t('CaseStudiesNav')}</NavLink>
                    <NavLink to="/media">{t('MediaCenter')}</NavLink>
                    <NavLink to="/login">{t('loginNav')}</NavLink>
                    <NavLink to="/sign-up">{t("registerNav")}</NavLink>
                    <NavLink to="/demo">{t("BookAdemo")}</NavLink>
                    <Link onClick={(e) => handleChange(e)}>{t("language")}</Link>
                </Stack>
            </Stack>
        </div>
    );

};

export default Navbar;
