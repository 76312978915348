import { useEffect } from "react";

import "./goddady-ssl.scss";

const GoDaddySSL = ({ doLoadData }) => {

  let sslImageJsUrl = "https://seal.godaddy.com/getSeal?sealID=OsYOGHfpQXlF60jN64AWd64u8nA6bQKDGvtuSRDX7JroVLrZmfKEbqu5Htoe";

  useEffect(() => {



    const oldScript = document.querySelector(`script[src="${sslImageJsUrl}"]`);
    if (oldScript) {
      oldScript.parentNode.removeChild(oldScript);
    }

    const newScript = document.createElement('script');
    newScript.src = sslImageJsUrl;
    document.body.appendChild(newScript);

    return () => {
      document.body.removeChild(newScript);

      let elem = document.getElementById('siteseal');
      if (elem) {
        setTimeout(() => {
          while (elem.childNodes.length > 1) {
            elem.childNodes[0].remove();
          }
        }, 1000);
      }
    }

  }, []);

  return (
    <span id="siteseal">
    </span>
  );

};

export default GoDaddySSL;
