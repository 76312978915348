import { Button } from '@mui/material'
import React from 'react'
import { useTranslation } from "react-i18next";
import "./Pricing.scss"


const Pricing = ({setActive}) => {
  const {t} = useTranslation()
  return (
    <div className='pricing-container' >
      <div className='pricing-wrapper'>
          <h1 className='pricing1'>{t ('Prices')}</h1>
          <h2 className='pricing2'>{t ('SolutionsFit')}</h2>
          <Button onClick={() => setActive(prev => !prev)} variant='contained' sx={{ 
              padding : "12px 34px",
              fontSize:"20px" , fontWeight:"500",lineHeight:"24px",
              color : "white",
              borderRadius : "16px" ,
              bgcolor : "secondary.main"
          }}>
              {t("ContactForQuote")}
          </Button>
      </div>
    </div>
  )
}

export default Pricing
