import { Typography } from '@mui/material'
import React from 'react'
import "./Currencies.scss"
import AI_Image3 from "../../images/saudi arabia.svg" ;
import AI_Image4 from "../../images/united arab emirates.svg" ;
import AI_Image5 from "../../images/oman.svg" ;
import AI_Image6 from "../../images/qatar.svg" ;
import AI_Image7 from "../../images/AI_Image 3.svg" ;
import AI_Image8 from "../../images/bahrain.svg" ;
import AI_Image9 from "../../images/jordan.svg" ;
import AI_Image10 from "../../images/egypt.svg" ;
import AI_Image11 from "../../images/united states.svg" ;
import AI_Image12 from "../../images/united kingdom.svg" ;
import AI_Image13 from "../../images/canada.svg" ;
import { useTranslation } from "react-i18next";




const Currencies = () => {


    const {t} = useTranslation()

    return (
        <div>
            <div className='cur-container'>
                <div className='cur-wrapper'>
                    <div className='cur-top'>
                        <h1 className='curr-toph1'>{t("OurLeadingProducts")}<br/> {t("OurLeadingProducts2")} </h1>
                    </div>
                    <div className='cur-mid'>
                    <Typography 
                        sx={{
                            p : "10px 20px",
                            fontSize: "20px",
                            fontWeight: "500",
                            lineHeight: "24px",
                            color: "white",
                            background: "#42D3AD",
                            borderRadius: "12px",
                            "@media (max-width: 480px)": {
                                fontSize: "16px",
                                lineHeight: "18px",
                                // height: "36px",
                                // borderRadius: "8px"
                            }
                        }}
                        >
                            {t("CurrenciesAvailable")}
                    </Typography>
                    </div>
                    <div className='curr-bot'>

                        <div className='curr-bottom'>
                            <div className='curr-bottom-tit'>
                                <p className='curr-bottom-p'>{t("GCC")}</p>
                            </div>
                            <div className='curr-flags'>
                                <div className='curr-flags2'>
                                    <div className='cur-alam'><img src={AI_Image3} alt=''/></div>
                                    <div className='curruncy'><p className='currency-name'>{t("SAR")}</p> </div>
                                    <div className='curruncy2'><p className='currency-country'>{t("SAR2")}</p> </div>
                                </div>
                                <div className='curr-flags2'>
                                    <div className='cur-alam'><img src={AI_Image4} alt=''/></div>
                                    <div className='curruncy'><p className='currency-name'>{t("AED")}</p> </div>
                                    <div className='curruncy2'><p className='currency-country'>{t("AED2")}</p> </div>
                                </div>
                                <div className='curr-flags2'>
                                    <div className='cur-alam'><img src={AI_Image5} alt=''/></div>
                                    <div className='curruncy'><p className='currency-name'>{t("OMR")}</p> </div>
                                    <div className='curruncy2'><p className='currency-country'>{t("OMR2")}</p> </div>
                                </div>
                                <div className='curr-flags2'>
                                    <div className='cur-alam'><img src={AI_Image6} alt=''/></div>
                                    <div className='curruncy'><p className='currency-name'>{t("QAR")}</p> </div>
                                    <div className='curruncy2'><p className='currency-country'>{t("QAR2")}</p> </div>
                                </div>
                                <div className='curr-flags2'>
                                    <div className='cur-alam'><img src={AI_Image7} alt=''/></div>
                                    <div className='curruncy'><p className='currency-name'>{t("KWDKD")}</p> </div>
                                    <div className='curruncy2'><p className='currency-country'>{t("KWDKD2")}</p> </div>
                                </div>
                                <div className='curr-flags2'>
                                    <div className='cur-alam'><img src={AI_Image8} alt=''/></div>
                                    <div className='curruncy'><p className='currency-name'>{t("BHD")}</p> </div>
                                    <div className='curruncy2'><p className='currency-country'>{t("BHD2")}</p> </div>
                                </div>
                                
                            </div>
                            
                        </div>


                        <div className='curr-bottom'>
                            <div className='curr-bottom-tit'>
                                <p className='curr-bottom-p'>{t("MENA")}</p>
                            </div>
                            <div className='curr-flags'>
                                <div className='curr-flags2'>
                                    <div className='cur-alam'><img src={AI_Image9} alt=''/></div>
                                    <div className='curruncy'><p className='currency-name'>{t("JODJD")}</p> </div>
                                    <div className='curruncy2'><p className='currency-country'>{t("JODJD2")}</p> </div>
                                </div>
                                <div className='curr-flags2'>
                                    <div className='cur-alam'><img src={AI_Image10} alt=''/></div>
                                    <div className='curruncy'><p className='currency-name'>{t("EGP£")}</p> </div>
                                    <div className='curruncy2'><p className='currency-country'>{t("EGP£2")}</p> </div>
                                </div>
                                
                            </div>

                        </div>


                        <div className='curr-bottom'>
                            <div className='curr-bottom-tit'>
                                <p className='curr-bottom-p'>{t("GLOBAL")}</p>
                            </div>
                            <div className='curr-flags'>
                                <div className='curr-flags2'>
                                    <div className='cur-alam'><img src={AI_Image11} alt=''/></div>
                                    <div className='curruncy'><p className='currency-name'>{t("USD$")}</p> </div>
                                    <div className='curruncy2'><p className='currency-country'>{t("USD$2")}</p> </div>
                                </div>
                                <div className='curr-flags2'>
                                    <div className='cur-alam'><img src={AI_Image12} alt=''/></div>
                                    <div className='curruncy'><p className='currency-name'>{t("GBP£")}</p> </div>
                                    <div className='curruncy2'><p className='currency-country'>{t("GBP£2")}</p> </div>
                                </div>
                                <div className='curr-flags2'>
                                    <div className='cur-alam'><img src={AI_Image13} alt=''/></div>
                                    <div className='curruncy'><p className='currency-name'>{t("CAD$")}</p> </div>
                                    <div className='curruncy2'><p className='currency-country'>{t("CAD$2")}</p> </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Currencies
