import React from 'react'
import "./Newsletter.scss"
import { useTranslation } from "react-i18next";
import { Button } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import {Environment} from '../../constants/environment.ts';
import { ToastContainer, toast } from 'react-toastify';
import { BeatLoader } from 'react-spinners';
import Modal from 'react-modal';
import { useState } from "react";
Modal.setAppElement('#root'); 
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: '1px solid #ccc',
    borderRadius: '10px',
    padding: '20px',
    width: '300px',
  } 
};
 
const Newsletter = () => {
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation()
    return (
        <div className='new-container'>
            <div className='new-wrapper'>
                <h1 className='newh1'>{t("Subscribe")}</h1>
                <div className='new-bot'>
                    <input type='text' dir='ltr' sx={{padding:"10px"}} id="yourEmailAddress" className='new-inp' placeholder={t("yourEmailAddress")}/>
                    <label className='subm'> <Button 
                         onClick={ async ()  =>{
                            setLoading(true);
                            await fetch(`${Environment.ertikazApi}/send`, {
                               method: 'PUT',
                               body: JSON.stringify({
                                  name: 'name',
                                  companyName: 'name',
                                  mobile: 'name',
                                  email: document.getElementById('yourEmailAddress')?.value,
                                  demoDate: '2022-07-07'
                               }),
                               headers: {
                                  'Content-type': 'application/json; charset=UTF-8',
                                  'Access-Control-Allow-Origin': '*',
                                  'Access-Control-Allow-Headers': '*'
                               },
                            }).then((res)=>{
                              setLoading(false);
                              let infoMessage='';
                              if(localStorage.getItem("lang") === "ar"){
                                  infoMessage='تم إرسال الطلب بنجاح';
                                }
                                else{
                                  infoMessage='Request has been sent successfully';
                                }
                              toast.success(infoMessage);
                              }).catch(e=>{
                              setLoading(false);
                              console.log(e);
                              toast.error('Error');
                            });
                         }}
                        sx={{color : "white"}}>{t("Submit")}
                    </Button> </label>
                </div>
            </div>
            <Modal isOpen={loading} style={customStyles}>
        <BeatLoader color="#4A90E2" />
      </Modal>
   <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={true}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        />
        </div>
    )
}

export default Newsletter
