
const ar = {
  translation: {
    // Navbar 
    lang: "EN",
    Overview: "الرئيسية",
    Features: "الميزات",
    "AppNav": "الاسعار",
    "CaseStudiesNav": "القيمة",
    MediaCenter: "الاعلام والأخبار",
    "loginNav": "تسجيل دخول",
    BookAdemo: "احجز عرض",
    "registerNav": "حساب جديد",
    "language": "English",


    // Footer
    "Lean Finance is": "لين فايننس هو برنامج بسيط ومرن لإصدار الفواتير الإلكترونية عبر الإنترنت لمساعدتك على تطوير أعمالك",

    "Issue, manage, send": "إصدار، إدارة، إرسال الفواتير الإلكترونية المعتمدة من هيئة الزكاة والضريبة والجمارك وحساب الضريبة الخاصة بك في ثوان.",

    "Approvedby": "معتمد من قبل ",

    "Experience it today": "جربه اليوم",

    "Sitemap": "خريطة الموقع",
    "Lean FinanceF": "لين ديسباتشر",
    "IndustriesF": "القطاعات",
    "About Us": "من نحن",
    "Ertikaz Solutions": "ارتكاز سولوشنز",
    "Ertikaz": "ارتكاز",
    "Lean Finance": "لين فايننس",
    "Contact Us": "اتصل بنا",
    "Info@Ertikaz.Tech": "Info@Ertikaz.Tech",
    "Sales@Ertikaz.Tech": "Sales@Ertikaz.Tech",
    "+966 534 470 076": "+966 534 470 076",
    "+966 534 479 020": "+966 534 479 020",
    "Copyright © 2024-2025": "حقوق النشر © 2024-2025 شركة",
    "Inc.": ".",
    "All Rights Reserved": "جميع الحقوق محفوظة.",

    "otherLinks": "روابط اخري",
    "careers": "الوظائف",

    "Comment ": "Overview page ",

    "overview": "نظره عامة ",

    "Comment": "Main section  ",
    "ld": "لين ديسباتشر",

    "saudiAndVision2030Logos": "Add the Made in Saudi & Vision 2030 logos",
    "is a simple": " ھو برنامج سھل ومرن ویمكن تطویره لتنظیم وإدارة أسطول النقل بكفاءة عبر الإنترنت.",
    "easilyManageAutomate": "إدارة وتشغیل الآلي لطلباتك ورحلاتك ومركباتك وعملیاتك ومواردك بكل سھولة.",
    "issue": "قم بإصدار وثیقة بیان نقل الحمولة الخاصة بك في 15 ثانیة!",
    "supporting": "دعم 11 عملة مختلفة.",
    " approved": "معتمد من قبل شعارات الھیئة العامة للنقل وبیان",
    "BookDemo ": " حجز عرض",
    "linkToTheBook": " رابط إلى صفحة حجز عرض",




    "digitallyRevolutionizing": "إحداث ثورة رقمية في إدارة عمليات سلسلة التوريد الشاملة",
    "totalOrders": "الطلبات",
    "totalTrips": " الرحلات ",
    "totalWay": " وثائق نقل  ",
    "totalVehicle": "المركبات",
    "totalOrders2": "70K",
    "totalTrips2": "8.7K",
    "totalWay2": "1.5K",
    "totalVehicle2": "3.3K",

    "totalCustomers": "Total Customers",
    "totalCountries": "Total Countries",
    "totalMiles": "Total Miles",

    "ourPartners": " شركائنا في النجاح ",


    "Industries ": " القطاعات ",
    "Flexible": "مرن وقابل للتخصیص لمجالك",
    "Grocery&Food": "المواد الغذائیة والبقالة",
    "E-Commerce": "التجارة الإلكترونیة",
    "Big&Bulky": "الكبير والضخم",
    "Carriers": "الناقلون",
    "Hazardous": "الخطره",
    "Mining": "التعدين",
    "Retail": "التجارة",
    "Pharmacy": "الصيدلة",
    "Healthcare": "الرعایة الصحیة",
    "Construction": "البناء",
    "InternationalCompanies": "الشركات العالمیة",
    "Gov&Public": "النقل العام والحكومي ",
    "Oil&Gas": "النفط والغاز",
    "Logistic": "اللوجستیات",


    "Benefits": "فوائد",
    "transportFleetManagement": "برنامج إدارة أسطول النقل ",
    transportFleetManagement2: "الذي تحتاجه",

    "TurnkeySolutionForLocalAndGlobal": "حل متكامل وجاھز لشركات النقل المحلیة والعالمیة ",

    "UpliftyYourCustomerExperience": "قدم تجربة استثنائیة لعملائك",
    "FastOnboardingSolution": "حل سریع الإعداد والتأھیل",
    "ImmediateRegionalAccessToSaudi": "الوصول الإقلیمي الفوري إلى السعودیة",
    "DigitalizeYourBusiness": "رقمنة عملك",
    "ops": "تبسیط عملیاتك وتوسیع نطاقھا بسھولة",
    "LowerOverheadsAndRaiseProfits": " تقلیل النفقات العامة وزیادة الربحیة",
    "IssueBayanNaql": "اصدار وثیقة بیان نقل في ثوان",
    "improveDeliveryTimes": "تحسین أوقات التسلیم",
    "realTimeTracking": "تتبع الأداء والاساطیل في الوقت الفعلي",
    "informedDecisions": "اتخاذ قرارات مستنیرة",
    "flexibleIntegration": "مرونة في الدمج",
    "customerSupport": "خدمة عملاء ودعم ممیزه",


    "Key Features": "المیزات الرئیسیة",
    "ElevateYourBusiness": "المیزات الرئیسیة ",
    "Manage": "إدارة جمیع جوانب عملیاتك دون عناء",
    "Multilingual": "تعدد اللغات ھو الطریق الأنسب لفریقك",
    "On The Go": "یمكن الوصول إلیھ من أي مكان وعلى أي جھاز",
    "Compliance": "متوافق مع الھیئة العامة للنقل وتوثیق الحمولة نقل",
    "TGA&Byan": "Add TGA & Bayan Logos",
    "Customize": "قم بتخصیصھ وفقا لاحتیاجاتك",
    "Collaborate": "تعاون مع فریقك",
    "Bayan": "إصدار وثیقة بیان نقل الحمولة في 15 ثانیة!",
    "App": "تطبیق مخصص للسائق",
    "Route": "قابلية تحسين المسار",
    "Hybrid": "الجدولة والإدارة المختلطة",
    "BulkUpload": "قابلية تحمیل المعلومات مجمعة",
    "Dashboard": "رؤية لوحة التحكم في الوقت الفعلي",
    "Shipment": "تتبع الشحنة وإثبات التسلیم",
    "Comms": "اتصال ثنائي الاتجاه    ",
    "Billing": "الفوترة والتسویة التلقائیة",
    "API": "دمج واجھة برمجة التطبیقات مع أنظمة تخطیط موارد المؤسسات    ",
    "Share": "مشاركة المستندات على الفور",
    "Tracking": "راقب أسطولك من خلال التتبع المباشر",
    "Modular": "تم تصمیمھ بشكل نمطي لراحتك",
    "Security": "برنامج آمن لراحة بالك",
    "Support": "دعم فني مجاني بعد البيع",
    "Go Green": "يساند مبادرات السعودیة للبیئة",
    "StartNow ": "ابدأ الآن ",


    "Testimonials": "شھادات",
    "whatOurClientsHave": "هذا ما يقوله عملاؤنا عن لين ديسباتشر",
    "Droob AlBilad Owner": "مؤسسة دروب البلد",
    "owner": "المالك",
    "There was a learning": "سهولة البرنامج جدا ممتازة اعجبتني خصائص لوحة التحكم ،اصدار وثائق النقل ,وميزة تكرار الطلب ,معرفة الرحلات المتاخرة والمنتهية وتميزيها بسهولة عن طريق الالوان ",
    "Using Lean Dispatcher to issue": "استخدامي للبرنامج كان من أجل وثيقة نقل و خلال ثلاثون ثانية فقط مصدرة الوثيقة. انا راضي وبقوه على البرنامج وعلى سرعة التجاوب على اي مشكلة تقنية تواجهني ",
    // client 2
    "Droob AlBilad Owner2": "AHK",
    "owner2": "مدير التشغيل ",
    "There was a learning2": "سهولة البرنامج جدا ممتازة",
    "There was a learning2_2": "اعجبتني خصائص لوحة التحكم ،اصدار وثائق النقل ,وميزة تكرار الطلب ,معرفة الرحلات المتاخرة والمنتهية وتميزيها بسهولة عن طريق الالوان ",
    "There was a learning2_3": "سهولة البرنامج جدا ممتازة اعجبتني خصائص لوحة التحكم ،اصدار وثائق النقل ,وميزة تكرار الطلب ,معرفة الرحلات المتاخرة والمنتهية وتميزيها بسهولة عن طريق الالوان ",
    // client 3 
    "Droob AlBilad Owner3": "اللوجيستي الاول",
    "owner3": "مدير التشغيل",
    "There was a learning3": "سهل جدا جدا جدا تقسيم الوظائف سهّل والعمل على البرنامج المالية بقسم والادمن بقسم جدا مريح.",
    "There was a learning3_2": "البرنامج منظم واصبح التحصيل اسرع وافضل ولا يوجد تعقيد.",
    "There was a learning3_3": "انا في تمام الرضا وما شاء الله الفريق متعاونين طول الوقت ويستقبلون الاستفسارات بصدر رحب ويحسب لهم سرعة التجاوب.",
    // "There was a learning3_3" : "",







    "OneLogistics": "اللوجیستي الاول",
    "OperationManager": " مدیر التشغیل",
    "AHK": "AHK",
    "OperationManagers": " مدراء التشغیل     ",
    "softwareIsVeryEasy": "سھل جدا جدا جدا تقسیم الوظائف سھّل والعمل  على البرنامج المالیة بقسم والادمن بقسم جدا مریح. البرنامج منظم واصبح التحصیل اسرع وافضل ولا یوجد تعقید.انا في تمام الرضا وما شاء الله الفریق متعاونین طول الوقت ویستقبلون الاستفسارات بصدر رحب ویحسب لھم سرعة التجاوب",
    " software’sSimplicity":
      "سهولة البرانامج جدا ممتازة أعجبني خصائص لوحة التحكم ، سرعة اصدار وثائق النقل وإلغاء الوثيقة الخاطئة ، سهولة جلب وثيقة النقل المصدرة وميزة تكرار الطلب ، وحفظ معلومات الساقين والمركبات ، ومعرفة الرحلات المتأخرة والمنتهية وتميزها بسهولة عن طريق الألوان ",



    "CaseStudies": "دراسات الحالة ",
    lorem5: "Logistic Industry",
    "companyName": "اللوجستي الاول",
    small: "صغير",
    "businessDescription": "اللوجستي الاول ھي شركة متخصصة في نقل المركبات الفاخرة والفریدة حول العالم عن طریق البحر والجو والبر.",
    "challenge": "التحدي ",
    "fleetManagement": "الإدارة الیدویة للأسطول.",
    "invoicing": "الفوترة الیدویة.",
    // "LeanDispatcher" : "ساعدھم لین دیسباتشر على",
    "manageBusiness": "إدارة الأعمال بسلاسة ورفع الكفاءة.",
    "singlePlatform": "منصة واحدة تتوافق مع الھیئة العامة للنقل وھیئة الزكاة والضریبة والجمارك.",
    "digitalization": "رقمنة الشركة لتحقیق وكسب المزید من الأعمال.",
    "improveCustomerExperience": "تحسین تجربة العملاء وزیادة رضاھم. ",
    "eliminateFinesAndHassle": "القضاء على الغرامات ومخالفھ توثیق الحمولة والمتاعب مع السائقین.",
    "companyName2": "AHK اللوجستية",
    "Large": "كبیر",
    "businessDescription2": "AHK ھو مزود خدمة النقل. نقل الأغذیة والمشروبات والمواد الكیمیائیة وغیرھا من المواد محلیا وإقلیمیا. ",
    "challenge2": "التحدي",
    "driverCommunication": "التواصل مع سائقیھم",
    "documentManagement": "الإدارة الیدویة للوثائق. ",
    "statusUpdates": "لا توجد تحدیثات فوریة للحالة.",
    "improveDriverCommunication": "ساعدھم لین دیسباتشر على",
    "digitalizeManagement": "رقمنا كاملة للعملیات الإداریة.    ",
    "eliminatePhysicalDocumentation": "التخلص من الوثائق المادیة.    ",
    "improveDocumentAccess": "تحسین الأرشفة والوصول المستمر إلى الوثائق.",
    "improveCustomerClarity": "تحسین الوضوح للعملاء من خلال تحدیثات الحالة الفوریة.",
    "ExperienceItNow": "جربه الآن",





    "Currencies": "العملات",

    "OurLeadingProducts": "نحن فخورون بمنتجاتنا الرائدة في المملكة العربية السعودية ",
    "OurLeadingProducts2": "والتي يمكن استخدامها الفعال حول العالم",
    "CurrenciesAvailable": "العملات المتوفرة على لين ديساباتشر ",



    "GCC": "مجلس التعاون الخليجي ",
    "SAR": " ریال SAR ",
    "AED": "د.ا AED",
    "OMR": "ریال OMR",
    "QAR": " ریال QAR",
    "KWDKD": "KWD KD ",
    "BHD": " .د.ب BHD",
    "SAR2": " السعودية",
    "AED2": " الإمارات",
    "OMR2": " عمان",
    "QAR2": " قطر",
    "KWDKD2": "الكويت",
    "BHD2": " البحرين",


    "MENA": "الشرق الأوسط وشمال أفريقيا",
    "JODJD": "JOD JD ",
    "EGP£": "EGP £ ",
    "JODJD2": "الأردن ",
    "EGP£2": "مصر",


    "GLOBAL": "عالميا",
    "USD$": "USD $",
    "GBP£": "GBP £ ",
    "CAD$": "CAD $ ",
    "USD$2": "امريكا",
    "GBP£2": "بريطانيا",
    "CAD$2": "كندا",


    "Pricing": "التسعیر",
    "Prices": "الاسعار",
    "SolutionsFit": "حلول تناسب احتیاجاتك    ",
    "LeanDispatcherLite ": "لین دیسباتشر لایت    ",
    "Lean-Dispatcher": "لین دیسباتشر    ",
    "LeanDispatcherPremium": "لین دیسباتشر بریمیوم",
    "ContactForQuote": "اتصل بنا",
    "CTALinks": "روابط CTA للدردشة مع المبیعات المنبثقة لعرض معلومات الاتصال والجوانب الأخرى.",





    "IssuingAndManaging": "إصدار وادارة وثائق النقل ",
    "IssuingAndManaging2": "الخاصة بك في بیان",
    "UploadBulkDriver": "تحمیل بیانات السائقین مجمعة ",
    "UploadBulkDriver2": "عبر برنامج اكسل",
    "UploadBulkVehicles": "تحمیل بیانات المركبات مجمعة",
    "UploadBulkVehicles2": " عبر برنامج اكسل",
    "LeanDescript1": "لین دیسباتشر ",
    "LeanDescript2": "ھو برنامج لإدارة أسطول النقل عبر  الإنترنت. معتمد من قبل الھیئة العامة للنقل و بیان النقل",
    "TripsOrdersDrivers": "إدارة الرحلات والطلبات ",
    "TripsOrdersDrivers2": " والسائقین والمركبات  والصیانة",
    "customerPortal1": "بوابة لعملائك لرفع ",
    "customerPortal2": " وجدولة الطلبات ",
    "customerPortal3": "(عقود طویلة  الأجل)",
    "customerPortal4": "والسماح  بالتفاوض على الاسعار",
    "portalToRaise": "والسماح  بالتفاوض على الاسعار. ",
    "DispatchingOwnFleet": " الإرسال إلى الأسطول الخاص ",
    "DispatchingOwnFleet2": " أو الأسطول المتعاقد علیه",
    "EagleEye": "تتبع",
    "EagleEye2": " عین النسر",
    "Reordering": " اعادة الطلب بسھولة",
    "ExtractReports": " استخراج ",
    "ExtractReports2": " التقاریر",
    "Invoice": "إدارة الفواتیر والإیصالات",
    "Invoice2": " والإشعارات المدینة والدائنة",
    "issuingZATCA": "إصدار فواتیر متوافقة مع  ",
    "issuingZATCA2": "ھیئة الزكاة والضریبة والجمارك ",
    "Inventory": "إدارة المخزون والموردین  ",
    "Inventory2": "والمستودعات والمشتریات  ",
    "Inventory3": " والمبيعات والمصروفات ",


    "ZATCA": " دیسباتشر بریمیوم ",
    "ZATCA2": "ھو برنامج لإدارة أسطول النقل عبر الإنترنت یدعم أیضا خدمة الفوترة الإلكترونیة وإدارة المخزون. معتمد من قبل الھیئة العامة للنقل و بیان نقل و ھیئة الزكاة والضریبة  والجمارك",
    "LeanDescriptlite": " دیسباتشر لایت ",
    "Lean": "لین",
    "LeanDescriptlite2": "ھو برنامج  لإصدار ولإدارة بیان نقل. معتمد من قبل الھیئة العامة للنقل و بیان نقل",
    "LeanDescript": " دیسباتشر ",
    "LeanDescript22": " ھو برنامج لإدارة أسطول النقل عبر  الإنترنت. معتمد من قبل الھیئة العامة للنقل و بیان نقل",


    // "IssuingAndManaging" : "إصدار وادارة وثائق النقل الخاصة بك في بیان" ,

    " UploadBulkVehicles": "تحمیل بیانات المركبات مجمعة",
    " UploadBulkVehicles2": "عبر برنامج اكسل",
    "NaqlWaybills": "إصدار وادارة وثائق النقل الخاصة بك في بیان.",
    "customerPortal": "بوابة لعملائك لرفع وجدولة الطلبات (عقود طویلة  الأجل).",






    "CrossSell": "بيع عابر",
    "E-Invoicing1": "ھل تبحث عن برنامج بسیط ومرن لإصدار",
    "E-Invoicing2": "الفواتیر الإلكترونیة عبر الإنترنت",
    "E-Invoicing3": "لمساعدتك على تطویر أعمالك؟",
    "Approved0": "معتمد من ",


    "EasilyCreate": "قم بسھولة بإنشاء وتحریر وإدارة العملاء والفواتیر وإشعارات الدائن /المدين وسندات القبض والصرف. معتمد من قبل ھیئة الزكاة والضریبة والجمارك",
    "ContactUs": " اتصل بنا",

    "CTALinksToChat": "روابط CTA للدردشة مع المبیعات المنبثقة لعرض معلومات الاتصالوالجوانب الأخرى.",



    "FAQ": "الأسئلة المتداولة",
    "SaaS": "ما ھو SaaS?",
    "SaaSMeanSoftware": "هو نموذج برمجي قائم على السحاة يتيح للمستخدمين الدخول إليه بسهولة من أي مكان ",



    "fleetPerformance": "ما ھو برنامج إدارة أسطول النقل؟",
    "manage&Automate ": "برنامج یستخدم لإدارة وأتمتة عملیات النقل والأسطول الطلبات والرحلات والمركبات والموارد بسهولة",

    "online fleet performance": "ما ھو برنامج إدارة أسطول النقل عبر الانترنت",
    "softwareThatAllows": "ھو برنامج قائم على السحابة یسمح لك بالقیام بجمیع العملیات وتخزین المستندات على السحابة دون الحاجة إلى وجودھا على أجھزتك",

    " createAnOrder": "كیف یمكنني إنشاء طلب؟",
    "createAnOrderOnLean": "لإنشاء طلب على لین دیسباتشر ، كل ما علیك فعلھ ھو: ",
    "Login": "1. تسجیل الدخول إلى حسابك.",
    "Orders": "2. انتقل إلى صفحة الطلبات من القائمة الرئیسیة.",
    "CreateOrder": "3. الضغط على زر إنشاء طلب لإضافة طلب واحد.",
    "neededInformation": "4. املأ المعلومات المطلوبة.",
    "listed": "5. بمجرد إنشاء الطلبات ، یتم إدراجھا في صفحة الرحلات . ",


    "createBulkOrders": "كیف یمكنني إنشاء طلبات مجمعة مرة واحدة؟",
    "createAnBulkOrdersOnLean": "لإنشاء عدة طلبات على لین دیسباتشر ، كل ما علیك فعلھ ھو: ",
    "Upload ": "لإضافة طلبات مجمعة ، الضغط على زر تنزیل نموذج اكسل ، بمجرد تنزیلھ ، یمكنك إضافة معلومات وفقا لذلك. ثم الضغط على زر رفع ملف اكسل لتحمیل طلباتك المجمعة إلى النظام",



    "assignTrips": "كیف یمكنني تعیین رحلات؟",
    "automatically": "تتم إضافة الرحلات تلقائیا إلى صفحة الرحلات بمجرد  إنشاء الطلبات ",
    "pending": "ثم یتم تنشیطھا عن طریق تعیین سائق",

    "assignDriver": "كیف یمكنني تعیین سائق لرحلة؟",
    "assignTrips2": "لتعیین سائق للرحلة على لین دیسباتشر ، كل ما علیك فعلھ ھو: ",
    "Trips": "2. انتقل إلى صفحة الرحلات من القائمة الرئیسیة",
    "Locate": "3. حدد الرحلة المضافة حدیثا، ثم الضغط على زر تعیین سائق",
    "select": "4. حدد السائق والسیارة واللوحات ثم الضغط على زر حفظ لتعیین رحلتك وتنشیطھا",


    "ordersToLeanDispatcher": "كیف یمكنني جعل عمیلي یضیف  طلبات مباشرة على لین دیسباتشر؟",
    "clientAdd": "لجعل عمیلك یضیف طلبات على لین دیسباتشر ، كل ما علیك فعلھ ھو: ",
    " createAUser": " 2. بمجرد إنشاء مستخدم لعمیلك، سیكون قادرا على إنشاء الطلبات.  ",
    "LoginToHIsAccount": "3. یمكنھ بعد ذلك تسجیل الدخول على حسابھ ومن ثم الانتقال إلى صفحة  الطلبات من القائمة الرئیسیة.",
    "Fill": "5. املأ المعلومات المطلوبة.",
    "Download3": "6. أو لإضافة طلبات مجمعة ، الضغط على زر تنزیل نموذج اكسل ، بمجرد التنزیل ، یمكنك إضافة المعلومات ثم الضغط على زر رفع ملف اكسل لإضافتھ إلى النظام. ",

    " ExternalOrders": "7. بمجرد إنشاء الطلبات ، یتم إدراجھا في صفحة الطلبات االخارجية لتتمكن من مراجعتھا والموافقة علیھا. ",

    "trackMyFleet": "كیف یمكنني تتبع أسطولي؟    ",
    "easilyTrack": "یمكنك بسھولة تتبع أسطولك النشط من صفحة عین النسر واختیار الرحلة المطلوبة لمراجعة جمیع معلوماتھا.",



    "addANewClient": "كیف یمكنني إضافة عمیل جدید؟",
    "Newlients": "لإضافة عملاء جدد على لین دیسباتشر ، كل ما علیك فعلھ ھو:  ",
    "Settings": "2.انتقل إلى قسم الإعدادات من القائمة الرئیسیة.",
    "Contact": "3. انتقل إلى صفحة جھات الاتصال. ",
    "AddContact": "4. ثم الضغط على زر إضافة جھة اتصال.",
    "clickCreate": "5. املأ المعلومات المطلوبة والضغط على زر إنشاء.  ",
    "NewContact": "6. أو یمكنك إنشاء جھة اتصال جدیدة عند إنشاء طلب جدید وملء معلومات المصدر والاستلام. ",


    "addANewVehicle": "كیف یمكنني إضافة نوع ولوحات مركبة جديدة؟",
    " vehicleType": "لإضافة نوع جدید من المركبات واللوحات على لین دیسباتشر ، كل ماعلیك فعلھ ھو: ",
    "vehicle": "3. نتقل إلى صفحة المركبات.    ",
    "CreateVehicleType": " 5. املأ المعلومات المطلوبة والضغط على زر إنشاء نوع مركبة",
    "CreatePlate": "6. املأ المعلومات المطلوبة والضغط على زر إنشاء نوع مركبة. ",


    "AddNewDriver": "كيف يمكنني إضافة سائق جديد ؟ ",
    " driver": "لإضافة سائق جدید جدید على لین دیسباتشر ، كل ما علیك فعلھ ھو:",
    "Drivers": "3. انتقل إلى صفحة السائقین. ",
    "CreateDriver": "4.ثم الضغط على زر إنشاء سائق ",
    "CreateDriverUser": " 5. املأ المعلومات المطلوبة والضغط على زر إنشاء مستخدم.",



    "newProduct": "كیف یمكنني إضافة منتج جدید؟ ",
    "products": " لإضافة منتجات على لین دیسباتشر ، كل ما علیك فعلھ ھو:",
    "Products": "3. انتقل إلى صفحة المنتجات. ",
    "AddProduct": " 4. انتقل إلى صفحة المنتجات",
    "fillInfo": "5. املأ المعلومات المطلوبة.",
    "CreateProduct": "6. ثم الضغط على زر إنشاء منتج لإضافة منتجك.",
    "reateANewProduct": "7. أو یمكنك إنشاء منتج جدید عند إنشاء طلب جدید وملء معلومات المنتج.",



    "setting": "أو یمكنك إنشاء منتج جدید عند إنشاء طلب جدید وملء معلومات المنتج.",
    "Settingss": "7. لمعرفة ما یفعلھ الإعداد في صفحة حسابي على لین دیسباتشر ، كل ما علیك فعلھ ھو: ",
    "Threedots": " 2.  الضغط على زر الثلاث نقاط في اعلى یسار الشاشة.",
    "My Account.": " 3. ثم الضغط على حسابي",
    "OurTeam": "4. اجتھد فریقنا لجعل تجربتك سھلھ قدر الإمكان من خلال إضافة مقاطع فیدیو بجوار كل إعداد للتعرف علیھ وعلى كیفیة استخدامھ خطوة  بخطوة.",


    "permissions": "كیف یمكنني تقیید الصلاحیات لبعض المستخدمین؟",
    "UsersPage": "كیف یمكنني تقیید الصلاحیات  لبعض المستخدمین؟",
    "user": " 1. كیف یمكنني تقیید الصلاحیات لبعض المستخدمین؟ ",
    "Permission": " 2. الضغط على زر صلاحیات وھناك یمكنك إضافة أو إزالة أي صلاحیة  لھذا المستخدم. ",



    "naming": "كیف یمكنني تخصیص اسم حقل / مسمى؟",
    "customize": "لتخصیص اسم حقل / مسمى في لین دیسباتشر ، كل ما علیك فعلھ ھو: ",
    "Scroll": " 4. • قم بالتمریر لأسفل وحدد من بین العدید من إعدادات التسمیة المتاحة  لتغییرھا حسب الحاجة.",
    "Update": " 5. ثم الضغط على زر تحدیث المعلومات للحفظ.",


    "softwareSecure": "ھل برنامج لین دیسباتشر آمن؟",
    "LeanDispatcher.": " لين ديساباتشر هو برنامج آمن وففقا لمعايير الاستضافة السحابية المحلية والدولية لراحة بالك ",


    "fleetManagementSoftware": "لماذا استخدام برنامج إدارة أسطول لماذا استخدام برنامج إدارة أسطول",
    "OnlineTrans": "لماذا استخدام برنامج إدارة أسطول إلیھ بسھولة من أي مكان وفي أي وقت وعلى أي جھاز.",


    "transportManagement": "كیف یمكن لبرامج إدارة أسطول  النقل عبر الإنترنت مساعدة - الشركات؟",
    "LeanDispatcherIsAnOnline": "لین دیسباتشر ھو برنامج لإدارة أسطول النقل عبر الإنترنت یساعد الشركات الصغیرة والمتوسطة والكبیرة في رقمنة عملیات أسطول النقل  وتبسیطھا وتسریعھا وإدارتھا وأتمتتھا. ",

    " workFlow": " جعل تدفق عملك أكثر تنظیما وكفاءة لضمان انخفاض النفقات العامة وزيادة الربحیة. ",



    "WhoShouldUse": "من یجب أن یستخدم برنامج إدارة أسطول النقل عبر الإنترنت؟",
    "Anyone": "جعل تدفق عملك أكثر تنظیما وكفاءة لضمان انخفاض النفقات العامةوزیادة الربحیة. ",


    "Newsletter": "قائمتنا البريدية",
    "Subscribe": "اشترك في قائمتنا البريدية ",
    "Submit": "تأكيد",
    "yourEmailAddress": "بريدك الألكتروني",




    "Footer": "تذییل الصفحھ",
    "Digitally ": "إحداث ثورة في عملیات الفوترة اللوجستیة الرقمیة لعملائنا  ",
    "Simple": "لین دیسباتشر ھو برنامج متعدد الاستخدامات، يمكن تطويره وإدارته بسهولة ، ويساعد على تنظيم وإدارة أسطول النقل بكفاءة عبر الانترنت",
    "Leann": "لین دیسباتشر",
    "Featuress": "المیزات",
    "Pricess": "الأسعار",
    "Value": "قیمتنا",
    "Media": "الاعلام",
    "Loginn": "تسجیل دخول",
    "BookDemo": "حجز عرض",
    "respective ": "رابط إلى كل صفحة على حدة ",

    "OtherLinks": "روابط أخرى ",
    "Careersa": " وظائف",
    "PrivacyPolicy": "سياسة الخصوصية",
    "About": "من نحن ",
    " ErtikazSolutions": "ارتكاز سولوشنز",

    "LinksF": "روابط لموقع ارتكاز",



    "ContactUS": "اتصل بنا",
    "mail1": "info@Ertikaz.tech",
    "mail2": "sales@Ertikaz.tech",
    "num": "+966 534 479 020 ",
    "num2": "+966 534 470 076 ",


    "Copyright ": "حقوق النشر © 2023-2022 شركة ارتكاز سولوشنز جميع الحقوق محفوظة .",
    "ErtikazSolutions": "روابط ارتكاز سولیوشنز إلى الموقع",

    "Value2": "قیمتنا",

    "Main": "رئیسي",
    "FAst": " إدارة أسطول نقل عبر الانترنت سریع مرن ",
    "FAst2": "هو برنامج",
    "FAst3": "یصدر وثیقة شحن بیان نقل الخاصة بك في 15 ثانية ",
    "Process": "عملیة",
    "Elevating": " من البدایة الى النھایة",
    "Elevating2": "من البدایة الى النھاية",
    "MakingAPromise": " تقدیم وعد",
    "Planning": "تخطیط الاتصالات",
    "Transporting": "نقل البضائع",
    "Delivery": "إیصال إشعار التسلیم",
    "Instantly": " فواتیر العمیل على الفور",
    "PromisetoCustomers": "عندما تقدم وعودا لعملائك ",
    "PromisetoCustomers2": " یضمن لین دیسباتشر",
    "PromisetoCustomers3": "  البرنامج الأفضل لإدارة أسطول النقل عبر الانترنت",
    "PromisetoCustomers4": " تقدیم تجربة استثنائیة لجمیع أصحاب المصلحة والتوصیل في الوقت المحدد",
    "Approved": " معتمد من قبل شعارات الھیئة العامة للنقل وبیان",
    "BookDemoo": "حجز  عرض  توضیحي",
    "linkD": "رابط لحجز صفحة تجريبية",



    "Empower": "تمكین",
    "Empowering": "يعزز لین دیسباتشر  ",
    "Empowering2": "من التسلیم في الوقت المحدد",
    "FromOrder": "من تقدیم الطلب ، والختم ، والتسلیم إلى التوقیع علیه",

    "DispatchersTracking": " في لین دیسباتشر",
    "DispatchersTracking1": "تسمح إمكانات التتبع والإنترنت ",
    "DispatchersTracking2": " لشركات النقل بأن تكون رقمیة ومرنة وفعالة",
    "GoogleMaps": "تحسین المسار المدعوم الذكاء الاصطناعي من خرائط جوجل",
    "Optimizing": "تحسین",
    "Ai": "یعمل التوجیھ المدعوم بالذكاء الاصطناعي على تحسین آلاف المسارات  في دقائق لإنشاء وقت الوصول المقدر الدقیق للسائقین.",

    "Combining": "الجمع بین",
    "Seamlessly": "اجمع بسلاسة بین التوجیه الدینامیكي والتعدیلات الیدویة في منصة واحدة. ",
    "MachineLearning": "التعلم الآلي",
    "routing": " یتحسن محرك التوجیھ یومیا حیث یجمع التعلم الآلي بیانات حول المسار وكفاءة السائق وأوقات الخدمة.",

    "ScheduleAndTrack": "الجدولة والتتبع",
    "SchedulePrompts": "مطالبات الجدولة",
    "reaches": "یتواصل لین دیسباتشر مع السائقین تلقائیا ، مما یطالبھم بجدولة أو تأكید  أو إعادة جدولة بنقرات داخل التطبیق. ",
    "AutomatedComms": "الاتصالات الآلیة",
    "DayOf": "في یوم التسلیم ، تسمح الاتصالات الآلیة القابلة للتخصیص للعملاء بتلقي معلومات حول وقت التسلیم والفاتورة ووثیقة الشحن عبر الرسائل النصیة.",
    "RatingDrivers": "تصنیف السائقین",
    "Allow": "امكانیة العملاء بتقییم السائق لضمان تجربة ممتازة من الطلب إلى التسلیم. ",


    "Monitoring": "مراقبة",
    "Dashboardd": "لوحة التحكم",
    "ViewEvery": "اعرض كل جانب من جوانب عملیتك في الوقت الفعلي على شاشة واحدة  مع بیانات التسلیم عالیة المستوى.",
    "VisualData": "البیانات المرئیة",
    "DataSummarized": "یتم تلخیص البیانات في رسومات سھلة القراءة ، مما یمنح مدیر السائقین القدرة على التنقل على الفور إلى التفاصیل الكاملة لوحدات الخدمة الفردیةوالمسارات وعملیات التسلیم.",
    "KnowMore": "اعرف المزید",
    "Predict": "توقع المشكلات التشغیلیة قبل حدوثھا واتخذ الإجراءات اللازمة وفقا لذلك لمنعھا.",
    "MediaFormats": "صیغ الوسائط",
    "AllTrip": "یتم تسجیل جمیع بیانات الرحلة للمراجعة بصیغ عدیدة مثل الصور والملاحظات الصوتیة وتسجیل مقاطع الفیدیو والتوقیعات.",

    "Documentation": "توثیق",
    "Seemless": "تواصل سلس",
    "TheLean": "یوفر تطبیق لین دیسباتشر اتصالا سلسا وفوریا بین العملاء والسائقین ومدیر السائقین.",
    "TripData": "بیانات الرحلة",
    "DriversCan": "یمكن للسائقین التحقق من الشحن التي تم تسلیمھا ، وكتابة ملاحظات حول  صعوبات الرحلة ، والتقاط الصور ، وجمع التوقیعات ، وتحمیل كل شيء على السحابة بحیث یتم أرشفتھ وإتاحتھ على الفور لمدراء السائقین.",
    "AppAvailability": "توفر التطبیق",
    "AndriodANdios": "یتوفر تطبیق Lean Dispatcher  على Android & iOS.",

    "Report": "تقریر وتحلیل",
    "Centralized": "مركزیة",
    "AllCentralized": "جمیع البیانات التي تم إنشاؤھا بواسطة لین دیسباتشر مركزیة ، بحیث یمكن الإبلاغ عنھا وتحلیلھا على الفور.",
    "Reporting": "تقریر",
    "Fine": "اضبط عملیاتك من خلال تحلیل البیانات من التقاریر المضمنة. یمكنك   أیضا إنشاء تقاریر مخصصة لالتقاط الطلبات والرحلات من بیانات  الوقت الفعلي.",



    "Form": "شكل",
    "BookaDemo": "احجز عرض",
    "please": "یرجى ملء النموذج أدناه وسیتواصل معك فریقنا.",
    "Name2": " الاسم",
    "Position": " المنصب",
    "Company": "الشركة",
    "Mobile": "رقم الهاتف ",
    "Email": "البرید الإلكتروني",
    "DemoDate": "تاریخ العرض",
    "Book a Demo": "احجز عرض",
    "Registers": "یسجل على النظام للمبیعات لمراجعتھا ",


    "Login2": "تسجیل الدخول",
    "AccountNumber": "رمز الحساب",
    "SignIn": "تسجیل الدخول",
    "LinkToplatform ": " رابط إلى النظام الأساسي ",
    "Username": "اسم المستخدم",
    "Password": "كلمة المرور",
    "RememberMe": "تذكرني",
    "Dont ": "لیس لدیك حساب؟ ",
    "Register": "إنشاء حساب جديد",
    "RegPage": " رابط لصفحة التسجیل",
    "CompanyName": "اسم الشركة",
    "SignPage": "  Link to sign in page",
    "ConfirmPassword": "تأكید كلمة المرور",
    "Attach": "إرفاق شھادة رقم ضریبة القیمة المضافة",
    "AlreadyHave? ": "ھل لدیك حساب؟ ",

    "Careers": "الوظائف",
    "FirstName": "الاسم لأول",
    " Send CV": " ارسل CV",
    "Last Name": "الاسم الأخير",
    "AttachCV": "ارفق CV",


    "videos": "تصفح مقاطع الفیدیو الخاصة بنا لمعرفة المزید عن لین دیسباتشر",
    "Important": "من المھم إضافة  فیدیو كیفیة إصدار  بیان لأنھ یحتوي على  حركة مرور",



    OURPrivacyPolicy: "سياسة الخصوصية",
    yourPrivacy: "نحن نقدر خصوصيتك",
    Committed: "نحن ملتزمون بالحفاظ على بياناتك بأمان.",
    Whenyouuse: " عندما نستخدم أحد منتجاتنا ، فإنك تأمننا على معلوماتك المالية الشخصية والمهنية  ",
    Whenyouuse2: " نحن نساعد في حماية هذه البيانات وحماية خصوصيتك باستخدام التقنيات والممارسات الرائدة في المجال",

    YourTrust: "ثقتكم بنا",
    AtErtikazSolutions: "في ارتكاز سولوشنز، نحن ملتزمون بشدة بالحفاظ على خصوصية وأمن بياناتك. بصفتنا مزودا رائدا للبرامج كخدمة (SaaS) ، فإننا نتفهم المسؤولية الهائلة التي نتحملها في إدارة وحماية المعلومات الحساسة التي تعهد بها إلينا.",
    SafetyInMind: "السلامة في الاعتبار ",
    reflecting: "تم تصميم سياسة الخصوصية الخاصة بنا مع وضع سلامتك في الاعتبار ، مما يعكس التزامنا بالحفاظ على أمان بياناتك. تحكم هذه السياسة كيفية جمع المعلومات واستخدامها وصيانتها والكشف عنها التي تم جمعها من مستخدمينا. وهي تغطي معلومات التعريف الشخصية ومعلومات التعريف غير الشخصية وملفات تعريف الارتباط لمتصفح الويب وكيف نحمي معلوماتك.",
    SecurityArchitecture: "البنية الأمنية ",
    OurRobust: "تضمن البنية الأمنية القوية الخاصة بنا تخزين بياناتك بشكل آمن والتعامل معها بعناية ، مع الالتزام بأعلى المعايير لحماية البيانات. نحن نستخدم أحدث تقنيات التشفير للحماية من الوصول غير المصرح به أو التغيير أو الكشف أو إتلاف معلوماتك الشخصية ومعلومات المعاملات والبيانات المخزنة على موقعنا.",
    CybersecurityMeasures: "تدابير الأمن السيبراني",
    Beyond: "علاوة على ذلك ، لدينا تدابير شاملة للأمن السيبراني لاكتشاف ومنع التهديدات والانتهاكات المحتملة. تتم مراقبة أنظمتنا وتحديثها باستمرار لمواجهة التهديدات الجديدة ، مما يضمن بقاء بياناتك آمنة في النطاق الرقمي الدائم للتطور.",
    DataUsage: "استخدام البيانات",
    InTerms1: "فيما يتعلق باستخدام البيانات ، يتم استخدام بياناتك فقط لتوفير خدماتنا وتحسينها. نحن نؤمن بمبدأ الحد الأدنى الضروري ، مما يعني أننا نجمع ونعالج البيانات التي نحتاجها فقط لتقديم خدماتنا بشكل فعال.",
    InTerms2: "نحن لا نشارك بياناتك الشخصية أو نبيعها أو نؤجرها لأطراف ثالثة دون موافقتك الصريحة. في حال احتجنا إلى العمل مع موفري خدمات تابعين لجهات خارجية ، فإننا نضمن امتثالهم لمعايير حماية البيانات الصارمة الخاصة بنا.",
    StrengtheningSecurity: "تعزيز الأمن",
    WeInvestHeavily: "في ارتكاز سولوشنز ، ثقتك هي أولويتنا القصوى. نحن نتفهم أن خدماتنا مثل الثقة التي تضعها فينا ، ونحن ملتزمون بكسب هذه الثقة والحفاظ عليها. ",
    WeInvestHeavily2: "نحن نستثمر بكثافة في البنية التحتية الأمنية الخاصة بنا ونعمل باستمرار على تعزيز تدابير الأمان لدينا لحماية بياناتك بشكل أفضل.",
    matters: "خصوصيتك تهمنا",
    matters2: "ونحن ملتزمون بحمايتها",
    AnyQuestions: "إذا كانت لديك أي أسئلة حول ممارسات الخصوصية الخاصة بنا أو إذا كنت بحاجة إلى مساعدة بشأن بياناتك ، فإن فريق دعم العملاء المخصص لدينا جاهز دائما للمساعدة.",


  }
}


export default ar