import React from 'react'
import {Typography} from '@mui/material';
import { useTranslation } from "react-i18next";
import "./PrivacyHead.scss"
const PrivacyHead = () => {
    const {t} = useTranslation()
    return (
        <div className='pvh-container'>
            <div className='pvh-wrapper'>
                <div className='pvh-first'>
                    <h1 className='pvh-h1'>{t ('OURPrivacyPolicy')} </h1>
                </div>
                
                <div className='pvh-btn'>
                <Typography  sx={{width : "843.67px" , 
                    padding : "20px 0",
                    color : "white",
                    borderRadius: "14px",
                    bgcolor: "secondary.main",
                    

                    "@media (max-width: 767px)": {
                        width: "480px",
                    }
                    ,  "@media (max-width: 480px)": {
                        width: "320px",
                        height : "50px",
                    }
                    ,  "@media (max-width: 320px)": {
                        width: "300px",
                    }
                    }}><h2 className='pvhbtn-h2'>{t("Committed")} </h2> </Typography>
                </div>
                    <p className='pvh-p'>{t("Whenyouuse")} <br/> {t("Whenyouuse2")} </p>
            </div>
        </div>
  )
}

export default PrivacyHead
