import "./TalkToUs.scss"
// images 
import x from "../../icons/x.png"
import logo from "../../icons/logo.svg"
import whats from "../../icons/popWhats.png"
import envelop from "../../icons/envelop.png"
import call from "../../icons/call.png"
import { useState } from "react";

const TalkToUs = ({active2 , setActive2}) => {

  const [active, setActive] = useState(false)

  const handleClick = () => {
    setActive(!active)
  }
  
    return (
      <>
      <div className={`talk-to-us ${active ? "active" : ""}`} onClick={handleClick}>
        {localStorage.getItem("lang") === "en" ? <span>chat with us</span> : <span>تواصل معنا</span>}
      </div>
      {active || active2 ? <PopUp setActive={setActive} setActive2={setActive2} /> : null}
      </>
    )
  
}

const PopUp = ({setActive , setActive2}) => {

  const [copied, setCopied] = useState(false)

  const handleCopy = () => {
    navigator.clipboard.writeText("+966550379547");
    setCopied(true)
  }
  
  

    if(localStorage.getItem("lang") === "en") {
      return (
        <div className="pop">
          <div className='popUp'>
          <div className="icon" onClick={() => {
            setActive(false)
            setActive2(false)
          } }><img src={x} alt="" /></div>
            <div className="content">
              <img src={logo} alt="" />
              <h2>Welcome to Lean Dispatcher</h2>
              <h2>Start Chat with:</h2>
              <div className="contact">
  
                <div className="item"><a href="https://api.whatsapp.com/send?phone=966550379547&text=Welcome%20To%20Lean%20Finance"><img src={whats} alt="" /></a></div>
  
                <div className="item"><a href="
                https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&source=mailto&to=info@ertikaz.tech"
                target="blank"><img src={envelop} alt="" /></a></div>
  
                <div className="item" onClick={ () => handleCopy()}><img src={call} alt="" /></div>
  
              </div>
              {copied && <h4>Number Is Copied</h4>}
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="pop">
          <div className='popUp'>
          <div className="icon" onClick={() => {
            setActive(false)
            setActive2(false)
          } }><img src={x} alt="" /></div>
            <div className="content">
              <img src={logo} alt="" />
              <h2>مرحبا بك في لين ديسباتشر</h2>
              <h2>ابدأ الدردشة معنا :</h2>
              <div className="contact">
  
                <div className="item"><a href="https://api.whatsapp.com/send?phone=966550379547&text=Welcome%20To%20Lean%20Finance"><img src={whats} alt="" /></a></div>
  
                <div className="item"><a href="
                https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&source=mailto&to=info@ertikaz.tech"
                target="blank"><img src={envelop} alt="" /></a></div>
  
                <div className="item" onClick={ () => handleCopy()}><img src={call} alt="" /></div>
  
              </div>
              {copied && <h4>Number Is Copied</h4>}
            </div>
          </div>
        </div>
      )
    }
  

}

export default TalkToUs
