import React from 'react'
import { Button } from "@mui/material";
import "./CrossSell.scss"
import crossSell from '../../images/cross-sell.svg';
import { useTranslation } from "react-i18next";
import {Environment} from '../../constants/environment.ts'
const CrossSell = ({setActive}) => {
    const {t} = useTranslation()
    return (
        <div className='cros-container'>
            <div className='cros-wrapper'>
                <div className='cros-left'>
                    <img className='img28' src={crossSell} alt=''/>
                </div>
                <div className='cros-right'>
                    <h1 className='crosh1'>{t("E-Invoicing1")} <span>{t("E-Invoicing2")}</span> {t("E-Invoicing3")}</h1>
                    <p className='crosp1'>{t("EasilyCreate")}.</p>
                    <Button
                        onClick={() => window.open(Environment.financeContactPage)}
                        color='primary'
                        variant='contained'
                        sx={{
                            padding : "13px 60px",
                            borderRadius: "16px",
                            fontSize: "18px",
                            lineHeight: "22px",
                            color:"white",
                            mb : {xs : 20 , sm : 0}
                        }}
                    >
                        {t('ContactUs')}
                    </Button>
                </div>
                
            </div>
        </div>
    )
}

export default CrossSell
