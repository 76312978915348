import React, { useEffect } from 'react'
import PrivacyHead from '../../components/PrivacyHead/PrivacyHead'
import PrivacyContent from '../../components/PrivacyContent/PrivacyContent'
import "./Privacy.scss"
const Privacy = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    } , [])
    
    return (
        <div className='pvcpage'>
            <PrivacyHead/>
            <PrivacyContent/>
        </div>
    )
}

export default Privacy


