export const en = {
  translation: {
    // Navbar 
    lang: "AR",
    Overview: "Overview",
    Features: "Features",
    "AppNav": "Prices",
    "CaseStudiesNav": "Value",
    MediaCenter: "Media & News",
    "loginNav": "Login",
    BookAdemo: "Book a demo",
    "registerNav": "Register",
    "language": "العربية",





    // Footer
    "Lean Finance is": "Lean Finance is a simple and dynamic online e-Invoicing software that helps you grow your business.",

    "Issue, manage, send": "Issue, manage, send your ZATCA approved e-Invoices and calculate your tax in seconds.",

    "Approvedby": "Approved by",

    "Experience it today": "Experience it today",

    "Sitemap": "Sitemap",
    "Lean FinanceF": "Lean Dispatcher",
    "IndustriesF": "Industries",
    "About Us": "About Us",
    "Ertikaz Solutions": "Ertikaz Solutions",
    "Ertikaz": "Ertikaz",
    "Lean Finance": "Lean Finance",
    "Contact Us": "Contact Us",
    "Info@Ertikaz.Tech": "Info@Ertikaz.Tech",
    "Sales@Ertikaz.Tech": "Sales@Ertikaz.Tech",
    "+966 534 470 076": "+966 534 470 076",
    "+966 534 479 020": "+966 534 479 020",
    "Copyright © 2024-2025": "Copyright © 2024-2025",
    "Inc.": "Inc.",
    "All Rights Reserved": "All Rights Reserved",

    "otherLinks": "Other Links",
    "careers": "Careers",

    "Comment ": "Overview page ",

    "overview": "Overview",


    "saudiAndVision2030Logos": "Add the Made in Saudi & Vision 2030 logos",
    "is a simple": "is a simple, flexible and scalable online transport fleet management software",
    "easilyManageAutomate": "Easily manage & automate your orders, trips, vehicles, operations and resources.",
    "issue": "Issue your Bayan Naql waybill in 15 seconds!",
    "supporting": "Supporting 11 different currencies.",
    " approved": "Approved by TGA & Bayan Logos",
    "BookDemo ": " Book a demo",
    "linkToTheBook": " Link to the book a demo page",




    "digitallyRevolutionizing": "Digitally revolutionizing the end-to-end supply chain operations management",
    "totalOrders": "Orders",
    "totalTrips": "Trips ",
    "totalWay": "Way Bills Issued ",
    "totalVehicle": "Vehicles ",
    "totalOrders2": "70K",
    "totalTrips2": "8.7K",
    "totalWay2": "1.5K",
    "totalVehicle2": "3.3K",

    "totalCustomers": "Total Customers",
    "totalCountries": "Total Countries",
    "totalMiles": "Total Miles",


    "ourPartners": " Our Partners In Success",


    "Industries ": " Industries ",
    Flexible: "Flexible and customizable ",
    Flexible1: "for your industry",
    GroceryAnsFood: "Grocery & Food",
    ECommerce: "E-Commerce",
    BigANdBulky: "Big & Bulky",
    Carriers: "Carriers",
    GlobalCompanies: "Global Companies",
    Hazardous: "Hazardous",
    Mining: "Mining",
    Retail: "Retail",
    Pharmacy: "Pharmacy",
    Healthcare: "Healthcare",
    Construction: "Construction",
    InternationalCompanies: "International Companies",
    GovAndPublic: "Gov & Public Transport",
    OilAndGas: " Oil & Gas",
    Logistic: "Logistic",



    "Benefits": "Benefits",
    transportFleetManagement: "The one transport fleet",
    transportFleetManagement2: "  management software you need",
    TurnkeySolutionForLocalAndGlobal: "Turnkey solution for local and global transport companies  ",
    UpliftyYourCustomerExperience: "Uplift your customer experience ",
    FastOnboardingSolution: "Fast onboarding solution.",
    ImmediateRegionalAccessToSaudi: "Immediate regional access to Saudi.",
    DigitalizeYourBusiness: "Digitalize your business ",
    ops: "Easily streamline & scale your ops",
    LowerOverheadsAndRaiseProfits: " Lower overheads & raise profits",
    IssueBayanNaql: "Issue Bayan Naql Waybill in seconds",
    improveDeliveryTimes: "Improve delivery Times",
    realTimeTracking: "Real-time fleet & performance tracking",
    informedDecisions: "Make informed decisions",
    flexibleIntegration: "Flexibly integrate",
    customerSupport: "Excellent customer and support service",



    "Key Features": "Key Features",
    "ElevateYourBusiness": "To elevate your business to new heights ",
    "Manage": "Effortlessly manage all aspects of your operation",
    "Multilingual": "Multilingual is the way to go",
    "On The Go": "Accessible from everywhere on any device",
    "Compliance": "Compliant with regulators",
    "TGA&Byan": "Add TGA & Bayan Logos",
    "Customize": "Customize it to your needs",
    "Collaborate": "Collaborate with your team",
    "Bayan": "Issue Bayan Naql in 15 Seconds!",
    "App": "Dedicated driver app",
    "Route": "Route optimization",
    "Hybrid": "Hybrid scheduling and management",
    "BulkUpload": "Bulk information upload",
    "Dashboard": "Realtime visibility dashboard",
    "Shipment": "Shipment tracking and proof of delivery",
    "Comms": "2 way communication",
    "Billing": "Automatic billing and settlement",
    "API": "API integrations with ERPs",
    "Share": "Instantly share documents",
    "Tracking": "Keep an eye on your fleet with Live tacking",
    "Modular": "Modularly built for your comfort",
    "Security": "A secure software for your ease of mind",
    "Support": "Free after sales tech support",
    "Go Green": "Support Saudi Arabia’s green initiatives",
    "StartNow ": "Start now ",




    "ld": "Lean Dispatcher",
    "Testimonials": "Testimonials",
    "whatOurClientsHave": "This is what our clients have to say about Lean Dispatcher",
    "Droob AlBilad Owner": "Droob AlBilad",
    "owner": "Owner",
    "There was a learning": "There was a learning period at the start like any other software but with continued use it became very easy, wish we knew about your company sooner.",
    "Using Lean Dispatcher to issue": "Using Lean Dispatcher to issue Bayan Naql in 30 seconds only is convenient. Im very pleased with my experience and the speed of response from the tech team. ",
    // client 2
    "Droob AlBilad Owner2": "AHK",
    "owner2": "Operation Manager",
    "There was a learning2": "The software’s simplicity is excellent.",
    "There was a learning2_2": "We like the features like the dashboard, quick Bayan Naql waybill issuing and cancelling, saving the driver and vehicle info, and knowing the late and completed trips by color codes.",
    "There was a learning2_3": "We are satisfied with using Lean Dispatcher.",
    // client 3 
    "Droob AlBilad Owner3": "One Logistics",
    "owner3": "Operation Manager",
    "There was a learning3": "The software is very very very easy. Organizing jobs is easy and having the finance work separated from the admin work is very comfortable.",
    "There was a learning3_2": "The software is organized and money collection is faster and better without complications.",
    "There was a learning3_3": "I’m very satisfied and masha’allah the team is very supportive all the time, they accept comments flexibly and their response is very fast.",


    "OneLogistics": "One Logistics",
    "OperationManager": " Operation Manager",
    "AHK": "AHK",
    "OperationManagers": " Operation Managers",
    "softwareIsVeryEasy": "The software is very very very easy. Organizing jobs is easy and having the finance work  separated from the admin work is very comfortable.The software is organized and money collection is faster and better without complications. I’m very satisfied and masha’allah the team is very supportive all the time, they accept comments flexibly and their response is very fast.",
    " software’sSimplicity":
      "The software’s simplicity is excellent. We like the features like the dashboard, quick Bayan Naql waybill issuing and cancelling, saving the driver and vehicle info, and knowing the late and completed trips by color codes.We are satisfied with using Lean Dispatcher",



    CaseStudies: "Case Studies",
    lorem5: "Logistic Industry",
    companyName: "The ONE Logistics ",
    small: "Small",
    businessDescription: "The One Logistics is a company specializing in moving exotic luxury vehicles around the world by Sea, Air & Road.",
    challenge: "Challenges",
    fleetManagement: "Manual fleet management.",
    invoicing: "Manual invoicing.",
    LeanDispatcher: "Lean Dispatcher helped them",
    manageBusiness: "Seamlessly manage the business, and raise efficiency.",
    singlePlatform: "Single platform that is perfectly in compliance with TGA & ZATCA.",
    digitalization: "Digitalizing the business to fulfill and receiving more business.",
    improveCustomerExperience: "Improving the customer experience.",
    eliminateFinesAndHassle: "Eliminated fines and hassle with drivers by utilizing our solution.",
    companyName2: "AHK Logistics ",
    Large: "Large",
    businessDescription2: "AHK is a transportation service provider. Transporting F&B, chemicals and other materials locally and regionally.",
    challenge2: "Challenges",
    driverCommunication: "Communicating with their drivers.",
    documentManagement: "Manual management of documents.",
    statusUpdates: "No on-spot status updates.",
    improveDriverCommunication: "Improve communication with drivers.",
    digitalizeManagement: "Digitalize the management process.",
    eliminatePhysicalDocumentation: "Eliminate physical documentation.",
    improveDocumentAccess: "Improved archiving and continuous access to documents.",
    improveCustomerClarity: "Improve clarity for customers through on-spot status updates.",
    ExperienceItNow: "Experience it now",
    LinkToBook: "Link to book a demo page",


    "Currencies": "Currencies",

    "OurLeadingProducts": "We are proud of our leading products in Saudi Arabia",
    "OurLeadingProducts2": "that can be effectively utilized around the world",

    "CurrenciesAvailable": "Currencies available on Lean Dispatcher ",



    "GCC": "GCC",
    "SAR": " ریال SAR ",
    "AED": "د.ا AED",
    "OMR": "ریال OMR",
    "QAR": " ریال QAR",
    "KWDKD": "KWD KD ",
    "BHD": " .د.ب BHD",
    "SAR2": " Saudi Arabia ",
    "AED2": "Emirates",
    "OMR2": "Oman",
    "QAR2": "Qatar",
    "KWDKD2": "Kuwait ",
    "BHD2": "Bahrain",

    "MENA": "MENA",
    "JODJD": "JOD JD ",
    "EGP£": "EGP £ ",
    "JODJD2": "Jordan ",
    "EGP£2": "Egypt",

    "GLOBAL": "GLOBAL",
    "USD$": "USD $",
    "GBP£": "GBP £ ",
    "CAD$": "CAD $ ",
    "USD$2": "United States",
    "GBP£2": "Britain",
    "CAD$2": "Canada",


    "Pricing": "Pricing",
    "Prices": "Prices",
    "SolutionsFit": "Solutions fit for your needs",
    "LeanDispatcherLite ": "Lean Dispatcher Lite ",
    "Lean-Dispatcher": "Lean Dispatcher",
    "LeanDispatcherPremium": "Lean Dispatcher Premium",
    "ContactForQuote": "Contact us for a quote",
    "CTALinks": "CTA links to chat with sales pop up to display contact info and other aspects.",




    "IssuingAndManaging": "Issuing and managing ",
    "IssuingAndManaging2": "your Bayan Naql waybills",
    "UploadBulkDriver": "Upload bulk driver ",
    "UploadBulkDriver2": "data via excel",
    "UploadBulkVehicles": "Upload bulk vehicles ",
    "UploadBulkVehicles2": "data via excel",
    "LeanDescript1": "Lean Dispatcher ",
    "LeanDescript2": "Online transport fleet management software. Approved by TGA & Bayan Naql",

    "TripsOrdersDrivers": "Trips, orders, drivers, vehicles ",
    "TripsOrdersDrivers2": "and maintenance management",
    "customerPortal1": "Your customer’s portal ",
    "customerPortal2": "to raise and schedule orders",
    "customerPortal3": "(Long term contracts).",
    "customerPortal4": "allowing you to negotiate rates",
    "portalToRaise": "and allow you to negotiate rates.",

    "DispatchingOwnFleet": " Dispatching to own fleet ",
    "DispatchingOwnFleet2": " or contracted fleet",
    "EagleEye": "Eagle eye ",
    "EagleEye2": "tracking",

    "Reordering": " Reordering",
    "ExtractReports": " Extract ",
    "ExtractReports2": "reports",
    "Invoice": "Invoice, receipts, debit and ",
    "Invoice2": " credit notes management ",
    "issuingZATCA": "Issuing ZATCA  ",
    "issuingZATCA2": " compliant invoices ",
    "Inventory": "Inventory, suppliers,",
    "Inventory2": "warehouses, purchasing, ",
    "Inventory3": "sales and expenses management ",

    "ZATCA":
      " Dispatcher Premium ",

    "ZATCA2":
      "Online transport fleet management software. That also supports e-Invoicing and inventorying services. Approved by TGA, Bayan Naql & ZATCA.",

    "LeanDescript": " Dispatcher ",
    "LeanDescript22": "Online transport fleet management software. Approved by TGA & Bayan Naql.",
    "Lean": "Lean",
    "LeanDescriptlite": " Dispatcher Lite ",
    "LeanDescriptlite2": "Online Byan waybill management software. Approved by TGA & Bayan Naql.",



    "NaqlWaybills": "Issuing and managing your Bayan Naql waybills.",
    " DispatchingOwnFleet": " Dispatching to own fleet or contracted fleet",



    "CrossSell": "Cross Sell",
    "E-Invoicing1": "Looking for a simple and dynamic",
    "E-Invoicing2": "online e-invoicing",
    "E-Invoicing3": "software to improve your business?",
    "Approved0": "Approved by ",
    "EasilyCreate": "Easily create, edit and manage your clients, invoices, credit / debit notes and receipt vouchers. Approved by ZATCA",
    "ContactUs": " Contact us",

    "CTALinksToChat": "CTA links to chat with sales pop up to display contact info and other aspects.",

    "FAQ": "FAQ",

    "SaaS": "What is SaaS?",
    "SaaSMeanSoftware": "SaaS mean software as a service which is a cloud based software that allows users to easily access it from anywhere.",

    "fleetPerformance": "What is a fleet performance management software?",
    "manage&Automate ": "A software used to easily manage & automate your transport & fleet operations, orders, trips, vehicles and resources. ",


    "online fleet performance": " What is an online fleet performance  mannagement software?",
    "softwareThatAllows": "Is a cloud based software that allows you to do all operations and store documents on the cloud without the need to have them on your devices.",


    " createAnOrder": "How can I create an order? ",
    "createAnOrderOnLean": "To create an order on Lean Dispatcher all you need to do is: ",
    "Login": "1. Login to your account.",
    "Orders": "2. Go to the Orders page from main menu.",
    "CreateOrder": "3. Click the Create Order Button to add a single order.",
    "neededInformation": "4. Fill in the needed information.",
    "listed": "5. Once orders are created they are then listed in the trips page. ",



    "createBulkOrders": "How can I create bulk orders at once?",
    "createAnBulkOrdersOnLean": "To create bulk orders on Lean Dispatcher all you need to do is:",
    "Download2": "3. To add bulk orders, click the Download Excel button, once downloaded you can add info accordingly. ",
    "Upload ": "4. Then click on the Upload Excel button to upload your bulk orders to the system.",


    "assignTrips": "How can I assign trips?",

    "automatically": "Trips are automatically added to the trip page once orders have been created.",
    "pending": "They are then pending activation and needs to assign a driver.",



    "assignDriver": "How can I assign a driver to a trip?",
    "assignTrips2": "To create trips orders on Lean Dispatcher all you need to do is:",
    "Trips": "2. Go to the Trips page from main menu.",
    "Locate": "3. Locate the newly added trip, then click on Assign Driver button.",
    "select": "4. Select the driver, the vehicle, the plates and then click the Save button to assign and activate your trip",



    "ordersToLeanDispatcher": "How can I have my client directly add orders to Lean Dispatcher?",
    "clientAdd": "To have your client add orders in Lean Dispatcher all you need to do is:",
    " createAUser": " 2. Once you create a user for your client he will be able to create orders.",
    "LoginToHIsAccount": "3. He can then Login to his account and go to the Orders page from main menu.",
    "Fill": "5. Fill in the needed information.",
    "Download3": "6. To add bulk orders, click the Download Excel button, once downloaded you can add info accordingly then press on the Upload  Excel button to add it to the system.",
    " ExternalOrders": "7. Once orders are created they are then listed in the External Orders page for you to review and approve. ",



    "trackMyFleet": "How can I track my fleet? ",
    "easilyTrack": "You can easily track your active fleet from the Eagle Eye page and selecting the desired trip to review all info.",


    "addANewClient": "How can I add a new client",
    "Newlients": "To add new clients on Lean Dispatcher all you need to do is: ",
    "Settings": "2. Go to the Settings section from main menu.",
    "Contact": "3. Go to the Contact page. ",
    "AddContact": "4. Then click on the Add Contact button.",
    "clickCreate": "5. Fill in the required info and click Create.  ",
    "NewContact": "6. Or you can create a new contact when creating a new order and filling in the source and pickup information. ",


    "addANewVehicle": "How can I add a new vehicle type and plates?",
    " vehicleType": "To add a new vehicle type and plates on Lean Dispatcher all you need to do is:",
    "vehicle": "3. Go to the vehicle page. ",
    "CreateVehicleType": " 5. Fill in the required info and click Create Vehicle Type.",
    "CreatePlate": "6. Then select that new vehicle type and add click the Create Plate button. ",


    "AddNewDriver": "How can I add a new driver?",
    " driver": "To add a new driver on Lean Dispatcher all you need to do is:",
    "Drivers": "3. Go to the Drivers page. ",
    "CreateDriver": "4. Then click on the Create Driver button. ",
    "CreateDriverUser": " 5. Fill in the required info and click Create User.",


    "newProduct": "How can I add a new product? ",
    "products": " To add a products on Lean Dispatcher all you need to do is:",
    "Products": "3. Go to the Products page. ",
    "AddProduct": " 4. Click the Add Product Button. ",
    "fillInfo": "5. Fill in the needed information.",
    "CreateProduct": "6. Then click the Create Product to add your product.",
    "reateANewProduct": "7. Or you can create a new product when creating a new order and filling in the product information.",


    "setting": "How can I know what a setting does in My Account page?",
    "Settingss": "7. Or you can create a new product when creating a new order and filling in the product information.",
    "Threedots": " 2. Click the 3 dots in the top right corner.",
    "My Account.": " 3. Then click My Account.",
    "OurTeam": "4. Our team made sure that your experience is as simple as possible by adding videos next to each setting to learn about them and how to use it step-by-step.",


    "permissions": "How can I restrict permissions for certain users?",
    "UsersPage": "Once you have created all the users from the Users page under settings in Lean Dispatcher, you can:",
    "user": " 1. Find the user that you want to grant or edit their permission. ",
    "Permission": " 2. Click the Permission button and there you can add or remove any permission for this user. ",


    "naming": "How can I customize a label / field naming?",
    "customize": "To customize any label / field naming in Lean Dispatcher all you need to do is:",
    "Scroll": " 4. Scroll down and select from the many label naming settings to change as needed.",
    "Update": " 5. Then click Update Information to save.",


    "softwareSecure": "Is the Lean Dispatcher software secure?",
    "LeanDispatcher.": " Lean Dispatcher is a secured software by local and international cloud hosting standards to ease your mind.",


    "fleetManagementSoftware": "Why should I use an online transport fleet management software?",
    "OnlineTrans": "With an online transport fleet management software you can easily have access to it from anywhere, anytime and on any device",


    "transportManagement": "How can online transport fleet management softwares help businesses?",
    "LeanDispatcherIsAnOnline": "Lean Dispatcher is an online transport fleet management software that helps small, medium and large businesses in digitizing, simplifying, expediting, managing and automating their transport fleet operations. ",
    " workFlow": " Making your work flow more organized and efficient to ensure lower overheads and increase profitability.  ",


    "WhoShouldUse": "Who should use an online transport fleet management software?",
    "Anyone": "Anyone that has a logistics and transport business up and running will needs a versatile and scalable software to effortlessly manage and automate all aspects of the business to ensure best results. ",






    "Newsletter": "Newsletter",
    "Subscribe": "Subscribe to our newsletter for the latest news & updates",
    "Submit": "Submit",
    "yourEmailAddress": "your email address",



    "Footer": "Footer",
    "Digitally ": "Digitally revolutionizing the end-to-end supply chain operations management ",
    "Simple": "Lean Dispatcher is a simple, flexible and scalable online transport fleet management software",
    "Leann": "Lean Dispatcher",
    "Featuress": "Features",
    "Pricess": "Prices",
    "Value": "Value",
    "Media": "Media",
    "Loginn": "Login",
    "BookDemo": "Book a Demo",
    "respective ": "Link to each respective page",

    "OtherLinks": "Other Links ",
    "Careersa": " Careers",
    "PrivacyPolicy": "Privacy Policy",

    "About": " About Us ",
    " ErtikazSolutions": "Ertikaz Solutions",
    "LinksF": "Links to Ertikaz website",



    "ContactUS": "Contact Us ",
    "mail1": "info@Ertikaz.tech",
    "mail2": "sales@Ertikaz.tech",
    "num": "+966 534 479 020 ",
    "num2": "+966 534 470 076 ",


    "Copyright ": "Copyright © 2024-2025 Ertikaz Solutions Inc. All Rights Reserved.",
    "ErtikazSolutions": "Ertikaz Solutions links to website.",



    "Value2": "Value",


    Main: "Main",
    FAst: "A fast & dynamic online transport fleet management ",
    FAst2: "software that ",
    FAst3: "issues your Bayan Naql Waybill in 15 seconds!",


    "Process": "Process",
    "Elevating": "Elevating your end-to-end ",
    "Elevating2": "supply chain process",
    "MakingAPromise": " Making a promise",
    "Planning": "Planning communication",
    "Transporting": "Transporting goods",
    "Delivery": "Delivery note receipt",
    "Instantly": " Instantly invoicing customer",
    "PromisetoCustomers": "When you make a promises to your customers",
    "PromisetoCustomers2": "Lean Dispatcher, ",
    "PromisetoCustomers3": "the premiere online transport fleet management software,",
    "PromisetoCustomers4": "ensures you give the best customer experience to all stakeholders and deliver on time. ",
    "Approved": " Approved by TGA & Bayan Logos",
    "BookDemoo": "Book a demo",
    "linkD": "Link to book a demo page",



    "Empower": "Empower",
    Empowering: "Lean Dispatcher ",
    Empowering2: "Empowering on time delivery ",
    FromOrder: "From order placing, sealing, delivering to signing for it",
    DispatchersTracking: "Lean Dispatcher’s ",
    DispatchersTracking1: "tracking and online capabilities ",
    DispatchersTracking2: "allow transport companies to be digitized, agile, and efficient.",

    GoogleMaps: "Google Map’s AI powered route optimization",
    Optimizing: "Optimizing",
    Ai: "AI powered routing optimizes thousands of routes in minutes to produce accurate ETAs for your drivers.",
    Combining: "Combining",
    Seamlessly: "Seamlessly combine dynamic routing with manual adjustments in a single session. ",
    MachineLearning: "Machine Learning",
    routing: " The routing engine improves every day as machine learning gathers data about route, driver efficiency, and service times.",

    ScheduleAndTrack: "Schedule & Track",
    SchedulePrompts: "Schedule Prompts",
    reaches: "Lean Dispatcher reaches out to drivers automatically, prompting them to schedule, confirm, or reschedule via in-App clicks. ",
    AutomatedComms: "Automated Comms",
    DayOf: "On the day of delivery, customizable automated communications allow customers to receive info about their delivery time, bill and waybill via text messages. ",
    RatingDrivers: "Rating Drivers",
    Allow: "Allow customers to rate the driver to ensure an excellent experience from order to delivery. ",


    Monitoring: "Monitor",
    Dashboardd: "Dashboard",
    ViewEvery: "View every aspect of your operation in real time on a single screen with the high level delivery data",
    VisualData: "Visual Data",
    DataSummarized: "Data is summarized in easy-to-read graphics, giving dispatchers the ability to instantly drill down to full details for individual service units, routes, and deliveries.",
    KnowMore: "Know More",
    Predict: "Predict operational issues before they occur and take action accordingly to prevent them.",
    MediaFormats: "Media Formats",
    AllTrip: "All trip data is recorded for review in many formats such as photos, voice notes, videos recording, and signatures.",

    Documentation: "Documentation",
    Seemless: "Seemless Comms",
    TheLean: "The Lean Dispatcher app provides instant seamless communication between customers, drivers and dispatchers.",
    TripData: "Trip Data",
    DriversCan: "Drivers can check on items delivered, write notes about trip difficulties, take photos, gather signatures, and upload everything to the cloud so it’s archived and instantly available to dispatchers.",
    AppAvailability: "App Availability",
    AndriodANdios: "The Lean Dispatcher app is available on Android & iOS.",

    Report: "Report & Analyze",
    Centralized: "Centralized",
    AllCentralized: "All of the data generated by Lean Dispatcher is centralized, so it can instantly be reported and analyzed.",
    Reporting: "Reporting",
    Fine: "Fine tune your operations by analyzing data from built-in reports. You can also create custom reports to capture orders and trips from real-time data.",



    "Form": "Form",
    BookaDemo: "Book a Demo",
    "please": "Please fill the below form and our team will be in touch.",
    "Name2": " Name",
    "Position": " Position",
    "Company": "Company",
    "Mobile": "Mobile",
    "Email ": "Email",
    "DemoDate": "Demo Date",
    "Book a Demo": "Book a Demo",
    "Registers": "Registers on the system for sales to review ",


    "Login2": "Login",
    "AccountNumber": "Account Number",
    "SignIn": "Sign In",
    "LinkToplatform ": " Link to platform ",
    "Username": "Username",
    "Password": "Password",
    "RememberMe": "Remember me",
    "Dont ": "Don’t have an account? ",
    "Register": "Register",
    "RegPage": " Link to registration page",
    "CompanyName": "Company Name",
    "SignPage": "  Link to sign in page",
    "Email": "Email",
    "ConfirmPassword": "Confirm Password",
    "Attach": "Attach VAT Number Certificate",
    "AlreadyHave? ": "Already have an account? ",

    "Careers": "Careers",
    "FirstName": "First Name",
    "Send CV": " Send CV",
    "Last Name": "Last Name",
    "AttachCV": "Attach CV",


    "videos": "Browse our videos to learn more about Lean Dispatcher",
    "Important": "Important to add the How to issue byan video as it has traffic",



    OURPrivacyPolicy: " Our Privacy Policy",
    yourPrivacy: "We value your privacy",
    Committed: "We're committed to keeping your data safe",
    Whenyouuse: " When you use one of our products you trust us with your personal and professional financial information. ",
    Whenyouuse2: " We help safeguard that data and protect your privacy by using industry-leading technology and practices.",
    YourTrust: "Your Trust in Us",
    AtErtikazSolutions: "At Ertikaz Solutions, we are deeply committed to maintaining the privacy and security of your data. As a leading Software-as-a-Service (SaaS) provider, we understand the enormous responsibility we have in managing and protecting the sensitive information that you entrust us with.",
    SafetyInMind: "Safety in Mind ",
    reflecting: "Our privacy policy is designed with your safety in mind, reflecting our commitment to keeping your data secure. This policy governs how we collect, use, maintain, and disclose information collected from our users. It covers personal identification , non-personal identification information, web browser cookies, and how we protect your information",
    SecurityArchitecture: " Security Architecture",
    OurRobust: "Our robust security architecture ensures that your data is securely stored and carefully handled, adhering to the highest industry standards for data protection. We employ state-of-the-art encryption technologies to protect against unauthorized access, alteration, disclosure, or destruction of your personal information, transaction information, and data stored on our site.",
    CybersecurityMeasures: " Cybersecurity Measures",
    Beyond: " Beyond this, we have comprehensive cybersecurity measures in place to detect and  prevent potential threand breaches. Our systems are continuously monitored and updatedcounter emerging threats, ensuring that your data remains safe inever-evolving digital landscape.",
    DataUsage: " Data Usage",
    InTerms1: "In terms of data usage, your data is used solely  to provide and improve our services. We believe in a minimum necessary principle, which means we only collect and process the data we need to deliver our services effectively. We never share, sell or rent your personal data to third parties without your explicit consent. In case we need to work with third-paservice providers, we ensure they comply with our stringent protection standards.",
    InTerms2: "We are transparent about our data practices. You have the right to khow your data is used and stored, and we provide tools and options you to view, modify, and delete your data. You also have the choiceopt-out of certain practices or features if you wish.",
    StrengtheningSecurity: "Strengthening Security",
    WeInvestHeavily: "At Ertikaz Solutions, your trust is our top priority. We understand that our services are only as  good as the trust you place in us, and we're dedicated to earning and preserving that trust.",
    WeInvestHeavily2: "We invest heavily in our security infrastructure  and continuously work towards strengthening our security measures to safeguard your data better.",
    matters: "Your privacy matters to us ",
    matters2: "and we are committed to protect it",
    AnyQuestions: "If you have any questions about our privacy practices or if you need assistance with your data, our dedicated customer support team is always ready to help",


  }
}

export default en