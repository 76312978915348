import React from 'react'
import "./Testimonials.scss"

import { useTranslation } from "react-i18next";
import Vector66 from '../../images/Vector.svg';

// Swiper 
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import { Autoplay } from 'swiper';
import 'swiper/css/pagination';




const Testimonials = () => {
    const {t} = useTranslation()
    return (
        <div className='teste-container'>
            <div className='teste-wrapper'>
                <div className='teste-left'><p className='teste-p-left'>{t('whatOurClientsHave')}</p></div>
                <div className='teste-mid'><img src={Vector66} alt=''/></div>
                <div className="swiper-container">
                    <Swiper
                        grabCursor={true}
                        spaceBetween={20}
                        slidesPerView={"1"}
                        modules={[Autoplay]}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                        }}
                        loop
                        speed={2000}
                    >
                        <SwiperSlide>
                            <div className='teste-right'>
                                <p className='teste-p1'>{t('There was a learning2')}</p>
                                <p className='teste-p1'>{t('There was a learning2_2')}</p>
                                <p className='teste-p1'>{t('There was a learning2_3')}</p>
                                <div className='teste-owner' >
                                    <h1 className='teste-h1'>{t("owner2")}</h1> 
                                    <h1 className='teste-h1'>{t('Droob AlBilad Owner2')}</h1>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='teste-right'>
                                <p className='teste-p1'>{t('There was a learning3')}</p>
                                <p className='teste-p2'>{t('There was a learning3_2')}</p>
                                <p className='teste-p2'>{t('There was a learning3_3')}</p>
                                <div className='teste-owner' >
                                    <h1 className='teste-h1'>{t("owner3")}</h1> 
                                    <h1 className='teste-h1'>{t('Droob AlBilad Owner3')}</h1>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='teste-right'>
                                <p className='teste-p1'>{t('There was a learning')}</p>
                                <p className='teste-p2'>{t('Using Lean Dispatcher to issue')}</p>
                                <div className='teste-owner' >
                                    <h1 className='teste-h1'>{t("owner")}</h1> 
                                    <h1 className='teste-h1'>{t('Droob AlBilad Owner')}</h1>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>  
        </div>
    )
}

export default Testimonials
