import React from 'react'
import { useTranslation } from "react-i18next";
import Image29 from '../../images/approved.png';
import './Approved.scss'

const Approved = () => {
const {t} = useTranslation()
    return (
        <div className='app-container'>
            <div className='app-wrapper'>
            <div className='app-content'>
                    {t("Approvedby")}
            </div>
            <img className='app-img-a' src={Image29} alt=''/>
            </div>
        </div>
    )
}

export default Approved
