// images
import footerLogo from "../../icons/footer-logo.svg";
import face from "../../icons/face.png";
import ig from "../../icons/ig.png";
import twitter from "../../icons/twitter.png";
import youtube from "../../icons/youtube.png";
import linked from "../../icons/linked.png";
import whats from "../../icons/whats.png";
import googlePlay from "../../icons/googlePlay.png";
import appStore from "../../icons/appStore.png";
import logos3 from "../../images/footer-logos.svg";
import vector1 from "../../images/dot.png";
// imports
import "./Footer.scss";
import { Button, Stack, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { HashLink as Hash } from 'react-router-hash-link';
import { useTranslation } from "react-i18next";
import GoDaddySSL from "../goddady-ssl/goddady-ssl";


const Footer = () => {

  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div className="Footer" id="footer">
      <div className="container">
        <Stack direction={{ xs: "column", md: "row" }} alignItems={{ xs: "start", md: "flex-start" }} spacing={{ xs: 25, md: 100 }}>

          <Stack
            sx={{
              bgcolor: "white",
              py: 18,
              pl: { xs: 10, md: 28 },
              pr: { xs: 20, md: 44 },
              pb: 25,
              width: "100%",
              maxWidth: "610px",
            }}
          >
            <div className="logo">
              <img src={footerLogo} alt="" />
            </div>

            <Typography
              variant="h5"
              sx={{
                fontWeight: "700",
                fontSize: { xs: "14px", md: "20px" },
                lineHeight: "25px",
                color: "#5E5E5E",
                mb: 10,
              }}
            >
              {t("ld")} {t("is a simple")}
            </Typography>

            <Stack spacing={6} mb={16}>
              <Typography sx={{
                fontSize: "16px",
                lineHeight: "20px",
                color: "#5E5E5E",
                fontWeight: "400",
                display: "flex",
                alignItems: "start",
                gap: "12px",
                maxWidth: "400px",

              }}>
                <img src={vector1} alt="" style={{ marginTop: "3px" }} />
                {t("easilyManageAutomate")}
              </Typography>
              <Typography sx={{
                fontSize: "16px",
                lineHeight: "20px",
                color: "#5E5E5E",
                fontWeight: "400",
                display: "flex",
                alignItems: "center",
                gap: "12px",
              }}>
                <img src={vector1} alt="" />
                {t("issue")}
              </Typography>
              <Typography sx={{
                fontSize: "16px",
                lineHeight: "20px",
                color: "#5E5E5E",
                fontWeight: "400",
                display: "flex",
                alignItems: "center",
                gap: "12px",
              }}>
                <img src={vector1} alt="" />
                {t("supporting")}
              </Typography>
            </Stack>

            <Stack direction={"row"} alignItems={"center"} spacing={12} mb={20} >
              <Typography sx={{ fontWeight: "700" }} >
                {t("Approvedby")}
              </Typography>
              <img src={logos3} alt="" />
            </Stack>

            <Button
              onClick={() => navigate("/demo")}
              sx={{
                width: "fit-content",
                py: 8, px: { xs: 28, sm: 32 }, mt: 13,
                borderRadius: "16px",
                fontSize: "19px",
                "&:hover": { color: "secondary.main" },
              }} variant="contained" ><Link to="/demo" >{t('Experience it today')}</Link></Button>
          </Stack>

          <Stack pt={10} >

            <Stack direction="row" spacing={{ xs: 20, md: localStorage.getItem("lang") === "en" ? 70 : 20 }}
              justifyContent={{ xs: "space-between" }} sx={{ color: "white", mb: { xs: 20, md: 22 } }} >
              <Stack spacing={20} >

                <div className="list">
                  <h2>{t('Sitemap')}</h2>
                  <Link to="/">{t('Lean FinanceF')}</Link>
                  <Link to="/">{t('Overview')}</Link>
                  <Hash to="/#features">{t('Features')}</Hash>
                  <Hash to="/#pricing">{t('AppNav')}</Hash>
                  <Hash to="/value">{t('CaseStudiesNav')}</Hash>
                  <Link to="/media">{t('MediaCenter')}</Link>
                  <Link to="/login" >{t('loginNav')}</Link>
                  <Link to="/demo" style={{ textTransform: "none" }}>{t('BookAdemo')}</Link>
                </div>

                <div className="list">
                  <h2>{t('otherLinks')}</h2>
                  <Link to="/careers">{t('careers')}</Link>
                  <Link to="/privacy">{t('PrivacyPolicy')}</Link>
                  <Link to="https://ertikaz.tech">{t('Ertikaz')}</Link>
                  <Link to="https://finance.ertikaz-tech.com">{t('Lean Finance')}</Link>
                </div>

              </Stack>
              <Stack spacing={20}>
                <div className="list">
                  <h2>{t('About Us')}</h2>
                  <Link to="https://ertikaz.tech" target="_blank" >{t('Ertikaz Solutions')}</Link>
                </div>
                <div className={localStorage.getItem("lang") === "en" ? "list" : "list fist"}>
                  <h2>{t('Contact Us')}</h2>

                  <li className="email"><a href="https://mail.google.com/mail/u/0/?fs=1&to=info@ertikaz.tech+&su&body&tf=cm" rel="noreferrer" target="_blank">{t('Info@Ertikaz.tech')}</a></li>

                  <li className="email"><a href="https://mail.google.com/mail/u/0/?fs=1&to=sales@ertikaz.tech+&su&body&tf=cm" rel="noreferrer" target="_blank" >{t('Sales@Ertikaz.tech')}</a></li>

                  <li>{t('+966 534 470 076')}</li>
                  <li>{t('+966 534 479 020')}</li>
                </div>
              </Stack>
            </Stack>

            <Stack direction={{ xs: "column", md: "row" }} alignItems={{ xs: "flex-start", md: "center" }} spacing={{ xs: 10, md: 20 }} mb={18}>
              <a href="https://apps.apple.com/us/app/lean-dispatcher/id1566348457" target="_blank" rel="noreferrer">
                <img src={appStore} alt="" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.lean.dispatcher" target="_blank" rel="noreferrer"><img src={googlePlay} alt="" /></a>
            </Stack>

            <Stack direction="row" alignItems="center" spacing={8} mb={10} className="socialMedia">
              <Link to="https://www.youtube.com/@ertikazTechnology" target="_blank" >
                <img src={youtube} alt="" />
              </Link>
              <Link to="https://www.instagram.com/ertikazofficial" target="_blank" >
                <img src={ig} alt="" />
              </Link>
              <Link to="https://www.linkedin.com/company/ertikazsolutions/mycompany/?viewAsMember=true" target="_blank" >
                <img src={linked} alt="" />
              </Link>
              <Link to="https://x.com/ertikazsolution?s=21&t=uK7TZkh_uM1EU56mB6XfnA" target="_blank" >
                <img width="28" src={twitter} alt="" />
              </Link>
              <Link to="https://web.facebook.com/profile.php?id=100089105286837" target="_blank" >
                <img src={face} alt="" />
              </Link>
              <Link to="https://api.whatsapp.com/send?phone=966550379547&text=Welcome%20To%20Lean%20Finance" target="_blank" >
                <img src={whats} alt="" />
              </Link>
            </Stack>

            <Typography variant="h6" sx={{ fontSize: "14px", color: "#F1EFF4", textAlign: "left" }}>
              {t('Copyright © 2024-2025')}
              <strong>
                &nbsp;
                <a className="irtekaz" href="https://ertikaz.tech/" rel="noreferrer" target="_blank">
                  {t('Ertikaz Solutions')}
                </a>
                &nbsp;
              </strong>
              {t('Inc.')} {t('All Rights Reserved')}

              <GoDaddySSL />
            </Typography>

          </Stack>

        </Stack>
      </div>
    </div>

  );

};

export default Footer;
