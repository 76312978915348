// images
import ld from "../../icons/footer-logo.svg"

// Imports 
import "./Login.scss"
import { Stack , Typography , Button } from "@mui/material";
import { Link } from "react-router-dom";
import { Second } from "../Demo/Demo";
import { useEffect,useState } from "react";
import {Buffer} from 'buffer';
import {Environment} from '../../constants/environment.ts';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BeatLoader } from 'react-spinners';
import Modal from 'react-modal';
Modal.setAppElement('#root'); 


function Login() {
  const [loading, setLoading] = useState(false);
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: '1px solid #ccc',
      borderRadius: '10px',
      padding: '20px',
      width: '300px',
    } 
  };
  useEffect(() => {
      window.scrollTo(0, 0);
  } , []);
  const getCurrentUTCTicks =()=> {
    // The number of milliseconds from the UNIX epoch to the .NET epoch (January 1, 0001)
    const epochDiff = 62135596800000;

    // The current UTC time in milliseconds since the UNIX epoch
    const utcNow = Date.now(); // This already returns the UTC time in milliseconds
    // Convert the time difference from milliseconds to ticks (1 tick = 100 nanoseconds = 0.0001 milliseconds)
    return (utcNow + epochDiff) * 10000;
  }
  const hmacSha512 = async(message,key)=>{
    const encoder = new TextEncoder();
    const keyData = encoder.encode(key);
    const messageData = encoder.encode(message);
  
    const cryptoKey = await window.crypto.subtle.importKey(
      "raw",
      keyData,
      { name: "HMAC", hash: "SHA-512" },
      false,
      ["sign"]
    );
  
    const signature = await window.crypto.subtle.sign("HMAC", cryptoKey, messageData);
    
    // Convert the signature to a hexadecimal representation
    const byteArray = new Uint8Array(signature);
    const hexCodes = [...byteArray].map(byte => {
      return byte.toString(16).padStart(2, "0");
    });
    
    return hexCodes.join("");
  }

  const handleSubmit = async (event) => {
    // Prevent page reload
    event.preventDefault();
    let model={};
    model.code=document.forms['loginForm']['accNum'].value;
    model.accountText=document.forms['loginForm']['accNum'].value;
    model.username=document.forms['loginForm']['username'].value;
    model.password=document.forms['loginForm']['password'].value;
    model.rememberMe = document.getElementById("remember").checked;
    let data=JSON.stringify(model); 
    setLoading(true);
    await fetch(`${Environment.Api}/api/Auth/checkLogin`, {
    method: "POST", 
    headers: {
      "Content-Type": "application/json",
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      'Accept-Language': localStorage.getItem("lang"),
    }, 
    body: data, 
    }).then((res)=>res.json().then((response)=>{
      if(response.hasError){
        setLoading(false);
        toast.error(response.message);
      }else{
        toast.success(response.message);
        const time=getCurrentUTCTicks();
        const secret=`${response.data}_${time}`;
        const key = Buffer.from(data).toString('base64');
        hmacSha512(key,secret).then(hash=>{
          window.location.href=`${Environment.Web}/login?key=${key}&secret=${secret}&hash=${hash}`;
        });        
      }
    })).catch(e=>{
      setLoading(false);
      console.log(e);
      toast.error('Error');
    });
  };
  if (localStorage.getItem("lang") === "en") {
    return (
      <div className="Login">
      <div className="dFirst login">
        
        <Stack direction={{xs : "column" , md : "row"}} alignItems={{xs : "center" , md : "start"}} spacing={30} justifyContent="center">
          <Stack width="fit-content" py={{xs : 16 , sm : 32}} px={{xs : 15 , sm : 30}} border="1px solid" borderColor="text" alignItems="center" borderRadius="50px">
            <div><img src={ld} alt="" /></div>
            <Typography sx={{
              fontWeight : "400",
              fontSize : "24px",
              mb :20,
              mt : 15
            }}>
            Login
            </Typography>
            <form id="loginForm" name='loginForm' onSubmit={handleSubmit}>
              <Stack spacing={12} mb={12} alignItems="center">
                <input type="text" name="accNum" placeholder="Account Number" />
                <input type="text" name="username" placeholder="Username" />
                <input type="password" name="password" placeholder="Password" />
              </Stack>
              <Stack direction="row" spacing={4} alignItems="center" justifyContent="center" mb={20}>
                <input id="remember" type="checkbox" className="checkbox"/>
                <Typography>
                  Remember me
                </Typography>
              </Stack>
              <Stack alignItems="center">
                <Button type="submit" variant="contained" sx={{
                    py : 9 , px : 45, width : "fit-content", borderRadius : "18px", mb : 28,
                    "&:hover": { color: "secondary.main" },
                  }}>Sign In</Button>
              </Stack>
              <Typography color="text.secondary" sx={{
                textAlign : "center",
                fontSize : "22px",
              }}>
              Don’t have an account?
              </Typography>
              <Typography color="#00A5E4" sx={{
                textAlign : "center",
                fontSize : "22px",
              }}>
                <Link to="/sign-up">Register</Link>
              </Typography>
            </form>
          </Stack>
        </Stack>
        <Modal isOpen={loading} style={customStyles}>
        <BeatLoader color="#4A90E2" />
      </Modal>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        />
      </div>
      <Second />
    </div>
    );
  } else {
    return (
      <div className="Login">
      <div className="dFirst login">
  
        
        <Stack direction={{xs : "column" , md : "row"}} alignItems={{xs : "center" , md : "start"}} spacing={30} justifyContent="center">
          <Stack width="fit-content" py={{xs : 16 , sm : 32}} px={{xs : 15 , sm : 30}} border="1px solid" borderColor="text" alignItems="center" borderRadius="50px">
            <div><img src={ld} alt="" /></div>
            <Typography sx={{
              fontWeight : "400",
              fontSize : "24px",
              mb :20,
              mt : 15
            }}>
            تسجيل الدخول
            </Typography>
            <form id="loginForm" name='loginForm' onSubmit={handleSubmit}>
              <Stack spacing={12} mb={12} alignItems="center">
                <input type="text" name="accNum" placeholder="رمز الحساب" />
                <input type="text" name="username" placeholder="اسم المستخدم" />
                <input type="password" name="password" placeholder="كلمة المرور" />
              </Stack>
              <Stack direction="row" spacing={4} alignItems="center" justifyContent="center" mb={20}>
                <input id="remember" type="checkbox" className="checkbox"/>
                <Typography>
                  تذكرني
                </Typography>
              </Stack>
              <Stack alignItems="center">
                <Button type="submit" variant="contained" sx={{
                    py : 9 , px : 45, width : "fit-content", borderRadius : "18px", mb : 28,
                    "&:hover": { color: "secondary.main" },
                  }}>تسجيل الدخول</Button>
              </Stack>
              <Typography color="text.secondary" sx={{
                textAlign : "center",
                fontSize : "22px",
              }}>
              ليس لديك حساب ؟
              </Typography>
              <Typography color="#00A5E4" sx={{
                textAlign : "center",
                fontSize : "22px",
              }}>
                <Link to="/sign-up">إنشاء حساب جديد</Link>
              </Typography>
            </form>
          </Stack>
        </Stack>
        <Modal isOpen={loading} style={customStyles}>
        <BeatLoader color="#4A90E2" />
      </Modal>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={true}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        />
      </div>
      <Second />
    </div>
    );
  }
  
}

export default Login;