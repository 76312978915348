import React, { useState,useEffect } from 'react'
import "./Figurs.scss"
import { useTranslation } from "react-i18next";
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import {Environment} from '../../constants/environment.ts';
const Figurs = () => {
const [countOun , setCountOn] = useState(false);
const [apiData,setApiData] = useState(null);
useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${Environment.Api}/api/Dashboard/Home`);
      const data = await response.json(); 
      setApiData(data.data);
    };

    fetchData();
  }, [setApiData]);
const {t} = useTranslation()
return (
    <div className='fig-container'>
        <div className='fig-wrapper'>
            <h1 className='figh1'>{t("digitallyRevolutionizing")} </h1>
            {apiData?
            <div className='fig-rect'>
                <div className='figele'>
                    <span className='figspan1'> 
                        <ScrollTrigger
                            onEnter={() => setCountOn(true)}
                            onExit={()=> setCountOn(false)}
                        >
                            {countOun && <CountUp start={0} end={apiData.ordersCount}  duration={2} delay={0} />}
                        </ScrollTrigger>
                    </span>
                    <span className='figspan2'>{t("totalOrders")}</span>
                </div>
                <div className='figele'>
                    <span className='figspan1'>
                        <ScrollTrigger
                            onEnter={() => setCountOn(true)}
                            onExit={()=> setCountOn(false)}
                        >
                            {countOun && <CountUp start={0} end={apiData.tripsCount} duration={2} delay={0} />}
                        </ScrollTrigger>
                    </span>
                    <span className='figspan2'>{t("totalTrips")}</span>
                </div>
                <div className='figele'>
                    
                    <span className='figspan1'>
                        <ScrollTrigger
                            onEnter={() => setCountOn(true)}
                            onExit={()=> setCountOn(false)}
                        >
                            {countOun && <CountUp start={0} end={apiData.vehiclesCount} duration={2}  delay={0} />}
                        </ScrollTrigger>
                    </span>
                    <span className='figspan2'>{t("totalVehicle")}</span>
                </div>
                <div className='figele'>
                    <span className='figspan1'>
                        <ScrollTrigger
                            onEnter={() => setCountOn(true)}
                            onExit={()=> setCountOn(false)}
                        >
                            {countOun &&<CountUp start={0} end={apiData.waybillsCount} duration={2} delay={0} />}
                        </ScrollTrigger>
                    </span>
                    <span className='figspan2'>{t("totalWay")}</span>
                </div>
            </div>
             :(<div>...</div>)
            }
        </div>
    </div>

    )
}

export default Figurs
