import React from 'react'
import "./Benefits.scss"
import { useTranslation } from "react-i18next";
// import styles from './Button.module.css';
import vector1 from "../../images/dot.png"



const Benefits = () => {
const {t} = useTranslation()

return (

        <div className='ben-container'>
                <div className='ben-wrapper'>

                        <div className='ben-top'>
                                <h1 className='ben-title'> {t("transportFleetManagement")} <br/> {t("transportFleetManagement2")}  </h1>
                                <div className='ben-bt'>{t("TurnkeySolutionForLocalAndGlobal")}</div>
                        </div>
                        
                        <div className='ben-bottom'>
                                <div className='ben-single'>
                                        <p> <img src={vector1} alt="" />  {t("UpliftyYourCustomerExperience")}</p>
                                        <p> <img src={vector1} alt="" />  {t("FastOnboardingSolution")}</p>
                                </div>

                                <div className='ben-single'>
                                        <p> <img src={vector1} alt="" />  {t("ImmediateRegionalAccessToSaudi")}</p>
                                        <p> <img src={vector1} alt="" />  {t("DigitalizeYourBusiness")}</p>
                                </div>

                                <div className='ben-single'>
                                        <p> <img src={vector1} alt="" />  {t("IssueBayanNaql")}</p>
                                        <p> <img src={vector1} alt="" />  {t("ops")}</p>
                                </div>

                                <div className='ben-single'>
                                        <p> <img src={vector1} alt="" /> {t("LowerOverheadsAndRaiseProfits")}</p>
                                        <p> <img src={vector1} alt="" /> {t("flexibleIntegration")}</p>
                                </div>

                                <div className='ben-single'>
                                        <p> <img src={vector1} alt="" /> {t("customerSupport")}</p>
                                        <p> <img src={vector1} alt="" /> {t("realTimeTracking")}</p>
                                </div>

                                <div className='ben-single'>
                                        <p> <img src={vector1} alt="" /> {t("informedDecisions")}</p>
                                        <p> <img src={vector1} alt="" /> {t("improveDeliveryTimes")}</p>
                                </div>

                        </div>
                </div>
        </div>
)
}

export default Benefits
