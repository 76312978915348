import React from 'react'
import './PrivacyContent.scss'
import { useTranslation } from "react-i18next";
import Image38 from '../../images/image 38.svg';
import Image39 from '../../images/image 39.svg';
import Image40 from '../../images/image 40.svg';


const PrivacyContent = () => {
    const {t} = useTranslation()
    return (
        <div className='pvc-container'>
            <div className='pvc-wrapper'>
                <div className='pvc-top'>
                    <div className='pvc-ele'> 
                        <div className='pvc-ele-tit'><h1 className='pvc-ele-h11'> {t ('YourTrust')}</h1></div>
                        <div className='pvc-ele-cont'><p className='pvc-ele-p'> {t ('AtErtikazSolutions')}</p> </div>
                    </div>
                    <div className='pvc-ele'> 
                        <div className='pvc-ele-tit'><h1 className='pvc-ele-h1'> {t ('SafetyInMind')}</h1></div>
                        <div className='pvc-ele-cont'><p className='pvc-ele-p'> {t ('reflecting')}</p> </div>
                    </div>
                    <div className='pvc-ele'> 
                        <div className='pvc-ele-tit'><h1 className='pvc-ele-h1'> {t ('SecurityArchitecture')}</h1></div>
                        <div className='pvc-ele-cont'><p className='pvc-ele-p'> {t ('OurRobust')}</p> </div>
                    </div>
                    <div className='pvc-ele'> 
                        <div className='pvc-ele-tit'><h1 className='pvc-ele-h11'> {t ('CybersecurityMeasures')}</h1></div>
                        <div className='pvc-ele-cont'><p className='pvc-ele-p'> {t ('Beyond')}</p> </div>
                    </div>
                    <div className='pvc-ele'> 
                        <div className='pvc-ele-tit'><h1 className='pvc-ele-h11'> {t ('DataUsage')}</h1></div>
                        <div className='pvc-ele-cont'><p className='pvc-ele-p'> {t ('InTerms1')} <br /> <br /> {t ('InTerms2')} </p> </div>
                    </div>
                    <div className='pvc-ele'> 
                        <div className='pvc-ele-tit'><h1 className='pvc-ele-h1'> {t ('StrengtheningSecurity')}</h1></div>
                        <div className='pvc-ele-cont'><p className='pvc-ele-p'> {t ('WeInvestHeavily')} <br /> <br /> {t ('WeInvestHeavily2')} </p> </div>
                    </div>

                </div>
                <div className='pvc-bot'>
                    <div className='pvc-bot-tit'> 
                        <h2 className='pvc-bot-h3'>{t("matters")} </h2>
                        <h2 className='pvc3'> {t("matters2")}</h2> 
                        
                    </div>
                    <div className='pvc-bot-para'> <p className='pvc-bot-p'>{t("AnyQuestions")} </p> </div>
                    <div className='pvc-icons'>
                        <img src={Image38} alt=''/>
                        <img src={Image39} alt=''/>
                        <img src={Image40} alt=''/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrivacyContent
