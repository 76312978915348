import React, { useRef, useState } from 'react'
import "./Main.scss"
import { useTranslation } from "react-i18next";
import Button from '@mui/material/Button';
import video from '../../videos/vid.mp4'
import videoAr from '../../videos/vid-ar.mp4'
import thumbImg from "../../icons/footer-logo.png"
import playBtn from "../../icons/playBtn.png"
import Vector1 from '../../images/dot.png';

import { Link } from 'react-router-dom';


const Main = () => {
    // vid
    const videoRef = useRef();

    const [videoActive, setVideoActive] = useState(false)

    const [thumb , setThumb] = useState(true)


    const handlePlay = () => {
        setThumb(false)
        if (!videoActive) {
        videoRef.current.play();
        setVideoActive(true)
        } else {
        videoRef.current.pause();
        setVideoActive(false)
        }
    }
    
    
const {t} = useTranslation()
    return (
        <div className='main-container'>
            <div className='main-wrapper' >
                <div className='main-left'>
                    <div className='mainh1'>
                        <h1 className='main-h1'>
                            {t("ld")}
                            <br />
                            {t("is a simple")}
                        </h1>
                    </div>
                    <div className='mainp'>
                        <div className='mainpdiv'>
                            <img src={Vector1} alt="" />
                            <p className='main-p'>{t("easilyManageAutomate")}</p>
                        </div>
                        <div className='mainpdiv'>
                            <img src={Vector1} alt="" />
                            <p className='main-p'>{t("issue")}</p>
                        </div>
                        <div className='mainpdiv'>
                            <img src={Vector1} alt="" />
                            <p className='main-p'>{t("supporting")}</p>
                        </div>
                    </div>
                    <div className='main-btn' >
                        <Link to="/demo" >
                            <Button color='secondary' variant='contained' sx={{
                                py : 9,
                                px : 23,
                                color : "white",
                                borderRadius : "16px",
                            }}>
                                    {t("BookaDemo")}
                            </Button>
                        </Link>
                    </div>
                </div>
                <div className='main-right'>
                <div className={`video ${videoActive ? "active" : null}`} onClick={handlePlay}>
                    {thumb && 
                        <div className="thumb">
                            <img src={thumbImg} alt="" />
                        </div>
                    }
                    <video loop src={localStorage.getItem("lang") === "en" ? video : videoAr } ref={videoRef} />

                    <button onClick={handlePlay} className={videoActive ? "active" : null}>
                        <img src={playBtn} alt="" />
                    </button>
                </div>
                </div>
            </div> 
        </div>
    )
}

export default Main
