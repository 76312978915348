import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./styles/style.scss"

// RTL

import {createTheme, ThemeProvider} from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import {prefixer} from 'stylis';
import {CacheProvider} from '@emotion/react';
import createCache from '@emotion/cache';
import './i18/i18';

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

const theme = createTheme({
  direction: 'rtl',
});

const root = ReactDOM.createRoot(document.getElementById('root'));

if(localStorage.getItem("lang") === "en") {
  root.render(
    <React.StrictMode>
      <App/>
    </React.StrictMode>
  );
} else {
  root.render(
    <React.StrictMode>
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={theme}>
          <App/>
        </ThemeProvider>
      </CacheProvider>
    </React.StrictMode>
  );
}

