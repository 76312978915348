import React from 'react'
import './Differences.scss'
import { useTranslation } from "react-i18next";
import Ellipse2 from "../../images/Ellipse 2.svg" ;
import Ellipse27 from "../../images/Ellipse 27.svg" ;


const Differences = () => {
    const {t} = useTranslation()
    return (
    <div className='dif-container'>
        <div className='dif-wrapper'>
            <div className='diff-1st'>
                <p className='dif-1stp'>{t('IssuingAndManaging')} <br/> {t('IssuingAndManaging2')} </p>
                <p className='dif-1stp'>{t('TripsOrdersDrivers')} </p>
                <p className='dif-1stp'>{t('customerPortal1')} <br/> {t('customerPortal2')} <br/>{t('customerPortal3')} </p>
                <p className='dif-1stp'>{t('customerPortal1')} <br/> {t('customerPortal2')} <br/>{t('customerPortal4')} </p>
                <p className='dif-1stp'>{t('DispatchingOwnFleet')} <br/> {t('DispatchingOwnFleet2')} </p>
                <p className='dif-1stp'>{t('EagleEye')} <br/> {t('EagleEye2')} </p>
                <p className='dif-1stp'>{t('Reordering')}</p>
                <p className='dif-1stp'>{t('ExtractReports')} <br/> {t('ExtractReports2')} </p>
                <p className='dif-1stp'>{t('UploadBulkDriver')} <br/> {t('UploadBulkDriver2')} </p>
                <p className='dif-1stp'>{t('UploadBulkVehicles')} <br/> {t('UploadBulkVehicles2')} </p>
                <p className='dif-1stp'>{t('Invoice')} <br/> {t('Invoice2')} </p>
                <p className='dif-1stp'>{t('issuingZATCA')} <br/> {t('issuingZATCA2')} </p>
                <p className='dif-1stp'>{t('Inventory')} <br/> {t('Inventory2')}  <br/> {t('Inventory3')}</p>
            </div>
            <div className='diff-2nd'>
                <div className='box'>
                    <h1 className='dh1'>{t('Lean')} <br/>{t('ZATCA')} </h1>
                    <p className='dp'>{t('ZATCA2')}</p>
                </div>
                <div className='circles'>
                    <div className='dif-image'><img className='circle-img' src={Ellipse2} alt=''/></div>
                    <div className='dif-image'><img className='circle-img' src={Ellipse2} alt=''/></div>
                    <div className='dif-image'><img className='circle-img' src={Ellipse2} alt=''/></div>
                    <div className='dif-image'><img className='circle-img' src={Ellipse2} alt=''/></div>
                    <div className='dif-image'><img className='circle-img' src={Ellipse2} alt=''/></div>
                    <div className='dif-image'><img className='circle-img' src={Ellipse2} alt=''/></div>
                    <div className='dif-image'><img className='circle-img' src={Ellipse2} alt=''/></div>
                    <div className='dif-image'><img className='circle-img' src={Ellipse2} alt=''/></div>
                    <div className='dif-image'><img className='circle-img' src={Ellipse2} alt=''/></div>
                    <div className='dif-image'><img className='circle-img' src={Ellipse2} alt=''/></div>
                    <div className='dif-image'><img className='circle-img' src={Ellipse2} alt=''/></div>
                    <div className='dif-image'><img className='circle-img' src={Ellipse2} alt=''/></div>
                    <div className='dif-image'><img className='circle-img' src={Ellipse2} alt=''/></div>
                </div>
            </div>
            <div className='diff-3rd'>
            <div className='box2'>
                <h1  className='dh1'> {t('Lean')} <br/>{t('LeanDescript')} </h1>
                <p  className='dp'>{t('LeanDescript22')}</p>
            </div>
                <div className='circles'>
                    <div className='dif-image'><img className='circle-img' src={Ellipse2} alt=''/></div>
                    <div className='dif-image'><img className='circle-img' src={Ellipse2} alt=''/></div>
                    <div className='dif-image'><img className='circle-img' src={Ellipse2} alt=''/></div>
                    <div className='dif-image'><img className='circle-img' src={Ellipse2} alt=''/></div>
                    <div className='dif-image'><img className='circle-img' src={Ellipse2} alt=''/></div>
                    <div className='dif-image'><img className='circle-img' src={Ellipse2} alt=''/></div>
                    <div className='dif-image'><img className='circle-img' src={Ellipse2} alt=''/></div>
                    <div className='dif-image'><img className='circle-img' src={Ellipse2} alt=''/></div>
                    <div className='dif-image'><img className='circle-img' src={Ellipse2} alt=''/></div>
                    <div className='dif-image'><img className='circle-img' src={Ellipse2} alt=''/></div>
                    <div className='dif-image'><img className='circle-img' src={Ellipse27} alt=''/></div>
                    <div className='dif-image'><img className='circle-img' src={Ellipse27} alt=''/></div>
                    <div className='dif-image'><img className='circle-img' src={Ellipse27} alt=''/></div>
                </div>
            </div>
            <div className='diff-4th'>
            <div className='box2'>
                <h1 className='dh1'>{t('Lean')} <br/>{t('LeanDescriptlite')} </h1>
                <p className='dp'>{t('LeanDescriptlite2')}</p>
            </div>
                <div className='circles'>
                    <div className='dif-image'><img className='circle-img' src={Ellipse2} alt=''/></div>
                    <div className='dif-image'><img className='circle-img' src={Ellipse27} alt=''/></div>
                    <div className='dif-image'><img className='circle-img' src={Ellipse27} alt=''/></div>
                    <div className='dif-image'><img className='circle-img' src={Ellipse27} alt=''/></div>
                    <div className='dif-image'><img className='circle-img' src={Ellipse27} alt=''/></div>
                    <div className='dif-image'><img className='circle-img' src={Ellipse27} alt=''/></div>
                    <div className='dif-image'><img className='circle-img' src={Ellipse27} alt=''/></div>
                    <div className='dif-image'><img className='circle-img' src={Ellipse27} alt=''/></div>
                    <div className='dif-image'><img className='circle-img' src={Ellipse2} alt=''/></div>
                    <div className='dif-image'><img className='circle-img' src={Ellipse2} alt=''/></div>
                    <div className='dif-image'><img className='circle-img' src={Ellipse27} alt=''/></div>
                    <div className='dif-image'><img className='circle-img' src={Ellipse27} alt=''/></div>
                    <div className='dif-image'><img className='circle-img' src={Ellipse27} alt=''/></div>
                </div>
            </div>
        </div>
    </div>
    )
}


export default Differences
