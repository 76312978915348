// images
import d1 from '../../images/image 38.svg';
import d2 from "../../icons/d2.png" 
import d3 from "../../icons/d3.png" 

import {Environment} from '../../constants/environment.ts';
// Imports 
import { Button, Stack, Typography } from "@mui/material";
import "./Demo.scss"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BeatLoader } from 'react-spinners';
import Modal from 'react-modal';
import { useEffect,useState } from "react";

import { Link } from "react-router-dom";

Modal.setAppElement('#root'); 
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: '1px solid #ccc',
    borderRadius: '10px',
    padding: '20px',
    width: '300px',
  } 
};
 

function Demo() {

  useEffect(() => {
    window.scrollTo(0, 0);
} , [])
  
  return (
    <div className="Demo">
      <First />
      <Second />
    </div>
  );
}

function First() {
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (event) => {
    // Prevent page reload
    event.preventDefault();
    const nowDate=new Date()
    let demoDate = new Date();
    demoDate.setDate(demoDate.getDate() + 1);
    
    
    let demoDateString=document.getElementsByName('date')[0]?.value;
    if(demoDateString){
      demoDate=new Date(Date.parse(demoDateString))
    }
    if(demoDate < nowDate){
      let errorMessage='';
      if(localStorage.getItem("lang") === "ar"){
        errorMessage='تاريخ العرض خاطئ';
        }
        else{
          errorMessage='Invalid Demo Date';
        }
      toast.error(errorMessage);
      return;
    }
    const bodyData={
      name: document.getElementsByName('name')[0]?.value,
      company:document.getElementsByName('company')[0]?.value,
      companyName:document.getElementsByName('company')[0]?.value,
      position:document.getElementsByName('position')[0]?.value,
      mobile: document.getElementsByName('phone')[0]?.value,
      email: document.getElementsByName('email')[0]?.value,
      demoDate: document.getElementsByName('date')[0]?.value,
      dispatcher:true,
    };
    if(!bodyData.name || !bodyData.name.length){
      toast.error(localStorage.getItem("lang") === "ar"?"الرجاء تعبئة الاسم":"Please Fill Name");
      return;
    }
    if(!bodyData.company || !bodyData.company.length){
      toast.error(localStorage.getItem("lang") === "ar"?"الرجاء تعبئة الشركة":"Please Company Name");
      return;
    }
    if(!bodyData.mobile || !bodyData.mobile.length){
      toast.error(localStorage.getItem("lang") === "ar"?"الرجاء تعبئة رقم الجوال":"Please Fill Mobile");
      return;
    }
    if(!bodyData.email || !bodyData.email.length){
      toast.error(localStorage.getItem("lang") === "ar"?"الرجاء تعبئة البريد الالكتروني":"Please Fill Email");
      return;
    }
    const emailExpression=  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailExpression.test(bodyData.email)){
      toast.error(localStorage.getItem("lang") === "ar"?"بريد إلكتروني خاطئ":"Please Enter Valid Email Address");
      return;
    }
    const mobileExpression=  /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\\./0-9]*$/g;
    if (!mobileExpression.test(bodyData.mobile)){
      toast.error(localStorage.getItem("lang") === "ar"?"رقم جوال خاطئ":"Please Enter Valid Mobile Number");
      return;
    }
    
    setLoading(true);
    await fetch(`${Environment.ertikazApi}/send`, {
       method: 'POST',
       body: JSON.stringify(bodyData),
       headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*'
       },
    }).then((res)=>{
      setLoading(false);
      let infoMessage='';
      if(localStorage.getItem("lang") === "ar"){
          infoMessage='تم إرسال الطلب بنجاح';
        }
        else{
          infoMessage='Request has been sent successfully';
        }
      toast.success(infoMessage);
      }).catch(e=>{
      setLoading(false);
      console.log(e);
      toast.error('Error');
    });
  };
  
  if(localStorage.getItem("lang") === "en") {
    return (
      <div className="dFirst">
          <Modal isOpen={loading} style={customStyles}>
        <BeatLoader color="#4A90E2" />
      </Modal>
   <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        />
        <Stack width="fit-content" py={{xs : 20 , sm : 40}} px={{xs: 10 , sm :25}} border="1px solid" borderColor="text" alignItems="center" borderRadius="50px" mx="auto">
          <Typography color="primary" sx={{
            fontWeight : "700",
            fontSize : "30px",
            lineHeight : "40px",
          }}>
          Book a demo
          </Typography>
          <Typography sx={{
            fontWeight : "400",
            fontSize : {xs : "16px" , sm : "20px"},
            lineHeight : {xs : "20px" , sm : "40px"},
            textAlign : {xs : "center" , sm : "left"} ,
            mb :24
          }}>
          Please fill the below form and our team will be in touch.
          </Typography>
          <form onSubmit={handleSubmit}>
            <Stack spacing={12} mb={24} alignItems="center">
              <input type="text" name='name' placeholder="Name" required />
              <input type="text" name='position' placeholder="Position" required />
              <input type="text" name='company' placeholder="Company" required />
              <input type="phone" name='phone' placeholder="Mobile" required />
              <input type="email" name='email' placeholder="Email" required />
              <input type="text" name='date' required className="date" placeholder="Demo date" 
                onFocus={(e) => {
                  e.target.type = "date"
                }}
              />
            </Stack>
            <Stack alignItems="center">
              <Button type="submit" variant="contained" sx={{
                  py : 9 , px : 40, width : "fit-content", borderRadius : "18px",
                  "&:hover": { color: "secondary.main" },
                }}>Book now</Button>
            </Stack>
          </form>
        </Stack>

      </div>
    );
  } else {
    return (
      <div className="dFirst">
          <Modal isOpen={loading} style={customStyles}>
        <BeatLoader color="#4A90E2" />
      </Modal>
   <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={true}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        />
        <Stack width="fit-content" py={{xs : 20 , sm : 40}} px={{xs: 10 , sm :25}} border="1px solid" borderColor="text" alignItems="center" borderRadius="50px" mx="auto">
          <Typography color="secondary" sx={{
            fontWeight : "700",
            fontSize : "30px",
            lineHeight : "50px",
          }}>
          احجز عرض
          </Typography>
          <Typography sx={{
            fontWeight : "400",
            fontSize : {xs : "16px" , sm : "20px"},
            lineHeight : {xs : "20px" , sm : "40px"},
            textAlign : {xs : "center" , sm : "left"} ,
            mb :24
          }}>
          يرجى ملء النموذج أدناه وسيتواصل معك فريقنا.
          </Typography>
          <form onSubmit={handleSubmit}>
            <Stack spacing={12} mb={24} alignItems="center">
              <input type="text" name='name' placeholder="الاسم" required />
              <input type="text" name='position' placeholder="المنصب" required />
              <input type="text" name='company' placeholder="الشركة" required />
              <input type="phone" dir='ltr' name='phone' placeholder="الهاتف" required />
              <input type="email" dir='ltr' name='email' placeholder="الايميل" required />
              <input type="text" name='date' required className="date" placeholder="تاريخ العرض التجريبي" 
                onFocus={(e) => {
                  e.target.type = "date"
                }}
              />
            </Stack>
            <Stack alignItems="center">
              <Button type="submit" variant="contained" sx={{
                  py : 9 , px : 40, width : "fit-content", borderRadius : "18px",
                  "&:hover": { color: "secondary.main" },
                }}>احجز الان</Button>
            </Stack>
          </form>
        </Stack>
      </div>
    );
  }
}

export function Second() {
  return (
    <div className="dSecond">
      
      <Stack alignItems="center" pb={80}>
        <Typography color="text.secondary" sx={{
          fontWeight : "700",
          fontSize : "20px",
          mb : 12
        }}>
        

        {localStorage.getItem("lang") === "en" ? "For support please contact us at" :"للدعم يرجي التواصل معنا عبر" }
        
        </Typography>
        <Stack direction="row" spacing={16}>
          <Link to="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=info@ertikaz.tech" target="_blank"><img src={d1} alt="" /></Link>
          <Link to="https://api.whatsapp.com/send?phone=966550379547&text=Welcome%20To%20Lean%20Finance" target="_blank"><img src={d2} alt="" /></Link>
          <Link to="https://www.youtube.com/@ertikazTechnology" target="_blank"><img src={d3} alt="" /></Link>
        </Stack>
      </Stack>
    </div>
  );
}

export default Demo ;