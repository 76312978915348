// images


// Imports 
import { Button, Skeleton, Stack, Typography } from "@mui/material";
import "./Media.scss"
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ElfsightWidget from '../../components/ElfsightWidget/ElfsightWidget';



function Media() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <div className="Media">
      <First />
      <Second />
    </div>
  );
}

function First() {
  if (localStorage.getItem("lang") === "en") {
    return (
      <div className="mFirst">


        <div className="container">
          <Typography color="white" sx={{
            fontWeight: "700",
            fontSize: { xs: "30px", sm: "44px" },
            lineHeight: "35px",
            textAlign: "center",
          }}>
            Browse our videos to learn more
          </Typography>
          <Typography color="white" sx={{
            fontWeight: "700",
            fontSize: { xs: "30px", sm: "44px" },
            textAlign: "center",
            lineHeight: "35px",
            mb: 20
          }}>
            about Lean Dispatcher
          </Typography>
          <Link to="/demo">
            <Button color="secondary" variant="contained" sx={{
              mb: 20,
              p: "16px 40px",
              color: "white",
              borderRadius: "8px",
              display: "block",
              m: "auto",
            }} >Book a demo</Button>
          </Link>
        </div>
      </div>
    );
  } else {
    return (
      <div className="mFirst">


        <div className="container">
          <Typography color="white" sx={{
            fontWeight: "700",
            fontSize: { xs: "30px", sm: "44px" },
            lineHeight: "50px",
            textAlign: "center",
            mb: 5
          }}>
            تصفح مقاطع الفیدیو الخاصة بنا لمعرفة
          </Typography>
          <Typography color="white" sx={{
            fontWeight: "700",
            fontSize: { xs: "30px", sm: "44px" },
            textAlign: "center",
            lineHeight: "35px",
            mb: 20
          }}>
            المزید عن لین دیسباتشر
          </Typography>
          <Link to="/demo">
            <Button color="secondary" variant="contained" sx={{
              mb: 20,
              p: "16px 40px",
              color: "white",
              borderRadius: "8px",
              display: "block",
              m: "auto",
            }} >احجز عرض</Button>
          </Link>
        </div>
      </div>
    );
  }
}


function Second() {

  const [loading, setloading] = useState(false)

  setTimeout(() => {
    setloading(true);
  }, 1000)

  if (localStorage.getItem("lang") === "en") {
    return (
      <div className="mSecond">

        {loading ? (
          <div className="container">
            <Stack spacing={13}>

              <iframe src="https://www.youtube.com/embed/KvC0FlrC0bo" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
              <Typography sx={{
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "20px",
                bgcolor: "secondary.main",
                py: 7,
                width: "100%",
                color: "white",
                textAlign: "center",
                borderRadius: "14px"
              }}>
                Lean Dispatcher Overview
              </Typography>
            </Stack>
            <Stack spacing={13}>
              <iframe src="https://www.youtube.com/embed/8Ydw-LStfEs" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
              <Typography sx={{
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "20px",
                bgcolor: "secondary.main",
                py: 7,
                width: "100%",
                color: "white",
                textAlign: "center",
                borderRadius: "14px"
              }}>
                Lean Dispatcher PREMIUM Overview
              </Typography>
            </Stack>

          </div>
        ) : (
          <div className="container">
            <Stack spacing={13} >
              <Skeleton animation="wave" variant="rectangular" height="220px" />
              <Skeleton animation="wave" variant="rectangular" height="48px" sx={{ borderRadius: "14px" }} />
            </Stack>
            <Stack spacing={13} >
              <Skeleton animation="wave" variant="rectangular" height="220px" />
              <Skeleton animation="wave" variant="rectangular" height="48px" sx={{ borderRadius: "14px" }} />
            </Stack>
            <Stack spacing={13} >
              <Skeleton animation="wave" variant="rectangular" height="220px" />
              <Skeleton animation="wave" variant="rectangular" height="48px" sx={{ borderRadius: "14px" }} />
            </Stack>
          </div>
        )}


        <ElfsightWidget />


      </div>
    );
  } else {
    return (
      <div className="mSecond">

        {loading ? (
          <div className="container">
            <Stack spacing={13}>
              <iframe src="https://www.youtube.com/embed/n-XcAj3fXyM" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
              <Typography sx={{
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "20px",
                bgcolor: "secondary.main",
                py: 7,
                width: "100%",
                color: "white",
                textAlign: "center",
                borderRadius: "14px"
              }}>
                نظرة عامة على لين دیسباتشر
              </Typography>
            </Stack>
            <Stack spacing={13}>

              <iframe src="https://www.youtube.com/embed/AKVnMQ64pGw" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
              <Typography sx={{
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "20px",
                bgcolor: "secondary.main",
                py: 7,
                width: "100%",
                color: "white",
                textAlign: "center",
                borderRadius: "14px"
              }}>
                نظرة عامة على دیسباتشر بريميوم
              </Typography>
            </Stack>
          </div>
        ) : (
          <div className="container">
            <Stack spacing={13} >
              <Skeleton animation="wave" variant="rectangular" height="220px" />
              <Skeleton animation="wave" variant="rectangular" height="48px" sx={{ borderRadius: "14px" }} />
            </Stack>
            <Stack spacing={13} >
              <Skeleton animation="wave" variant="rectangular" height="220px" />
              <Skeleton animation="wave" variant="rectangular" height="48px" sx={{ borderRadius: "14px" }} />
            </Stack>
            <Stack spacing={13} >
              <Skeleton animation="wave" variant="rectangular" height="220px" />
              <Skeleton animation="wave" variant="rectangular" height="48px" sx={{ borderRadius: "14px" }} />
            </Stack>
          </div>
        )}

        <ElfsightWidget />

      </div>
    );
  }
}



export default Media;