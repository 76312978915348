import React from 'react'
import "./MultibleEmpowers.scss"
import Button from '@mui/material/Button';
import Image31 from "../../images/image 31.jpg" ;
import Image32 from "../../images/image 32.jpg" ;
import Image33 from "../../images/image 33.jpg" ;
import Image34 from "../../images/image 34.jpg" ;
import Image35 from "../../images/image 35.svg" ;
import { useTranslation } from "react-i18next";
import {Link} from "react-router-dom"



const MultibleEmpowers = () => {
    const {t} = useTranslation()
    return (
        <div className='mul-container'>
            <div className='mul-wrapper'>

                
                <div className='mul-eachOne'>
                    <div className='mul-top'>
                        <div className='mul-title'>{t("GoogleMaps")}</div>
                        <div className='mul-btn'>
                            <Link to={"/demo"} >
                                <Button className='mv-btn' variant='contained' color='secondary' sx={{
                                    fontSize : "!6px",
                                    color : "white",
                                    borderRadius : "6px" ,
                                    "@media (max-width: 480px)": {
                                        fontSize: "12px",
                                        width : "120px"
                                    }
                                }}>{t("BookaDemo")} </Button>
                            </Link>
                        </div>
                    </div>
                    <div className='mul-bottom'>
                        <div className='mulImage'>
                            <img src={Image31} alt=''/>
                        </div>
                        <div className='mul-contnet'>
                            <div className='mul-desc'>
                                <h2 className='mulH2'>{t("Optimizing")} </h2>
                                <p className='mulP'>{t("Ai")}</p>
                            </div>
                            <div className='mul-desc'>
                                <h2 className='mulH2'> {t("Combining")}</h2>
                                <p className='mulP'>{t("Seamlessly")}</p>
                            </div>
                            <div className='mul-desc'>
                                <h2 className='mulH2'> {t("MachineLearning")}</h2>
                                <p className='mulP'>{t("routing")}</p>
                            </div>
                        </div>
                    </div>
                </div>

                
                <div className='mul-eachOne'>
                    <div className='mul-top'>
                        <div className='mul-title'>{t("ScheduleAndTrack")}</div>
                        <div className='mul-btn'>
                        <Link to={"/demo"} >
                                <Button className='mv-btn' variant='contained' color='secondary' sx={{
                                    fontSize : "!6px",
                                    color : "white",
                                    borderRadius : "6px" ,
                                    "@media (max-width: 480px)": {
                                        fontSize: "12px",
                                        width : "120px"
                                    }
                                }}>{t("BookaDemo")} </Button>
                            </Link>
                        </div>
                    </div>
                    <div className='mul-bottom'>
                        <div className='mulImage'>
                            <img src={Image32} alt=''/>
                        </div>
                        <div className='mul-contnet'>
                            <div className='mul-desc'>
                                <h2 className='mulH2'> {t("SchedulePrompts")}</h2>
                                <p className='mulP'>{t("reaches")}</p>
                            </div>
                            <div className='mul-desc'>
                                <h2 className='mulH2'> {t("AutomatedComms")}</h2>
                                <p className='mulP'>{t("DayOf")}</p>
                            </div>
                            <div className='mul-desc'>
                                <h2 className='mulH2'>{t("RatingDrivers")} </h2>
                                <p className='mulP'>{t("Allow")}</p>
                            </div>
                        </div>
                    </div>
                </div>

                
                <div className='mul-eachOne'>
                    <div className='mul-top'>
                        <div className='mul-title'>{t("Monitoring")}</div>
                        <div className='mul-btn'>
                        <Link to={"/demo"} >
                                <Button className='mv-btn' variant='contained' color='secondary' sx={{
                                    fontSize : "!6px",
                                    color : "white",
                                    borderRadius : "6px" ,
                                    "@media (max-width: 480px)": {
                                        fontSize: "12px",
                                        width : "120px"
                                    }
                                }}>{t("BookaDemo")} </Button>
                            </Link>
                        </div>
                    </div>
                    <div className='mul-bottom'>
                        <div className='mulImage'>
                            <img src={Image33} alt=''/>
                        </div>
                        <div className='mul-contnet'>
                            <div className='mul-desc'>
                                <h2 className='mulH2'>{t("Dashboardd")} </h2>
                                <p className='mulP'>{t("ViewEvery")}</p>
                            </div>
                            <div className='mul-desc'>
                                <h2 className='mulH2'>{t("KnowMore")} </h2>
                                <p className='mulP'>{t("Predict")}</p>
                            </div>
                            <div className='mul-desc'>
                                <h2 className='mulH2'> {t("VisualData")}</h2>
                                <p className='mulP'>{t("DataSummarized")}</p>
                            </div>
                            <div className='mul-desc'>
                                <h2 className='mulH2'>{t("MediaFormats")}</h2>
                                <p className='mulP'>{t("AllTrip")}</p>
                            </div>
                        </div>
                    </div>
                </div>

                
                <div className='mul-eachOne'>
                    <div className='mul-top'>
                        <div className='mul-title'>{t("Documentation")}</div>
                        <div className='mul-btn'>
                        <Link to={"/demo"} >
                                <Button className='mv-btn' variant='contained' color='secondary' sx={{
                                    fontSize : "!6px",
                                    color : "white",
                                    borderRadius : "6px" ,
                                    "@media (max-width: 480px)": {
                                        fontSize: "12px",
                                        width : "120px"
                                    }
                                }}>{t("BookaDemo")} </Button>
                            </Link>
                        </div>
                    </div>
                    <div className='mul-bottom'>
                        <div className='mulImage'>
                            <img src={Image34} alt=''/>
                        </div>
                        <div className='mul-contnet'>
                            <div className='mul-desc'>
                                <h2 className='mulH2'>{t("Seemless")} </h2>
                                <p className='mulP'>{t("TheLean")}</p>
                            </div>
                            <div className='mul-desc'>
                                <h2 className='mulH2'> {t("AppAvailability")}</h2>
                                <p className='mulP'>{t("AndriodANdios")}</p>
                            </div>
                            <div className='mul-desc'>
                                <h2 className='mulH2'> {t("TripData")}</h2>
                                <p className='mulP'>{t("DriversCan")}</p>
                            </div>

                        </div>
                    </div>
                </div>

                
                <div className='mul-eachOne'>
                    <div className='mul-top'>
                        <div className='mul-title'>{t("Report")}</div>
                        <div className='mul-btn'>
                        <Link to={"/demo"} >
                                <Button className='mv-btn' variant='contained' color='secondary' sx={{
                                    fontSize : "!6px",
                                    color : "white",
                                    borderRadius : "6px" ,
                                    "@media (max-width: 480px)": {
                                        fontSize: "12px",
                                        width : "120px"
                                    }
                                }}>{t("BookaDemo")} </Button>
                            </Link>
                        </div>
                    </div>
                    <div className='mul-bottom'>
                        <div className='mulImage'>
                            <img src={Image35} alt=''/>
                        </div>
                        <div className='mul-contnet'>
                            <div className='mul-desc'>
                                <h2 className='mulH2'>{t("Centralized")} </h2>
                                <p className='mulP'>{t("AllCentralized")}</p>
                            </div>
                            <div className='mul-desc'>
                                <h2 className='mulH2'> {t("Reporting")}</h2>
                                <p className='mulP'>{t("Fine")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MultibleEmpowers
