import React from 'react'
import "./Empower.scss"
import { useTranslation } from "react-i18next";

const Empower = () => {
    const {t} = useTranslation()

    return (
        <div className='emp-container'>
            <div className='emp-wrapper'>
                <div className='emp-first'>
                    <h1 className='emp-h1'>{t ('Empowering')}<br/> <span className='emp2'> {t("Empowering2")}</span> </h1>
                </div>
                
                <div className='emp-btn'>{t("FromOrder")} </div>

                    <p className='emp-p'><span className='emp3'> {t("DispatchersTracking")}</span> {t("DispatchersTracking1")}<br/> {t("DispatchersTracking2")} </p>
            </div>
        </div>
    )
}

export default Empower
