import React from 'react'
import "./Process.scss"
import { useTranslation } from "react-i18next";
import steps from "../../images/steps.svg"
import stepsA from "../../images/stepsA.svg"
import stepsMobile from "../../images/stepsMobile.svg"
import stepsMobileA from "../../images/stepsMobileA.svg"
import { Stack } from '@mui/material';



const Process = () => {
    const {t} = useTranslation()

return (
    <div>
        <div className='proc-container'> 
            <div className='proc-wrapper'>
                <div className='proc-top'>
                    <div className='proc-topH1'>
                        <h1 className='proc-h'>{t("Elevating")} <br/> {t("Elevating2")}  </h1>
                    </div>
                </div>
                <div className='proc-mid'>
                    <p className="proc-p"> <span className='proc-midspan1'> {t("PromisetoCustomers")} </span> <br/> <span className='proc-midspan2'> {t("PromisetoCustomers2")} </span>{t("PromisetoCustomers3")} <br/> {t("PromisetoCustomers4")}</p>
                </div>
                <Stack mt={{xs : 10 , sm :35}} display={{xs : "none" , sm : "flex"}}>
                {
                localStorage.getItem("lang") === "en" ? <img src={steps} alt="" /> : <img src={stepsA} alt="" />
                }
                </Stack>
                <Stack mt={{xs : 10 , sm :35}} display={{xs : "flex" , sm : "none"}}>
                {
                localStorage.getItem("lang") === "en" ? <img src={stepsMobile} alt="" /> : <img src={stepsMobileA} alt="" />
                }
                </Stack>
            </div>
        </div>
    </div>
)
}

export default Process
