// Imports 
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import { createTheme , ThemeProvider } from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';

// Pages
import Home from "./pages/Home/Home"
import Demo from "./pages/Demo/Demo"
import Media from "./pages/Media/Media"
import Login from "./pages/Login/Login" 
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import TalkToUs from "./components/TalkToUs/TalkToUs"
import Value from "./pages/Value/Value"
import Privacy from "./pages/Privacy/Privacy" 
import { useState } from "react";




function App() {

  const [active, setActive] = useState(false)
  if(!localStorage.getItem("lang")){
    localStorage.setItem("lang","en");
  }
let font = localStorage.getItem("lang") === "en" ? `"Helvetica Neue", "Helvetica", "Arial", sans-serif` : 
` "Cairo", sans-serif` 

// Theming
const theme = createTheme({
  breakpoints : {
    values : {
      xs : 0,
      sm : 768,
      md : 991,
      lg : 1280,
    }
  },
  palette : {
    primary : {
      main : "#564079"
    },
    secondary : {
      main : "#42D3AD"
    },
    text : {
      primary : "#5E5E5E",
      secondary : "#424243",
    },
  },
  spacing : 2,
  typography : {

    "fontFamily": font,
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500,
    "fontWeightBold": 700,
    
    button : {
      textTransform : "none",
      fontSize : "18px",
      fontWeight : "700",
      lineHeight : "22px"
    }
  }
})


  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
          <Router>
            <TalkToUs active2={active} setActive2={setActive}/>
            <Navbar />
            <Routes>
              <Route path="/" element={<Home setActive={setActive}/>} />
              <Route path="/demo" element={<Demo/>} />
              <Route path="/media" element={<Media/>} />
              <Route path="/login" element={<Login/>} /> 
              <Route path="/value" element={<Value />} />
              <Route path="/privacy" element={<Privacy />} /> 
            </Routes>
            <Footer />
          </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
