// images

import c1 from "../../images/clients/1.png";
import c2 from "../../images/clients/2.png";
import c3 from "../../images/clients/3.png";
import c4 from "../../images/clients/4.png";
import c5 from "../../images/clients/5.png";
import c6 from "../../images/clients/6.png";
import c7 from "../../images/clients/7.png";
import c8 from "../../images/clients/8.png";
import c9 from "../../images/clients/9.jpeg";
import c10 from "../../images/clients/10.png";
import c11 from "../../images/clients/11.png";
import c12 from "../../images/clients/12.png";
import c13 from "../../images/clients/13.png";
import c14 from "../../images/clients/14.png";
import c15 from "../../images/clients/15.png";
import c16 from "../../images/clients/16.png";
import c17 from "../../images/clients/17.png";

import c18 from "../../images/clients/18.jpg"
import c19 from "../../images/clients/19.jpg"
import c20 from "../../images/clients/20.jpg"
import c21 from "../../images/clients/21.jpg"
import c22 from "../../images/clients/22.jpg"
import c23 from "../../images/clients/23.jpg"
import c24 from "../../images/clients/24.jpg"
import c25 from "../../images/clients/25.jpg"
import c26 from "../../images/clients/26.jpg"
import c27 from "../../images/clients/27.jpg"
import c28 from "../../images/clients/28.jpg"
import c29 from "../../images/clients/29.jpg"
import c30 from "../../images/clients/30.jpg"
import c31 from "../../images/clients/31.jpg"
import c32 from "../../images/clients/32.png"
import c33 from "../../images/clients/33.png"
import c34 from "../../images/clients/34.png"
import c35 from "../../images/clients/35.png"


import fe1 from "../../images/features/الإدارة.jpg";
import fe2 from "../../images/features/أثناء التنقل.jpg";
import fe3 from "../../images/features/تعدد اللغات.jpg";
import fe4 from "../../images/features/معتمد.jpg";
import fe5 from "../../images/features/بيان.jpg";
import fe6 from "../../images/features/التطبيق.jpg";
import fe7 from "../../images/features/لوحة التحكم.jpg";
import fe8 from "../../images/features/التخصيص.jpg";
import fe9 from "../../images/features/التتبع.jpg";
import fe10 from "../../images/features/المسار.jpg";
import fe11 from "../../images/features/تواصل.jpg";
import fe12 from "../../images/features/الشحنات.jpg";
import fe13 from "../../images/features/المشاركة.jpg";
import fe14 from "../../images/features/التعاون.jpg";
import fe15 from "../../images/features/تحميل مجمع.jpg";
import fe16 from "../../images/features/مختلط.jpg";
import fe17 from "../../images/features/الفوترة.jpg";
import fe18 from "../../images/features/API.png";
import fe19 from "../../images/features/Modular.png";
import fe20 from "../../images/features/Security.png";
import fe21 from "../../images/features/Support.png";
import fe22 from "../../images/features/Go Green.png";


// Imports
import "./Home.scss";
import { Button, Divider, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

// import CountUp from "react-countup";

// import ScrollTrigger from "react-scroll-trigger";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";

// Swiper 
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import { Autoplay } from 'swiper';
import 'swiper/css/pagination';

import { useTranslation } from "react-i18next";

// Components
import Main from "../../components/Main/Main"
import CaseStudies from "../../components/CaseStudies/CaseStudies"
import Currencies from "../../components/Currencies/Currencies"
import Pricing from "../../components/Pricing/Pricing"
import Differences from "../../components/Differences/Differences"
import Newsletter from "../../components/Newsletter/Newsletter"
import Testimonials from "../../components/Testimonials/Testimonials"
import Figurs from '../../components/Figurs/Figurs'
import Approved from '../../components/Approved/Approved'
import Industries from '../../components/Industries/Industries'
import Benefits from '../../components/Benefits/Benefits'
import CrossSell from '../../components/CrossSell/CrossSell'




function Home({setActive}) {
    if(!localStorage.getItem("lang")){
        localStorage.setItem("lang","en");
      }
    useEffect(() => {
        window.scrollTo(0, 0);
    } , [])

    
    
    return (
        <div className="Home">
            <Main />
            <Figurs />
            <Approved />
            <Third />
            <Industries/>
            <Benefits /> 
            <div id="features" style={{height : "120px"}} ></div>
            <Fifth />  
            <Testimonials />
            <CaseStudies />
            <Currencies />
            <div id='pricing' style={{height : "120px"}} ></div>
            <Pricing setActive={setActive} />
            <Differences />
            <CrossSell setActive={setActive} />
            <Ten />
            <Newsletter />
        </div>
    );
}

function Third() {

    const {t} = useTranslation()
    
    return (
        <div className="Third">
            <Typography
                color="white"
                sx={{
                    fontSize: {xs : "20px" , sm : "28px"},
                    fontWeight: "700",
                    bgcolor : "primary.main",
                    width : "100%",
                    textAlign: "center",
                    py : {xs : 8 , sm : 14}
                }}
                data-aos="fade-up"
            >
                {t("ourPartners")}
            </Typography>
            <div className="">
                <Stack>
                        <div>
                            <Swiper 
                                slidesPerView={5}
                                spaceBetween= {0}
                                modules={[Autoplay]}
                                autoplay={{
                                    delay: 0,
                                    disableOnInteraction: false,
                                }}
                                speed={5000}
                                loop={true}
                            >
                                <SwiperSlide><img src={c1} alt="" /></SwiperSlide>
                                <SwiperSlide><img src={c2} alt="" /></SwiperSlide>
                                <SwiperSlide><img src={c3} alt="" /></SwiperSlide>
                                <SwiperSlide><img src={c4} alt="" /></SwiperSlide>
                                <SwiperSlide><img src={c5} alt="" /></SwiperSlide>
                                <SwiperSlide><img src={c6} alt="" /></SwiperSlide>
                                <SwiperSlide><img src={c7} alt="" /></SwiperSlide>
                                <SwiperSlide><img src={c8} alt="" /></SwiperSlide>
                                <SwiperSlide><img src={c9} alt="" /></SwiperSlide>
                                <SwiperSlide><img src={c10} alt="" /></SwiperSlide>
                                <SwiperSlide><img src={c11} alt="" /></SwiperSlide>
                                <SwiperSlide><img src={c12} alt="" /></SwiperSlide>
                                <SwiperSlide><img src={c13} alt="" /></SwiperSlide>
                                <SwiperSlide><img src={c14} alt="" /></SwiperSlide>
                                <SwiperSlide><img src={c15} alt="" /></SwiperSlide>
                                <SwiperSlide><img src={c16} alt="" /></SwiperSlide>
                                <SwiperSlide><img src={c17} alt="" /></SwiperSlide>
                                
                                <SwiperSlide><img src={c18} alt="" /></SwiperSlide>
                                <SwiperSlide><img src={c19} alt="" /></SwiperSlide>
                                <SwiperSlide><img src={c20} alt="" /></SwiperSlide>
                                <SwiperSlide><img src={c21} alt="" /></SwiperSlide>
                                <SwiperSlide><img src={c22} alt="" /></SwiperSlide>
                                <SwiperSlide><img src={c23} alt="" /></SwiperSlide>
                                <SwiperSlide><img src={c24} alt="" /></SwiperSlide>
                                <SwiperSlide><img src={c25} alt="" /></SwiperSlide>
                                <SwiperSlide><img src={c26} alt="" /></SwiperSlide>
                                <SwiperSlide><img src={c27} alt="" /></SwiperSlide>
                                <SwiperSlide><img src={c28} alt="" /></SwiperSlide>
                                <SwiperSlide><img src={c29} alt="" /></SwiperSlide>
                                <SwiperSlide><img src={c30} alt="" /></SwiperSlide>
                                <SwiperSlide><img src={c31} alt="" /></SwiperSlide>
                                <SwiperSlide><img src={c32} alt="" /></SwiperSlide>
                                <SwiperSlide><img src={c33} alt="" /></SwiperSlide>
                                <SwiperSlide><img src={c34} alt="" /></SwiperSlide>
                                <SwiperSlide><img src={c35} alt="" /></SwiperSlide>
                                
                            </Swiper>
                        </div>
                </Stack>
            </div>
        </div>
    );
}

function Fifth() {

    const [current, setcurrent] = useState(0)

    const features = [
        {
            head : "Effortlessly manage all aspects of your operation.",
            image : fe1,
        },
        {
            head : "Accessible from everywhere, on any device.",
            image : fe2,
        },
        {
            head : "Multilingual is the way to go. Supporting Arabic, English, Urdu & Hindi.",
            image : fe3,
        },
        {
            head : "Compliant with Naql & Transport General Authority.",
            image : fe4,
        },
        {
            head : "Issue Bayan Naql waybill in 15 Seconds!",
            image : fe5,
        },
        {
            head : "A dedicated driver app for Android & iOS.",
            image : fe6,
        },
        {
            head : "Realtime visibility of your fleet and operations.",
            image : fe7,
        },
        {
            head : "A wide selection of customization capabilities to fit your needs.",
            image : fe8,
        },
        {
            head : "Keep an eye on your fleet with our Eagle Eye Live tacking in realtime.",
            image : fe9,
        },
        {
            head : "Route optimization using google maps.",
            image : fe10,
        },
        {
            head : "2 way communication with Text, Voice & Video.",
            image : fe11,
        },
        {
            head : "Shipment tracking and proof of pickup & delivery.",
            image : fe12,
        },
        {
            head : "Instantly share documents via SMS, WhatsApp or a Link.",
            image : fe13,
        },
        {
            head : "Easily collaborate with your team.",
            image : fe14,
        },
        {
            head : "Bulk driver & vehicle information upload.",
            image : fe15,
        },
        {
            head : "Hybrid scheduling and management capability.",
            image : fe16,
        },
        {
            head : "Automate your billing and settlement processes.",
            image : fe17,
        },
        {
            head : "API integration capabilities with ERPs.",
            image : fe18,
        },
        {
            head : "Modularly built for your comfort.",
            image : fe19,
        },
        {
            head : "A secure software for your ease of mind.",
            image : fe20,
        },
        {
            head : "Free after-sales tech support.",
            image : fe21,
        },
        {
            head : "We work hard to support Saudi Arabia’s green initiatives.",
            image : fe22,
        },
    ]

    const featuresA = [
        {
            head : "إدارة جميع جوانب عملياتك دون عناء.",
            image : fe1,
        },
        {
            head : "یمكن الوصول إلیه من أي مكان وعلى أي جھاز.",
            image : fe2,
        },
        {
            head : "تعدد اللغات ھو الطریق الأنسب لفریقك، ندعم اللغة العربیة، الانجلیزیة، الاردو والھندي.",
            image : fe3,
        },
        {
            head : "متوافق مع الھیئة العامة للنقل وتوثیق الحمولة نقل.",
            image : fe4,
        },
        {
            head : "اصدر وثیقة بیان نقل الحمولة في 15 ثانیة!",
            image : fe5,
        },
        {
            head : "تطبیق مخصص للسائقین على اندرویر وiOS.",
            image : fe6,
        },
        {
            head : "رؤية واضحة لاسطولك و عملیاتك في الوقت الفعلي.",
            image : fe7,
        },
        {
            head : "مجموعة واسعة من إمكانیات التخصیص لتناسب احتیاجاتك.",
            image : fe8,
        },
        {
            head : "راقب أسطولك من خلال عین النسر للتتبع المباشر في الوقت الحقیقي.",
            image : fe9,
        },
        {
            head : "قابلية تحسین المسار عن طریق خرائط جوجل.",
            image : fe10,
        },
        {
            head : "اتصال ثنائي الاتجاه عن طریق الرسائل النصیة، مكالمات صوتیة ومكالمات فیدیو.",
            image : fe11,
        },
        {
            head : "تتبع الشحنة وإثبات الاستلام و التسلیم.",
            image : fe12,
        },
        {
            head : "مشاركة المستندات على الفور عن طریق الرسائل النصیة، الواتساب، او رابط.",
            image : fe13,
        },
        {
            head : "تعاون مع فریقك بسھولة.",
            image : fe14,
        },
        {
            head : "قابلية تحمیل المعلومات مجمعة للسائقین والمركبات.",
            image : fe15,
        },
        {
            head : "قابلية الجدولة والإدارة المختلطة.",
            image : fe16,
        },
        {
            head : "اتمتة عمليات الفوترة والتسویة التلقائیة.",
            image : fe17,
        },
        {
            head : "دمج واجھة برمجة التطبیقات مع أنظمة تخطیط موارد المؤسسات.",
            image : fe18,
        },
        {
            head : "تم تصميمه بشكل نمطي لراحتك",
            image : fe19,
        },
        {
            head : "برنامج آمن لراحة بالك",
            image : fe20,
        },
        {
            head : "دعم فني مجاني بعد البيع",
            image : fe21,
        },
        {
            head : "نعمل بجهد لمساندة مبادرات السعودیة للبیئة.",
            image : fe22,
        },
    ]

    const handleClick = (e) => {
        const liss = document.querySelectorAll(".list li")
        liss.forEach((li) => {
            li.classList.remove("active")
        })
        e.target.classList.add("active")
        setcurrent(e.target.dataset.num)
    }


    if(localStorage.getItem("lang") === "en") { 
        return (
            <div className="Fifth">
    
                <div className="container">
                    <Typography
                        color="white"
                        sx={{
                            fontWeight: "700",
                            fontSize: {xs : "30px" , sm : "36px"},
                            lineHeight: "30px",
                            textAlign: "center",
                        }}
                    >
                        Key Features
                    </Typography>
                    <Typography
                        color="secondary"
                        sx={{
                            fontWeight: "400",
                            fontSize: {xs : "16px" , sm : "24px"},
                            lineHeight: "30px",
                            textAlign: "center",
                            mb: 20,
                        }}
                    >
                        To elevate your business to new heights
                    </Typography>
                    <div className="box">
                        {/* desktop */}
                        <Stack
                            width="100%"
                            display={{xs : "none" , md : "flex"}}
                            direction={{xs : "row" , md : "row"}}
                            divider={
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    sx={{ bgcolor: "secondary.main" }}
                                />
                            }
                            alignItems="center"
                            justifyContent="space-between"
                            py={6}
                            px={8}
                            border="1px solid"
                            borderColor="text.primary"
                            borderRadius="16px"
                            mb={4}
                            className="list"
                            spacing={4}
                        >
                            <li data-num="0" onClick={(e) => handleClick(e) } className="active" >Management</li>
                            <li data-num="1" onClick={(e) => handleClick(e) } >On The Go</li>
                            <li data-num="2" onClick={(e) => handleClick(e) }>Multilingual</li>
                            <li data-num="3" onClick={(e) => handleClick(e) }>Compliance</li>
                            <li data-num="4" onClick={(e) => handleClick(e) }>Bayan </li>
                            <li data-num="5" onClick={(e) => handleClick(e) }>App</li>
                            <li data-num="6" onClick={(e) => handleClick(e) }>Dashboard</li>
                            <li data-num="7" onClick={(e) => handleClick(e) }>Customization</li>
                            <li data-num="8" onClick={(e) => handleClick(e) }>Tracking</li>
                            <li data-num="9" onClick={(e) => handleClick(e) }>Routing</li>
                            <li data-num="10" onClick={(e) => handleClick(e) }>Comms</li>
                        </Stack>
                        <Stack
                            width="100%"
                            display={{xs : "none" , md : "flex"}}
                            direction={{xs : "row" , md : "row"}}
                            divider={
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    sx={{ bgcolor: "secondary.main" }}
                                />
                            }
                            alignItems="center"
                            justifyContent="space-between"
                            py={6}
                            px={8}
                            border="1px solid"
                            borderColor="text.primary"
                            borderRadius="16px"
                            mb={24}
                            className="list"
                            spacing={4}
                        >
                            <li data-num="11" onClick={(e) => handleClick(e) }>Statuses</li>
                            <li data-num="12" onClick={(e) => handleClick(e) }>Sharing</li>
                            <li data-num="13" onClick={(e) => handleClick(e) }>Collaboration</li>
                            <li data-num="14" onClick={(e) => handleClick(e) }>Bulk Upload</li>
                            <li data-num="15" onClick={(e) => handleClick(e) }>Hybrid</li>
                            <li data-num="16" onClick={(e) => handleClick(e) }>Billing</li>
                            <li data-num="17" onClick={(e) => handleClick(e) }>API</li>
                            <li data-num="18" onClick={(e) => handleClick(e) }>Modular</li>
                            <li data-num="19" onClick={(e) => handleClick(e) }>Security</li>
                            <li data-num="20" onClick={(e) => handleClick(e) }>Support</li>
                            <li data-num="21" onClick={(e) => handleClick(e) }>Go Green</li>
                        </Stack>
                        {/* mobile */}
                        <Stack
                            display={{xs : "flex" , md : "none"}}
                            direction={{xs : "row" , md : "row"}}
                            width="100%"

                            divider={
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    sx={{ bgcolor: "secondary.main" }}
                                />
                            }
                            alignItems="center"
                            justifyContent="space-between"
                            py={6}
                            px={8}
                            border="1px solid"
                            borderColor="text.primary"
                            borderRadius="16px"
                            mb={10}
                            className="list"
                        >
                            <li data-num="0" onClick={(e) => handleClick(e) } className="active" >Management</li>
                            <li data-num="1" onClick={(e) => handleClick(e) } >On The Go</li>
                            <li data-num="2" onClick={(e) => handleClick(e) }>Multilingual</li>
                            <li data-num="3" onClick={(e) => handleClick(e) }>Compliance</li>

                        </Stack>
                        <Stack
                            width="100%"
                            direction={{xs : "row" , md : "row"}}
                            display={{xs : "flex" , md : "none"}}
                            divider={
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    sx={{ bgcolor: "secondary.main" }}
                                />
                            }
                            alignItems="center"
                            justifyContent="space-between"
                            py={6}
                            px={8}
                            border="1px solid"
                            borderColor="text.primary"
                            borderRadius="16px"
                            mb={10}
                            className="list"
                        >
                            <li data-num="4" onClick={(e) => handleClick(e) }>Bayan </li>
                            <li data-num="6" onClick={(e) => handleClick(e) }>Dashboard</li>
                            <li data-num="7" onClick={(e) => handleClick(e) }>Customization</li>
                            <li data-num="8" onClick={(e) => handleClick(e) }>Tracking</li>
                            <li data-num="9" onClick={(e) => handleClick(e) }>Routing</li>
                            <li data-num="10" onClick={(e) => handleClick(e) }>Comms</li>
                        </Stack>
                        <Stack
                            direction={{xs : "row" , md : "row"}}
                            display={{xs : "flex" , md : "none"}}
                            width="100%"

                            divider={
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    sx={{ bgcolor: "secondary.main" }}
                                />
                            }
                            alignItems="center"
                            justifyContent="space-between"
                            py={6}
                            px={8}
                            border="1px solid"
                            borderColor="text.primary"
                            borderRadius="16px"
                            mb={10}
                            className="list"
                        >
                            <li data-num="11" onClick={(e) => handleClick(e) }>Statuses</li>
                            <li data-num="12" onClick={(e) => handleClick(e) }>Sharing</li>
                            <li data-num="13" onClick={(e) => handleClick(e) }>Collaboration</li>
                            <li data-num="14" onClick={(e) => handleClick(e) }>Bulk Upload</li>
                            <li data-num="15" onClick={(e) => handleClick(e) }>Hybrid</li>
                        </Stack>
                        <Stack
                            direction={{xs : "row" , md : "row"}}
                            display={{xs : "flex" , md : "none"}}
                            width="100%"
                            divider={
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    sx={{ bgcolor: "secondary.main" }}
                                />
                            }
                            alignItems="center"
                            justifyContent="space-between"
                            py={6}
                            px={8}
                            border="1px solid"
                            borderColor="text.primary"
                            borderRadius="16px"
                            mb={24}
                            className="list"
                        >
                            <li data-num="16" onClick={(e) => handleClick(e) }>Billing</li>
                            <li data-num="17" onClick={(e) => handleClick(e) }>API</li>
                            <li data-num="18" onClick={(e) => handleClick(e) }>Modular</li>
                            <li data-num="19" onClick={(e) => handleClick(e) }>Security</li>
                            <li data-num="20" onClick={(e) => handleClick(e) }>Support</li>
                            <li data-num="21" onClick={(e) => handleClick(e) }>Go Green</li>
                        </Stack>
    
                        <Stack
                            // spacing={50}
                            justifyContent="space-around"
                            direction={{xs : "column" , md : "row"}}
                            spacing={{xs : 20 , md : 0}}
                            width={"100%"}
                        >
                            <Stack alignItems={{xm : "center" , sm : "start" }} >
                                <Typography
                                    color={"primary"}
                                    sx={{
                                        fontSize: "33px",
                                        fontWeight: "700",
                                        lineHeight: "40px",
                                        maxWidth : {xs : "100%" , sm : "240px"},
                                        textAlign : {xs : "center" , sm : "start"},
                                        mb: {xs : 10 , md : 20},
                                    }}
                                >
                                    {features[current].head}
                                </Typography>
                                <Link to="/demo" >
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        sx={{
                                            py: 8,
                                            px: 35,
                                            width: "fit-Content",
                                            borderRadius: "16px",
                                            color : "white",
                                            display : {xs : "block" , sm : "inline-flex"},
                                            margin : {xs : "auto" , sm : "0"},
                                        }}
                                    >
                                        Start now
                                    </Button>
                                </Link>
                            </Stack>
    
                            <div className="image">
                                <img loading="lazy" src={features[current].image} alt="" />
                            </div>
                        </Stack>
    
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="Fifth" id="features">
    
                <div className="container">
                    <Typography
                        color="white"
                        sx={{
                            fontWeight: "700",
                            fontSize: {xs : "40px" , sm : "48px"},
                            lineHeight: "60px",
                            textAlign: "center",
                        }}
                    >
                        الميزات الأساسية
                    </Typography>
                    <Typography
                        color="secondary"
                        sx={{
                            fontWeight: "700",
                            fontSize: {xs : "20px" , sm : "32px"},
                            lineHeight: "30px",
                            textAlign: "center",
                            mb: 20,
                        }}
                    >
                        لتطویر أعملالك واللارتقاء بھا نحو آفاق جدیدة
                    </Typography>
    
                    <div className="box">
                        {/* desktop */}
                        <Stack
                            width="100%"
                            display={{xs : "none" , md : "flex"}}
                            direction={{xs : "row" , md : "row"}}
                            divider={
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    sx={{ bgcolor: "secondary.main" }}
                                />
                            }
                            alignItems="center"
                            justifyContent="space-between"
                            py={6}
                            px={8}
                            border="1px solid"
                            borderColor="text.primary"
                            borderRadius="16px"
                            mb={4}
                            className="list"
                            spacing={4}
                        >
                            <li data-num="0" onClick={(e) => handleClick(e) } className="active" >الإدارة</li>
                            <li data-num="1" onClick={(e) => handleClick(e) } >أثناء التنقل</li>
                            <li data-num="2" onClick={(e) => handleClick(e) }>تعدد اللغات</li>
                            <li data-num="3" onClick={(e) => handleClick(e) }>معتمد</li>
                            <li data-num="4" onClick={(e) => handleClick(e) }>بيان</li>
                            <li data-num="5" onClick={(e) => handleClick(e) }>التطبيق</li>
                            <li data-num="6" onClick={(e) => handleClick(e) }>لوحة التحكم</li>
                            <li data-num="7" onClick={(e) => handleClick(e) }>التخصيص</li>
                            <li data-num="8" onClick={(e) => handleClick(e) }>التتبع المباشر</li>
                            <li data-num="9" onClick={(e) => handleClick(e) }>المسار</li>
                            <li data-num="10" onClick={(e) => handleClick(e) }>التواصل</li>
                        </Stack>
                        <Stack
                            width="100%"
                            display={{xs : "none" , md : "flex"}}
                            direction={{xs : "row" , md : "row"}}
                            divider={
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    sx={{ bgcolor: "secondary.main" }}
                                />
                            }
                            alignItems="center"
                            justifyContent="space-between"
                            py={6}
                            px={8}
                            border="1px solid"
                            borderColor="text.primary"
                            borderRadius="16px"
                            mb={24}
                            className="list"
                            spacing={4}
                        >
                            <li data-num="11" onClick={(e) => handleClick(e) }>الحالات</li>
                            <li data-num="12" onClick={(e) => handleClick(e) }>المشاركة</li>
                            <li data-num="13" onClick={(e) => handleClick(e) }>التعاون</li>
                            <li data-num="14" onClick={(e) => handleClick(e) }>تحميل مجمع</li>
                            <li data-num="15" onClick={(e) => handleClick(e) }> مختلط</li>
                            <li data-num="16" onClick={(e) => handleClick(e) }>الفوترة</li>
                            <li data-num="17" onClick={(e) => handleClick(e) }>واجهة برمجة التطبيقات</li>
                            <li data-num="18" onClick={(e) => handleClick(e) }>نموذجي</li>
                            <li data-num="19" onClick={(e) => handleClick(e) }>الأمان</li>
                            <li data-num="20" onClick={(e) => handleClick(e) }>الدعم</li>
                            <li data-num="21" onClick={(e) => handleClick(e) }>كن صديقا للبيئة</li>
                        </Stack>
                        {/* mobile */}
                        <Stack
                            display={{xs : "flex" , md : "none"}}
                            direction={{xs : "row" , md : "row"}}
                            width="100%"

                            divider={
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    sx={{ bgcolor: "secondary.main" }}
                                />
                            }
                            alignItems="center"
                            justifyContent="space-between"
                            py={6}
                            px={8}
                            border="1px solid"
                            borderColor="text.primary"
                            borderRadius="16px"
                            mb={10}
                            className="list"
                        >
                            <li data-num="0" onClick={(e) => handleClick(e) } className="active" >الإدارة</li>
                            <li data-num="1" onClick={(e) => handleClick(e) } >أثناء التنقل</li>
                            <li data-num="2" onClick={(e) => handleClick(e) }>تعدد اللغات</li>
                            <li data-num="3" onClick={(e) => handleClick(e) }>معتمد</li>
                            <li data-num="4" onClick={(e) => handleClick(e) }>بيان</li>

                        </Stack>
                        <Stack
                            width="100%"
                            direction={{xs : "row" , md : "row"}}
                            display={{xs : "flex" , md : "none"}}
                            divider={
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    sx={{ bgcolor: "secondary.main" }}
                                />
                            }
                            alignItems="center"
                            justifyContent="space-between"
                            py={6}
                            px={8}
                            border="1px solid"
                            borderColor="text.primary"
                            borderRadius="16px"
                            mb={10}
                            className="list"
                        >
                            <li data-num="5" onClick={(e) => handleClick(e) }>التطبيق</li>
                            <li data-num="6" onClick={(e) => handleClick(e) }>لوحة التحكم</li>
                            <li data-num="7" onClick={(e) => handleClick(e) }>التخصيص</li>
                            <li data-num="8" onClick={(e) => handleClick(e) }>التتبع المباشر</li>
                        </Stack>
                        <Stack
                            direction={{xs : "row" , md : "row"}}
                            display={{xs : "flex" , md : "none"}}
                            width="100%"

                            divider={
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    sx={{ bgcolor: "secondary.main" }}
                                />
                            }
                            alignItems="center"
                            justifyContent="space-between"
                            py={6}
                            px={8}
                            border="1px solid"
                            borderColor="text.primary"
                            borderRadius="16px"
                            mb={10}
                            className="list"
                        >   
                            <li data-num="9" onClick={(e) => handleClick(e) }>المسار</li>
                            <li data-num="10" onClick={(e) => handleClick(e) }>التواصل</li>
                            <li data-num="11" onClick={(e) => handleClick(e) }>الحالات</li>
                            <li data-num="12" onClick={(e) => handleClick(e) }>المشاركة</li>
                            <li data-num="13" onClick={(e) => handleClick(e) }>التعاون</li>
                        </Stack>
                        <Stack
                            direction={{xs : "row" , md : "row"}}
                            display={{xs : "flex" , md : "none"}}
                            width="100%"
                            divider={
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    sx={{ bgcolor: "secondary.main" }}
                                />
                            }
                            alignItems="center"
                            justifyContent="space-between"
                            py={6}
                            px={8}
                            border="1px solid"
                            borderColor="text.primary"
                            borderRadius="16px"
                            mb={10}
                            className="list"
                        >   
                            <li data-num="14" onClick={(e) => handleClick(e) }>تحميل مجمع</li>
                            <li data-num="15" onClick={(e) => handleClick(e) }> مختلط</li>
                            <li data-num="16" onClick={(e) => handleClick(e) }>الفوترة</li>
                            <li data-num="17" onClick={(e) => handleClick(e) }>واجهة برمجة التطبيقات</li>
                        </Stack>
                        <Stack
                            direction={{xs : "row" , md : "row"}}
                            display={{xs : "flex" , md : "none"}}
                            width="100%"
                            divider={
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    sx={{ bgcolor: "secondary.main" }}
                                />
                            }
                            alignItems="center"
                            justifyContent="space-between"
                            py={6}
                            px={8}
                            border="1px solid"
                            borderColor="text.primary"
                            borderRadius="16px"
                            mb={24}
                            className="list"
                        >
                            <li data-num="18" onClick={(e) => handleClick(e) }>نموذجي</li>
                            <li data-num="19" onClick={(e) => handleClick(e) }>الأمان</li>
                            <li data-num="20" onClick={(e) => handleClick(e) }>الدعم</li>
                            <li data-num="21" onClick={(e) => handleClick(e) }>كن صديقا للبيئة</li>
                        </Stack>
    
                        <Stack
                            // spacing={50}
                            justifyContent="space-around"
                            direction={{xs : "column" , md : "row"}}
                            spacing={{xs : 20 , md : 0}}
                            width={"100%"}
                        >
                            <Stack alignItems={{xm : "center" , sm : "start" }} >
                                <Typography
                                    sx={{
                                        fontSize: "32px",
                                        fontWeight: "700",
                                        lineHeight: "48px",
                                        maxWidth : {xs : "100%" , sm : "240px"},
                                        textAlign : {xs : "center" , sm : "start"},
                                        mb: {xs : 10 , md : 20},
                                    }}
                                >
                                    {featuresA[current].head}
                                </Typography>
                                <Link to="/demo" >
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        sx={{
                                            py: 8,
                                            px: 35,
                                            width: "fit-Content",
                                            borderRadius: "16px",
                                            color : "white",
                                            display : {xs : "block" , sm : "inline-flex"},
                                            margin : {xs : "auto" , sm : "0"},
                                        }}
                                    >
                                        ابدأ الان
                                    </Button>
                                </Link>
                            </Stack>
    
                            <div className="image">
                                <img src={features[current].image} alt="" />
                            </div>
                        </Stack>
    
                    </div>
                </div>
            </div>
        );
    }
    
    
}

function Ten() {

    if(localStorage.getItem("lang") === "en") {
        return (
            <div className="Ten">
                <div className="container">
                    
                    <Accordion
                        sx={{
                            borderTop: "none",
                            borderBottom: "none",
                            boxShadow: "none",
                            borderRadius: "0",
                            borderColor: "#BCBEC0",
                            py: 5,
                        }}
                    >
                        <AccordionSummary
                            expandIcon={
                                <ExpandMoreIcon
                                    color="secondary"
                                    fontSize="large"
                                    sx={{
                                        "&:hover": {
                                            color: "primary.main",
                                        },
                                    }}
                                />
                            }
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography
                                sx={{
                                    fontWeight: "700",
                                    fontSize: "40px",
                                    lineHeight: "64px",
                                    color: "#5E5E5E",
                                    textAlign: "center",
                                    mb: 20,
                                    width : "100%"
                                }}
                            >
                                FAQ
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                                <hr />
                            <Accordion
                                sx={{
                                    borderTop: "none",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    borderRadius: "0",
                                    borderColor: "#BCBEC0",
                                    py: 5,
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            color="secondary"
                                            fontSize="large"
                                            sx={{
                                                "&:hover": {
                                                    color: "primary.main",
                                                },
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "400",
                                            fontSize: "20px",
                                        }}
                                    >
                                        What is SaaS?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        SaaS mean software as a service which is a cloud
                                        based software that allows users to easily access it
                                        from anywhere.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderTop: "none",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    borderRadius: "0",
                                    py: 5,
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            color="secondary"
                                            fontSize="large"
                                            sx={{
                                                "&:hover": {
                                                    color: "primary.main",
                                                },
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "400",
                                            fontSize: "20px",
                                        }}
                                    >
                                        What is a fleet performance
                                        management software?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    A software used to easily manage & automate
                                    your transport & fleet operations, orders, trips,
                                    vehicles and resources.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderTop: "none",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    borderRadius: "0",
                                    borderColor: "#BCBEC0",
                                    py: 5,
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            color="secondary"
                                            fontSize="large"
                                            sx={{
                                                "&:hover": {
                                                    color: "primary.main",
                                                },
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "400",
                                            fontSize: "20px",
                                        }}
                                    >
                                        What is an online fleet performance
                                        mannagement software?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    Is a cloud based software that allows you to do
all operations and store documents on the cloud
without the need to have them on your devices.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderTop: "none",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    borderRadius: "0",
                                    borderColor: "#BCBEC0",
                                    py: 5,
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            color="secondary"
                                            fontSize="large"
                                            sx={{
                                                "&:hover": {
                                                    color: "primary.main",
                                                },
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "400",
                                            fontSize: "20px",
                                        }}
                                    >
                                        How can I create an order?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        To create an <strong>order</strong> on Lean Dispatcher all you
                                        need to do is: <br />
                                        <li> <strong>Login</strong> to your account.</li>
                                        <li> Go to the <strong>Orders</strong> page from main menu.</li>
                                        <li> Click the <strong>Create Order</strong> Button to add a single order.</li>
                                        <li> Fill in the needed information.</li>
                                        <li>Once <strong>orders</strong> are created they are then listed in the <strong>trips</strong> page.</li>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderTop: "none",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    borderRadius: "0",
                                    borderColor: "#BCBEC0",
                                    py: 5,
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            color="secondary"
                                            fontSize="large"
                                            sx={{
                                                "&:hover": {
                                                    color: "primary.main",
                                                },
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "400",
                                            fontSize: "20px",
                                        }}
                                    >
                                        How can I create bulk orders at once?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        To create <strong>bulk orders</strong> on Lean Dispatcher all you
                                        need to do is: <br /> 
                                        <li> <strong>Login</strong> to your account. </li>
                                        <li> Go to the <strong>Orders</strong> page from main menu.</li>
                                        <li>To add <strong>bulk orders</strong>, click the <strong>Download Excel</strong> button, once downloaded you can add info accordingly.</li>
                                        <li> Then click on the <strong>Upload Excel</strong> button to upload your <strong>bulk orders</strong> to the system.</li>
                                        <li> Once <strong>orders</strong> are created they are then listed in the <strong>trips</strong> page.</li>
                                        
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderTop: "none",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    borderRadius: "0",
                                    borderColor: "#BCBEC0",
                                    py: 5,
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            color="secondary"
                                            fontSize="large"
                                            sx={{
                                                "&:hover": {
                                                    color: "primary.main",
                                                },
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "400",
                                            fontSize: "20px",
                                        }}
                                    >
                                        How can I assign trips?{" "}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    <strong>Trips</strong> are automatically added to the trip page
                                    once <strong>orders</strong> have been created.
                                    <br />
                                    They are then <strong>pending activation</strong> and needs to
                                    <strong> assign a driver</strong>.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderTop: "none",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    borderRadius: "0",
                                    borderColor: "#BCBEC0",
                                    py: 5,
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            color="secondary"
                                            fontSize="large"
                                            sx={{
                                                "&:hover": {
                                                    color: "primary.main",
                                                },
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "400",
                                            fontSize: "20px",
                                        }}
                                    >
                                        How can I assign a driver to a trip?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        To assign trips on Lean Dispatcher all you need
                                        to do is: <br />
                                        <li> <strong>Login</strong> to your account.</li>
                                        <li> Go to the <strong>trips</strong> from the main menu.</li>
                                        <li>Locate the newly added trip, then click on <strong>Assign Driver</strong> button.</li>
                                        <li>Select the <strong>driver</strong>, the <strong>vehicle</strong>, the <strong>plates</strong> and then click the <strong>Save</strong> button to assign and activate your trip.</li>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderTop: "none",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    borderRadius: "0",
                                    borderColor: "#BCBEC0",
                                    py: 5,
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            color="secondary"
                                            fontSize="large"
                                            sx={{
                                                "&:hover": {
                                                    color: "primary.main",
                                                },
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "400",
                                            fontSize: "20px",
                                        }}
                                    >
                                        How can I have my client directly add orders to Lean Dispatcher?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    To have your <strong>client add orders</strong> in Lean Dispatcher all you need to do is:
                                    <li><strong>Login</strong> to your account.</li>
                                    <li>Once you <strong>create a user</strong> for your client he will be able to create orders.</li>
                                    <li>He can then <strong>Login to his account</strong> and go to the <strong>Orders</strong> page from main menu.</li>
                                    <li>Click the <strong>Create Order</strong> Button to add a single order.</li>
                                    <li>Fill in the needed information.</li>
                                    <li>Or to add <strong>bulk orders</strong>, click the <strong>Download Excel</strong> button, once downloaded you can add info accordingly then press on the <strong>Upload Excel </strong> button to add it to the system.</li>
                                    <li>Once <strong>orders</strong> are created they are then listed in the<strong> External Orders</strong> page for you to review and approve.</li>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderTop: "none",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    borderRadius: "0",
                                    borderColor: "#BCBEC0",
                                    py: 5,
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            color="secondary"
                                            fontSize="large"
                                            sx={{
                                                "&:hover": {
                                                    color: "primary.main",
                                                },
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "400",
                                            fontSize: "20px",
                                        }}
                                    >
                                        How can I track my fleet?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    You can easily track your active fleet from the Eagle Eye page and selecting the desired trip to review all info.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderTop: "none",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    borderRadius: "0",
                                    borderColor: "#BCBEC0",
                                    py: 5,
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            color="secondary"
                                            fontSize="large"
                                            sx={{
                                                "&:hover": {
                                                    color: "primary.main",
                                                },
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "400",
                                            fontSize: "20px",
                                        }}
                                    >
                                        How can I add a new client?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    To add new <strong>clients</strong> on Lean Dispatcher all you need to do is:
                                        <li><strong>Login</strong> to your account.</li>
                                        <li>Go to the <strong>Settings</strong> section from main menu.</li>
                                        <li>Go to the <strong>Contact</strong> page.</li>
                                        <li>Then click on the <strong>Add Contact</strong> button.</li>
                                        <li>Fill in the required info and click <strong>Create</strong>.</li>
                                        <li>Or you can <strong>create a new contact</strong> when creating a <strong>new order</strong> and filling in the source and pickup information.</li>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderTop: "none",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    borderRadius: "0",
                                    borderColor: "#BCBEC0",
                                    py: 5,
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            color="secondary"
                                            fontSize="large"
                                            sx={{
                                                "&:hover": {
                                                    color: "primary.main",
                                                },
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "400",
                                            fontSize: "20px",
                                        }}
                                    >
                                        How can I add a new vehicle type and plates?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        To add a new <strong>vehicle type</strong> and <strong>plates</strong> on Lean Dispatcher all you need to do is:
                                        <li> <strong>Login</strong> to your account.</li>
                                        <li>Go to the <strong>Settings</strong> section from main menu.</li>
                                        <li>Go to the <strong>Vehicle</strong> page.</li>
                                        <li>Then click on the <strong>Create Vehicle Type</strong> button.</li>
                                        <li>Fill in the required info and click <strong>Create Vehicle Type</strong>.</li>
                                        <li>Then select that new vehicle type and add click the <strong>Create Plate</strong> button.</li>
                                        <li>Fill in the info and click the <strong>Create Plate</strong> button.</li>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderTop: "none",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    borderRadius: "0",
                                    borderColor: "#BCBEC0",
                                    py: 5,
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            color="secondary"
                                            fontSize="large"
                                            sx={{
                                                "&:hover": {
                                                    color: "primary.main",
                                                },
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "400",
                                            fontSize: "20px",
                                        }}
                                    >
                                        How can I add a new driver?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    To add a new driver on Lean Dispatcher all you need to do is:
                                        <li> <strong>Login</strong> to your account. </li>
                                        <li>Go to the <strong>Settings</strong> section from main menu.</li>
                                        <li>Go to the <strong>Drivers</strong> page.</li>
                                        <li>Then click on the <strong>Create Driver</strong> button.</li>
                                        <li>Fill in the required info and click <strong>Create User</strong>.</li>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderTop: "none",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    borderRadius: "0",
                                    borderColor: "#BCBEC0",
                                    py: 5,
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            color="secondary"
                                            fontSize="large"
                                            sx={{
                                                "&:hover": {
                                                    color: "primary.main",
                                                },
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "400",
                                            fontSize: "20px",
                                        }}
                                    >
                                        How can I add a new product?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        To add a <strong>products</strong> on Lean Dispatcher all you
                                        need to do is:
                                        <li><strong>Login</strong> to your account.</li>
                                        <li>Go to the <strong>Settings</strong> section from main menu.</li>
                                        <li>Go to the <strong>Products</strong> page</li>
                                        <li>Click the <strong>Add Product</strong> Button.</li>
                                        <li>Fill in the needed information.</li>
                                        <li>Then click the <strong>Create Product</strong> to add your product.</li>
                                        <li>Or you can <strong>create a new product</strong> when creating a <strong>new order</strong> and filling in the product information.</li>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderTop: "none",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    borderRadius: "0",
                                    borderColor: "#BCBEC0",
                                    py: 5,
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            color="secondary"
                                            fontSize="large"
                                            sx={{
                                                "&:hover": {
                                                    color: "primary.main",
                                                },
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "400",
                                            fontSize: "20px",
                                        }}
                                    >
                                        How can I know what a setting does in My Account page?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    To know what a setting does in <strong>My Account</strong> page on Lean Finance all you need to do is:
                                    <li><strong>Login</strong> to your account.</li>
                                    <li>Click the <strong>3 dots</strong> in the top right corner.</li>
                                    <li>Then click <strong>My Account</strong>.</li>
                                    <li>Our team made sure that your experience is as simple as possible by adding videos next to each setting to learn about them and how to use it step-by-step.</li>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderTop: "none",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    borderRadius: "0",
                                    borderColor: "#BCBEC0",
                                    py: 5,
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            color="secondary"
                                            fontSize="large"
                                            sx={{
                                                "&:hover": {
                                                    color: "primary.main",
                                                },
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "400",
                                            fontSize: "20px",
                                        }}
                                    >
                                        How can I restrict permissions for certain users?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    Once you have created all the users from the <strong>Users page</strong> under <strong>settings</strong> in Lean Dispatcher, you can:
                                    <li>Find the <strong>user</strong> that you want to grant or edit their permission.</li>
                                    <li>Click the <strong>Permission</strong> button and there you can add or remove any permission for this user.</li>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderTop: "none",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    borderRadius: "0",
                                    borderColor: "#BCBEC0",
                                    py: 5,
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            color="secondary"
                                            fontSize="large"
                                            sx={{
                                                "&:hover": {
                                                    color: "primary.main",
                                                },
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "400",
                                            fontSize: "20px",
                                        }}
                                    >
                                        How can I customize a label / field naming?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    To <strong>customize any label</strong> / <strong>field naming</strong> in Lean Dispatcher all you need to do is:
                                    <li><strong>Login</strong> to your account.</li>
                                    <li>Click the <strong>3 dots</strong> in the top right corner.</li>
                                    <li>Then click <strong>My Account</strong>.</li>
                                    <li>Scroll down and select from the many label naming settings to change as needed.</li>
                                    <li>Then click Update <strong>Information</strong> to save.</li>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderTop: "none",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    borderRadius: "0",
                                    borderColor: "#BCBEC0",
                                    py: 5,
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            color="secondary"
                                            fontSize="large"
                                            sx={{
                                                "&:hover": {
                                                    color: "primary.main",
                                                },
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "400",
                                            fontSize: "20px",
                                        }}
                                    >
                                        Is the Lean Dispatcher software secure?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    Lean Dispatcher is a secured software by local and international cloud hosting standards to ease your mind.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderTop: "none",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    borderRadius: "0",
                                    borderColor: "#BCBEC0",
                                    py: 5,
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            color="secondary"
                                            fontSize="large"
                                            sx={{
                                                "&:hover": {
                                                    color: "primary.main",
                                                },
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "400",
                                            fontSize: "20px",
                                        }}
                                    >
                                        Why should I use an online transport fleet management software?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    With an online transport fleet management software you can easily have access to it from anywhere, anytime and on any device.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderTop: "none",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    borderRadius: "0",
                                    borderColor: "#BCBEC0",
                                    py: 5,
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            color="secondary"
                                            fontSize="large"
                                            sx={{
                                                "&:hover": {
                                                    color: "primary.main",
                                                },
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "400",
                                            fontSize: "20px",
                                        }}
                                    >
                                        How can online transport fleet management softwares help businesses?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    Lean Dispatcher is an online transport fleet
                                    management software that helps small, medium
                                    and large businesses in digitizing, simplifying,
                                    expediting, managing and automating their
                                    transport fleet operations.
                                    <br />
                                    Making your work flow more organized and
                                    efficient to ensure lower overheads and increase
                                    profitability.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderTop: "none",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    borderRadius: "0",
                                    borderColor: "#BCBEC0",
                                    py: 5,
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            color="secondary"
                                            fontSize="large"
                                            sx={{
                                                "&:hover": {
                                                    color: "primary.main",
                                                },
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "400",
                                            fontSize: "20px",
                                        }}
                                    >
                                        Who should use an online transport fleet
                                        management software?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    Anyone that has a logistics and transport
                                    business up and running will needs a versatile
                                    and scalable software to effortlessly manage and
                                    automate all aspects of the business to ensure
                                    best results.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <hr />
                        </AccordionDetails>
                    </Accordion>
                
                </div>
            </div>
        );
    } else {
        return (
            <div className="Ten">
                <div className="container">
                    
                    <Accordion
                        sx={{
                            borderTop: "none",
                            borderBottom: "none",
                            boxShadow: "none",
                            borderRadius: "0",
                            borderColor: "#BCBEC0",
                            py: 5,
                        }}
                    >
                        <AccordionSummary
                            expandIcon={
                                <ExpandMoreIcon
                                    color="secondary"
                                    fontSize="large"
                                    sx={{
                                        "&:hover": {
                                            color: "primary.main",
                                        },
                                    }}
                                />
                            }
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography
                                sx={{
                                    fontWeight: "700",
                                    fontSize: "40px",
                                    lineHeight: "64px",
                                    color: "text.secondary",
                                    textAlign: "center",
                                    mb: 20,
                                    width : "100%"
                                }}
                            >
                                الأسئلةالشائعة
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                                <hr />
                            <Accordion
                                sx={{
                                    borderTop: "none",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    borderRadius: "0",
                                    borderColor: "#BCBEC0",
                                    py: 5,
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            color="secondary"
                                            fontSize="large"
                                            sx={{
                                                "&:hover": {
                                                    color: "primary.main",
                                                },
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "400",
                                            fontSize: "28px",
                                        }}
                                    >
                                       ما هو SaaS؟

                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    هو نموذج برمجي قائم على السحابة يتيح للمستخدمين الدخول إليه بسهولة من أي مكان.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderTop: "none",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    borderRadius: "0",
                                    py: 5,
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            color="secondary"
                                            fontSize="large"
                                            sx={{
                                                "&:hover": {
                                                    color: "primary.main",
                                                },
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "400",
                                            fontSize: "28px",
                                        }}
                                    >ما هو برنامج إدارة أسطول النقل؟

                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>برنامج يستخدم لإدارة وأتمتة عمليات النقل والأسطول الطلبات والرحلات والمركبات والموارد بسهولة. 
 </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderTop: "none",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    borderRadius: "0",
                                    borderColor: "#BCBEC0",
                                    py: 5,
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            color="secondary"
                                            fontSize="large"
                                            sx={{
                                                "&:hover": {
                                                    color: "primary.main",
                                                },
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "400",
                                            fontSize: "28px",
                                        }}
                                    >ما هو برنامج إدارة أسطول النقل عبر الإنترنت؟

                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    هو برنامج قائم على السحابة يسمح لك بالقيام بجميع العمليات وتخزين المستندات على السحابة دون الحاجة إلى وجودها على أجهزتك.

                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderTop: "none",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    borderRadius: "0",
                                    borderColor: "#BCBEC0",
                                    py: 5,
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            color="secondary"
                                            fontSize="large"
                                            sx={{
                                                "&:hover": {
                                                    color: "primary.main",
                                                },
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "400",
                                            fontSize: "28px",
                                        }}
                                    >كيف يمكنني إنشاء طلب؟ 

                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography> لإنشاء <b>طلب</b> على لين ديسباتشر ، كل ما عليك فعله هو: 
<li><b>تسجيل الدخول</b> إلى حسابك</li>
<li>إنتقل إلى <b>صفحة الطلبات</b> من القائمة الرئيسية</li>
<li>الضغط على زر <b>إنشاء طلب</b> لإضافة طلب واحد</li>
<li>إملأ المعلومات المطلوبة</li>
<li>بمجرد <b>إنشاء طلب</b>، يتم إدراجها في صفحة الرحلات</li> 
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderTop: "none",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    borderRadius: "0",
                                    borderColor: "#BCBEC0",
                                    py: 5,
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            color="secondary"
                                            fontSize="large"
                                            sx={{
                                                "&:hover": {
                                                    color: "primary.main",
                                                },
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "400",
                                            fontSize: "28px",
                                        }}
                                    >كيف يمكنني إنشاء طلبات مجمعة مرة واحدة؟
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        لإنشاء <b>عدة طلبات</b> على لين ديسباتشر، كل ما عليك فعله هو:
                                        <li> <strong>تسجيل الدخول</strong> إلى حسابك.</li>
                                        <li> انتقل إلى صفحة <strong>الطلبات</strong> من القائمة الرئيسية .</li>
                                        <li> لإضافة  <strong>طلبات مجمعة</strong> ، الضغط على زر <b>تنزيل نموذج إكسل</b></li>
                                        <li> بمجرد تنزيله، يمكنك إضافة معلومات وفقاً لذلك</li>
                                        <li> ثم الضغط على زر <strong>رفع ملف اكسل</strong> لتحميل طلباتك المجمعة إلى نظام</li>
                                        <li>بمجرد <b>إنشاء طلب</b>، يتم إدراجها في صفحة الرحلات</li> 
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderTop: "none",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    borderRadius: "0",
                                    borderColor: "#BCBEC0",
                                    py: 5,
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            color="secondary"
                                            fontSize="large"
                                            sx={{
                                                "&:hover": {
                                                    color: "primary.main",
                                                },
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "400",
                                            fontSize: "28px",
                                        }}
                                    >
                                       كيف يمكنني تعيين رحلات؟

                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography> 
                                       تتم إضافة الرحلات تلقائياً إلى صفحة الرحلات بمجرد إنشاء <b>الطلبات</b> 
                                        <br />
                                        ثم يتم <b>تنشيطها</b> عن طريق <b>تعيين سائق</b>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderTop: "none",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    borderRadius: "0",
                                    borderColor: "#BCBEC0",
                                    py: 5,
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            color="secondary"
                                            fontSize="large"
                                            sx={{
                                                "&:hover": {
                                                    color: "primary.main",
                                                },
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "400",
                                            fontSize: "28px",
                                        }}
                                    >
كيف يمكنني تعيين سائق لرحلة؟
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        لتعيين <b>سائق للرحلة</b> على لين ديسباتشر، كل ما عليك فعله
                                        <li> <strong>تسجيل الدخول</strong> إلى حسابك. </li>
                                        <li> انتقل إلى صفحة <b>الرحلات</b> من القائمة الرئيسية</li>
                                        <li> حدد الرحلة المضافة حديثاً، ثم الضغط على زر  <strong>تعيين سائق</strong> .</li> 
                                        <li>حدد <strong>السائق والسيارة واللوحات</strong> ثم الضغط على زر <strong>حفظ</strong> لتعيين رحلة وتنشيطها</li>
                                        
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderTop: "none",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    borderRadius: "0",
                                    borderColor: "#BCBEC0",
                                    py: 5,
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            color="secondary"
                                            fontSize="large"
                                            sx={{
                                                "&:hover": {
                                                    color: "primary.main",
                                                },
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "400",
                                            fontSize: "28px",
                                        }}
                                    >كيف يمكنني جعل عميلي يضيف طلبات مباشرة على لين ديسباتشر؟

                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
لجعل <b>عميلك يضيف طلبات</b> على لين ديسباتشر، كل ما عليك فعله هو:
                                        <li> <strong>تسجيل الدخول</strong> إلى حسابك.</li>
                                        <li> بمجرد إنشاء <strong>مستخدم</strong> لعميلك، سيكون قادراً على إنشاء الطلبات .</li>
                                        <li> يمكنه بعد ذلك <strong>تسجيل الدخول</strong> على حسابه ومن ثم الإنتقال إلى صفحة <b>الطلبات</b> من القائمة الرئيسية .</li>
                                       <li>الضغط على زر <b>إنشاء طلب</b> لإضافة طلب واحد</li>
                                        <li> إملأ المعلومات المطلوبة.</li>
                                        
                                        <li>أو لإضافة <strong>طلبات مجمعة</strong> ، الضغط على زر <b>تنزيل نموذج إكسل</b></li>
                                        <li> بمجرد تنزيله، يمكنك إضافة معلومات وفقاً لذلك</li>
                                        <li> ثم الضغط على زر <strong>رفع ملف اكسل</strong> لتحميل طلباتك المجمعة إلى نظام</li>
                                        <li>بمجرد <b>إنشاء طلب</b>، يتم إدراجها في صفحة الرحلات</li> 
                                      </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderTop: "none",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    borderRadius: "0",
                                    borderColor: "#BCBEC0",
                                    py: 5,
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            color="secondary"
                                            fontSize="large"
                                            sx={{
                                                "&:hover": {
                                                    color: "primary.main",
                                                },
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "400",
                                            fontSize: "28px",
                                        }}
                                    >
                                        كيف يمكنني تتبع أسطولي؟ 

                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    يمكنك بسهولة تتبع أسطولك النشط من صفحة عين النسر واختيار الرحلة المطلوبة لمراجعة جميع معلوماتها. 


                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderTop: "none",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    borderRadius: "0",
                                    borderColor: "#BCBEC0",
                                    py: 5,
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            color="secondary"
                                            fontSize="large"
                                            sx={{
                                                "&:hover": {
                                                    color: "primary.main",
                                                },
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "400",
                                            fontSize: "28px",
                                        }}
                                    >
                                        كيف يمكنني إضافة عميل جديد؟ 

                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        لإضافة عملاء جدد على لين ديسباتشر، كل ما عليك فعله هو: <br /> 
                                        <li> <strong>تسجيل الدخول</strong> إلى حسابك .</li>
                                        <li>انتقل إلى قسم <strong>الإعدادات</strong> من القائمة الرئيسية .</li>
                                        <li>انتقل إلى صفحة <strong>جهات الاتصال</strong> ثم الضغط على زر <b>إضافة جهة اتصال</b> .</li>
                                        <li> املأ المعلومات المطلوبة والضغط على زر <b>إنشاء</b> .</li>
                                        <li> أو يمكنك إنشاء <b>جهة اتصال جديدة</b> عند إنشاء <b>طلب جديد</b> وملئ معلومات المصدر والاستلام.</li>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderTop: "none",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    borderRadius: "0",
                                    borderColor: "#BCBEC0",
                                    py: 5,
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            color="secondary"
                                            fontSize="large"
                                            sx={{
                                                "&:hover": {
                                                    color: "primary.main",
                                                },
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "400",
                                            fontSize: "28px",
                                        }}
                                    >
                                    كيف يمكنني إضافة نوع ولوحات مركبة جديدة؟ 

                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    لإضافة نوع جديد من المركبات واللوحات على لين ديسباتشر، كل ما عليك فعله هو: <br /> 
                                        <li> <strong>تسجيل الدخول</strong> إلى حسابك .</li>
                                        <li>انتقل إلى قسم <strong>الإعدادات</strong> من القائمة الرئيسية .</li>
                                        <li>انتقل إلى صفحة <strong>المركبات</strong></li>
                                        <li>ثم الضغط على زر <b>إضافة إنشاء نوع مركبة</b> .</li>
                                        <li> املأ المعلومات المطلوبة والضغط على زر <b>إنشاء نوع مركبة</b> .</li>
                                        <li> ثم حدد نوع المركبة الجديدة والضغط على زر <b>إنشاء لوحة</b> .</li>
                                        <li> إملأ المعلومات والضغط على زر <b>إنشاء لوحة</b> .</li>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderTop: "none",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    borderRadius: "0",
                                    borderColor: "#BCBEC0",
                                    py: 5,
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            color="secondary"
                                            fontSize="large"
                                            sx={{
                                                "&:hover": {
                                                    color: "primary.main",
                                                },
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "400",
                                            fontSize: "28px",
                                        }}
                                    >
كيف يمكنني إضافة سائق جديد؟ 
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        لإضافة سائق في لين ديسباتشر كل ما عليك فعله هو:
                                        <li> <strong>تسجيل الدخول</strong> إلى حسابك.</li>
                                        <li>انتقل إلى قسم <strong>الإعدادات</strong> من القائمة الرئيسية .</li>
                                        <li> انتقل إلى صفحة <strong>السائقين</strong> . </li>
                                        <li> انقر فوق الزر <strong>إنشاء سائق</strong> .</li>
                                        <li> املأ المعلومات المطلوبة .</li>
                                        <li> انقر فوق <strong>إنشاء</strong> للإضافة . </li>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderTop: "none",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    borderRadius: "0",
                                    borderColor: "#BCBEC0",
                                    py: 5,
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            color="secondary"
                                            fontSize="large"
                                            sx={{
                                                "&:hover": {
                                                    color: "primary.main",
                                                },
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "400",
                                            fontSize: "28px",
                                        }}
                                    >
كيف يمكنني إضافة منتج جديد؟ 
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    لإضافة منتج جديد في لين ديسباتشر كل ما عليك فعله هو:
                                        <li> <strong>تسجيل الدخول</strong> إلى حسابك.</li>
                                        <li>انتقل إلى قسم <strong>الإعدادات</strong> من القائمة الرئيسية .</li>
                                        <li> انتقل إلى صفحة <strong>المنتجات</strong> . </li>
                                        <li> انقر فوق الزر <strong>إضافة منتج</strong> لإضافة منتجاتك .</li>
                                        <li> املأ المعلومات المطلوبة .</li>
                                        <li> انقر فوق <strong>إنشاء</strong> للإضافة . </li>
                                        <li> أو يمكنك إنشاء  <strong>منتج</strong> جديد عند إنشاء <b>طلب جديد</b> وملئ معلومات المنتج . </li>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderTop: "none",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    borderRadius: "0",
                                    borderColor: "#BCBEC0",
                                    py: 5,
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            color="secondary"
                                            fontSize="large"
                                            sx={{
                                                "&:hover": {
                                                    color: "primary.main",
                                                },
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "400",
                                            fontSize: "28px",
                                        }}
                                    >
كيف يمكنني معرفة ما يفعله الخيار في صفحة حسابي؟
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    لمعرفة ما يفعلة الإعداد في صفحة حسابي على لين ديسباتشر كل ما عليك فعله هو:
                                        <li> <strong>تسجيل الدخول</strong> إلى حسابك.</li>
                                         <li> الضغط على زر  <strong>الثلاث نقاط</strong> في أعلى يسار الشاشة . </li>
                                        <li> ثم الضغط على <strong>حسابي</strong> .</li>
                                        <li> اجتهد فريقنا لجعل تجربتك سهله قدر الإمكان من خلال إضافة مقاطع فيديو بجوار كل إعداد للتعرف عليه وعلى كيفية استخدامه خطوةً بخطوة. </li>
                                        
                                        </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderTop: "none",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    borderRadius: "0",
                                    borderColor: "#BCBEC0",
                                    py: 5,
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            color="secondary"
                                            fontSize="large"
                                            sx={{
                                                "&:hover": {
                                                    color: "primary.main",
                                                },
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "400",
                                            fontSize: "28px",
                                        }}
                                    >
كيف يمكنني تقييد الصلاحيات لبعض المستخدمين؟ 
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        بمجرد إنشاء جميع المشتخدمين من صفحة <b>المستخدمين</b> ضمن الإعدادات في لين ديسباتشر، يمكنك <br/>
                                        البحث عن <b>المستخدم</b> الذي تريد منحه أو تعديل صلاحياته <br/>
                                        الضغط على زر <b>الصلاحيات</b> وهناك يمكنك إضافة أو إزالة أي صلاحية لهذا المستخدم
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    borderTop: "none",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    borderRadius: "0",
                                    borderColor: "#BCBEC0",
                                    py: 5,
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            color="secondary"
                                            fontSize="large"
                                            sx={{
                                                "&:hover": {
                                                    color: "primary.main",
                                                },
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "400",
                                            fontSize: "28px",
                                        }}
                                    >
كيف يمكنني تخصيص اسم حقل / مسمى؟                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    لتخصيص إسم حقل / مسمى في لين ديسباتشر كل ما عليك فعله هو:
                                        <li> <strong>تسجيل الدخول</strong> إلى حسابك.</li>
                                         <li> الضغط على زر  <strong>الثلاث نقاط</strong> في أعلى يسار الشاشة . </li>
                                        <li> ثم الضغط على <strong>حسابي</strong> .</li>
                                        <li> قم بالتمرير لأسفل وحدد من بين العديد من إعدادات التسمية المتاحة لتغييرها حسب الحاجة.  </li>
                                     
                                        </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion
                                sx={{
                                    borderTop: "none",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    borderRadius: "0",
                                    borderColor: "#BCBEC0",
                                    py: 5,
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            color="secondary"
                                            fontSize="large"
                                            sx={{
                                                "&:hover": {
                                                    color: "primary.main",
                                                },
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "400",
                                            fontSize: "28px",
                                        }}
                                    >
هل برنامج لين ديسباتشر آمن؟                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    لين ديسباتشر هو برنامج آمن وفقا لمعايير الاستضافة السحابية المحلية والدولية لراحة بالك.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion
                                sx={{
                                    borderTop: "none",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    borderRadius: "0",
                                    borderColor: "#BCBEC0",
                                    py: 5,
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            color="secondary"
                                            fontSize="large"
                                            sx={{
                                                "&:hover": {
                                                    color: "primary.main",
                                                },
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "400",
                                            fontSize: "28px",
                                        }}
                                    >
لماذا استخدام برنامج إدارة أسطول النقل عبر الإنترنت؟
  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    باستخدام برنامج إدارة أسطول النقل عبر الإنترنت ، يمكن لك الوصول إليه بسهولة من أي مكان وفي أي وقت وعلى أي جهاز.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion
                                sx={{
                                    borderTop: "none",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    borderRadius: "0",
                                    borderColor: "#BCBEC0",
                                    py: 5,
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            color="secondary"
                                            fontSize="large"
                                            sx={{
                                                "&:hover": {
                                                    color: "primary.main",
                                                },
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "400",
                                            fontSize: "28px",
                                        }}
                                    >
كيف يمكن لبرامج إدارة أسطول النقل عبر الإنترنت مساعدة الشركات؟ 
  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    لين ديسباتشر هو برنامج لإدارة أسطول النقل عبر الإنترنت يساعد الشركات الصغيرة والمتوسطة والكبيرة في رقمنة عمليات أسطول النقل وتبسيطها وتسريعها وإدارتها وأتمتتها. 
                                    <br/>
جعل تدفق عملك أكثر تنظيما وكفاءة لضمان انخفاض النفقات العامة وزيادة الربحية. 
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion
                                sx={{
                                    borderTop: "none",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    borderRadius: "0",
                                    borderColor: "#BCBEC0",
                                    py: 5,
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            color="secondary"
                                            fontSize="large"
                                            sx={{
                                                "&:hover": {
                                                    color: "primary.main",
                                                },
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "400",
                                            fontSize: "28px",
                                        }}
                                    >
من يجب أن يستخدم برنامج إدارة أسطول النقل عبر الإنترنت؟
  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    سيحتاج أي شخص لديه أعمال لوجستية ونقل قيد التشغيل إلى برنامج متعدد الاستخدامات وقابل للتطوير لإدارة جميع جوانب الأعمال وأتمتتها دون عناء لضمان أفضل النتائج. 

                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <hr />
                        </AccordionDetails>
                    </Accordion>
                
                </div>
            </div>
        );
    }
}

export default Home;
