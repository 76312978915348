import React, { useEffect } from 'react';
import "./ElfsightWidget.scss"

const ElfsightWidget = () => {
    useEffect(() => {
        // Ensure that the Elfsight platform script is loaded
        const script = document.createElement('script');
        script.src = 'https://static.elfsight.com/platform/platform.js';
        script.defer = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);


    const getByTitle = () => {

        if (localStorage.getItem("lang") === "en") {
            return "News & Feeds";
        } else {
            return "اخر الاخبار";
        }
    }

    return (
        <div className="div-feeds-container" id="news">
            <div className='div-title'>
                {getByTitle()}
            </div>
            <div className="elfsight-app-b794a62b-650f-49d7-a3c3-f77387867a8c" data-elfsight-app-lazy></div>
        </div>
    );
};

export default ElfsightWidget;