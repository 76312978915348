import React, { useEffect } from 'react'
import MainVal from '../../components/MainVal/MainVal'
import Empower from '../../components/Empower/Empower'
import Approved from '../../components/Approved/Approved'
import MultibleEmpowers from '../../components/MultibleEmpowers/MultibleEmpowers'
import Process from '../../components/Process/Process'



const Value = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    } , [])
    
    return (
        <div>
            <MainVal/>
            <Process/>
            <Approved/>
            <Empower/>
            <MultibleEmpowers/>
        </div>
    )
}

export default Value
