import React from 'react'
import './Industries.scss'



import Image1 from "../../images/image 1.jpg" ;
import Image2 from "../../images/image 2.jpg" ;
import Image3 from "../../images/image 3.jpg" ;
import Image4 from "../../images/image 4.svg" ;
import Image5 from "../../images/image 5.jpg" ;
import Image6 from "../../images/image 6.jpg" ;
import Image7 from "../../images/image 7.jpg" ;
import Image8 from "../../images/image 8.jpg" ;
import Image9 from "../../images/image 9.jpg" ;
import Image41 from "../../images/image 41.jpg" ;
import Image16 from "../../images/image 16.svg" ;
import Image17 from "../../images/image 17.svg" ;
import Image18 from "../../images/image 18.svg" ;
import Image19 from "../../images/image 19.svg" ;

import { useTranslation } from "react-i18next";



const Industries = () => {
    const {t} = useTranslation()
    return (
        <div className='ind-container'>
            <div className='ind-wrapper'>
                <div className='ind-h2'>
                        <h2>{t("Flexible")} </h2>
                </div>
                <div className='ind-images'>
                    <div className='ind-img'>
                        <img src={Image1} alt='' />
                    
                    
                            <h3>{t("Grocery&Food")} </h3>
                        
                    </div>

                    <div className='ind-img'>
                        <img src={Image2} alt='' />
                        
                            <h3>{t("E-Commerce")} </h3>
                        
                    </div>

                    <div className='ind-img'>
                        <img src={Image3} alt='' />
                        
                        
                            <h3> {t("Retail")}</h3>
                        
                    </div>

                    <div className='ind-img'>
                        <img src={Image4} alt='' />
                    
                            <h3> {t("Healthcare")}</h3>
                        
                    </div>
                    <div className='ind-img'>
                        <img src={Image5} alt='' />
                            <h3>{t("Pharmacy")} </h3>
                    </div>

                    <div className='ind-img'>
                        <img src={Image6} alt='' />
                            <h3> {t("InternationalCompanies")}</h3>
                        
                    </div>

                    <div className='ind-img'>
                        <img src={Image7} alt='' />
                    
                        
                            <h3>{t("Big&Bulky")} </h3>
                        
                    </div>

                    <div className='ind-img'>
                        <img src={Image8} alt='' />
                    
                        
                            <h3>{t("Mining")} </h3>
                        
                    </div>

                    <div className='ind-img'>
                        <img src={Image9} alt='' />
                    
                        
                            <h3> {t("Construction")}</h3>
                        
                    </div>

                    <div className='ind-img'>
                        <img src={Image41} alt='' />
                    
                        
                            <h3>{t("Hazardous")} </h3>
                        
                    </div>

                    <div className='ind-img'>
                        <img src={Image16} alt='' />
                    
                        
                            <h3>{t("Logistic")} </h3>
                        
                    </div>

                    <div className='ind-img'>
                        <img src={Image17} alt='' />
                    
                        
                            <h3> {t("Oil&Gas")}</h3>
                        
                    </div>

                    <div className='ind-img'>
                        <img src={Image18} alt='' />
                    
                        
                            <h3>{t("Carriers")} </h3>
                        
                    </div>

                    <div className='ind-img'>
                        <img src={Image19} alt='' />
                        
                            <h3> {t("Gov&Public")}</h3>
                    </div>


                </div>


            </div>
            
        </div>
    )
}

export default Industries
