import React from 'react'
import './CaseStudies.scss'
import left from '../../images/image 23.jpg';
import right from '../../images/image 24.svg';
import rightLogo from '../../images/image 26.svg';
import leftLogo from '../../images/image 25.svg';
import Vector1 from '../../images/dot.png';
import { Button } from '@mui/material'
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

const CaseStudies = () => {
    const {t} = useTranslation()
return (
    <div className='case-container'>
        <div className='case-wrapper'>
            <div className='case-title'> {t('CaseStudies')} </div>
            
            <div className='case-content'>
                
                <div className='case-content-left'>
                        <div className='case-imgsAndlogos'>

                            <div className='case-img'>
                                <img className='imggg1' src={left} alt=''/>
                                <div className="abs">
                                    <div  className='rec48 imgg2'> 
                                        <img  src={leftLogo} alt ='' />
                                    </div>
                                    <div className='rec49 imgg2'> 
                                        <p>{t ('lorem5')} </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='case-desc'>
                            <div className='case-1st'>
                                <h1 className='case-1st-title'>
                                {t('companyName')} | <span className='cse2'>{t('small')} </span>
                                </h1>
                                <p className='case-1st-desc'>
                                {t ('businessDescription')}
                                </p>
                            </div>
                            <div className='case-2nd'>
                                <h1 className='case-1st-title'>{t('challenge')}</h1>
                                <div className='case-2nddiv case222' >
                                    <p className='case-2nd-desc'> <img src={Vector1} alt="" /> {t('fleetManagement')}</p>
                                    <p className='case-2nd-desc'> <img src={Vector1} alt="" /> {t('invoicing')}</p>
                            </div>
                            </div>
                            <div className='case-3rd'>
                                <h1 className='case-1st-title'>{t('improveDriverCommunication')}</h1>
                                <div className='descat'>
                                    <div className='each-cse'><span className='cse-out'><img src={Vector1} alt=''/></span><p className='case-1st-desc'>{t('manageBusiness')}</p></div>
                                    <div className='each-cse'><span className='cse-out'><img src={Vector1} alt=''/></span><p className='case-1st-desc'>{t('singlePlatform')}</p></div>
                                    <div className='each-cse'><span className='cse-out'><img src={Vector1} alt=''/></span><p className='case-1st-desc'>{t('digitalization')}</p></div>
                                    <div className='each-cse'><span className='cse-out'><img src={Vector1} alt=''/></span><p className='case-1st-desc'>{t('improveCustomerExperience')}</p></div>
                                    <div className='each-cse'><span className='cse-out'><img src={Vector1} alt=''/></span><p className='case-1st-desc'>{t('eliminateFinesAndHassle')}</p></div>
                                </div>
                            </div>
                            <div className='case-4th'>
                            <Link to="/demo">
                                <Button variant='contained'  sx={{
                                    padding : "14px 42px",
                                    fontSize:"20px" , fontWeight:"500",lineHeight:"24px",
                                    color : "white",
                                    borderRadius : "16px" ,
                                    }}>
                                        {t("ExperienceItNow")}
                                </Button>
                            </Link>
                            </div>
                        </div>
                </div>

                <div className='case-content-right'>
                        <div className='case-imgsAndlogos'>
                            <div className='case-img'>
                                <img className='imggg1' src={right} alt=''/>
                                <div className="abs">
                                    <div className='rec48 imgg2'> 
                                        <img src={rightLogo} alt=''/>
                                    </div>
                                    <div className='rec49 imgg2'> 
                                        <p>{t ('lorem5')} </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='case-desc'>
                            <div className='case-1st'>
                            <h1 className='case-1st-title'>
                                {t ('companyName2')} | <span className='cse2'>{t ('Large')} </span>
                                </h1>
                                <p className='case-1st-desc'>
                                {t ('businessDescription2')}
                                </p>
                            </div>
                            <div className='case-2nd'>
                                <h1 className='case-1st-title'>{t('challenge2')}</h1>
                                <div className='case-2nddiv'>
                                    <p className='case-2nd-desc'>
                                        <img src={Vector1} alt="" />
                                        {t('driverCommunication')}
                                    </p> 
                                    <p className='case-2nd-desc'>
                                        <img src={Vector1} alt="" />
                                        {t('documentManagement')}
                                    </p>
                                    <p className='case-2nd-desc'>
                                        <img src={Vector1} alt="" />
                                        {t('statusUpdates')}
                                    </p> 
                                </div>
                            </div>
                            <div className='case-3rd'>
                            <h1 className='case-1st-title'>{t('improveDriverCommunication')}</h1>
                            <div className='descat'>
                                    <div className='each-cse'><span className='cse-out'><img src={Vector1} alt=''/></span><p className='case-1st-desc'>{t('digitalizeManagement')}</p></div>
                                    <div className='each-cse'><span className='cse-out'><img src={Vector1} alt=''/></span><p className='case-1st-desc'>{t('eliminatePhysicalDocumentation')}</p></div>
                                    <div className='each-cse'><span className='cse-out'><img src={Vector1} alt=''/></span><p className='case-1st-desc'>{t('improveDocumentAccess')}</p></div>
                                    <div className='each-cse'><span className='cse-out'><img src={Vector1} alt=''/></span><p className='case-1st-desc'>{t('improveCustomerClarity')}</p></div>
                            </div>
                            </div>
                            <div className='case-4th'>
                                <Link to="/demo">
                                    <Button variant='contained'  sx={{
                                        padding : "14px 42px",
                                        fontSize:"20px" , fontWeight:"500",lineHeight:"24px",
                                        color : "white",
                                        borderRadius : "16px" ,
                                        }}>
                                            {t("ExperienceItNow")}
                                    </Button>
                                </Link>
                            </div>
                        </div>
                </div>

            </div>
        </div>
    </div>
)
}

export default CaseStudies
